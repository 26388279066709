export const menuItemsList = [
    { id: 1, url: "#", menuFor: "admin" },
    { id: 2, url: "/", menuFor: "admin" },
    { id: 3, url: "/media", menuFor: "admin" },
    { id: 4, url: "/menu", menuFor: "admin" },
    { id: 5, url: "/system", menuFor: "admin" },
    { id: 6, url: "/settings", menuFor: "admin" },
    { id: 7, url: "/profile-fields", menuFor: "admin" },
    { id: 8, url: "/users", menuFor: "admin" },
    { id: 9, url: "/groups", menuFor: "admin" },
    { id: 10, url: "/forms", menuFor: "admin" },
    { id: 11, url: "/types", menuFor: "admin" },
    { id: 12, url: "/categories", menuFor: "admin" },
    { id: 13, url: "/attribute", menuFor: "admin" },
    { id: 14, url: "/admin-conversation", menuFor: "admin" },
    { id: 15, url: "/links", menuFor: "admin" },

    { id: 16, url: "#", menuFor: "user" },
    { id: 17, url: "/dashboard", menuFor: "user" },
    { id: 18, url: "/user-conversation", menuFor: "user" },
    { id: 19, url: "/media", menuFor: "user" },
]