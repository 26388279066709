import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import {
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Breadcrumbs,
  Link,
  Divider,
  Stack,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faArrowRight,
  faFolder,
  faCertificate,
  faPlus,
  faTimes,
  faClipboard,
  faUpload,
  faCircleInfo,
  faFilePdf,
  faFile,
  faFileExcel,
  faXmark,
  faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import Header from "../../components/layout/header";
import CustomEditor from "../../components/common/ckEditor";
import { Form, Formik, ErrorMessage, FieldArray, Field } from "formik";
import Select from "react-select";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import { Toast } from "../../utils/toast";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImageUpload from "../../components/common/imageUpload";
import CreatableSelect from "react-select/creatable";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postTypeOptions } from "../../helpers/constants/addNewsConstants/postContentConstants";
import { targetTypes } from "../../helpers/constants/addNewsConstants/associatedAttributesConstants";
import {
  DayWeekMonthYearOptions,
  DismissModelBehaviourOption,
  ModelBehaviourOptions,
  OneDayWeekMonthYearOptions,
  configForContentEditor,
  configForContentEditorForHTML,
  yesNoOptions,
} from "../../helpers/constants/common.js";
import {
  AcknowledgementTypeOptions,
  ExpiryActions,
  NotifyUsersOptions,
  ReminderNotificationActions,
  ShowTopOfFeedOptionActions,
} from "../../helpers/constants/addNewsConstants/moreAttributesConstants";
import FileUploadModal from "../../components/common/fileUploadModal.js";
import FileUpload from "../../components/common/fileUpload.js";
import { addNewNewsvalidationSchema } from "../../helpers/validations/addNewNews/index.js";
import { WarningAlert } from "../../components/common/alert/warningAlert.js";
import { SuccessAlert } from "../../components/common/alert/successAlert.js";
import { useParams } from "react-router-dom";
import DrawerMenu from "../../components/layout/drawer.js";
import { Toaster } from "react-hot-toast";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadModalAttribue from "../../components/common/fileUploadModalAttribute.js";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { parseInt } from "lodash";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Accordion from "react-bootstrap/Accordion";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting.js";
import UploadMediaModel from "../../components/common/media/uploadMediaModel.js";
import FileSaver from "file-saver";
import UploadMediaModelAtt from "../../components/common/media/uploadMediaModelAtt.js";
import AddMedia from "../../components/common/media/addMedia.js";
import { useDropzone } from "react-dropzone";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
} from "@mui/material";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  errorTab: PropTypes.number.isRequired,
};

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },
];

const columns_advace_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },

  {
    key: 5,
    id: "edit",
    disablePadding: true,
    label: "Edit",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 6,
    id: "delete",
    disablePadding: true,
    label: "Delete",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 7,
    id: "redacted",
    disablePadding: true,
    label: "Redacted",
    minWidth: 50,
    className: "other-color",
  },
];

const UserCreateContent = () => {
  let { id, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(0);
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [openRnameMediaModel, setOpenRenameModel] = useState(false)
  const [openRnameMediaModelAtt, setOpenRenameModelAtt] = useState(false)

  const [openFileUploadModelForAtt, setOpenFileUploadModelForAtt] =
    useState(false);

  const [contentForPost, setContentForPost] = useState("");
  const [contentForPostForAtt, setContentForPostForAtt] = useState("");

  const [publicationOption, setPublicationOption] = useState("");
  const [getAllNewsData, setGetAllNewsData] = useState([]);
  const [errorTab, setErrorTab] = useState(-1);
  const [isErrorOnClassificationTab, SetIsErrorOnClassificationTab] =
    useState(false);
  const [isErrorOnIRTab, setIsErrorOnIRTab] = useState(false);
  const [isErrorOnEngTab, SetIsErrorOnEngTab] = useState(false);
  const [showError, setShowError] = useState(false);
  const [newsId, setNewsID] = useState(id ? parseInt(id) : "");
  const [isEdit, setIsEdit] = useState(newsId ? true : false);
  const [getNewsData, setGetNewsData] = useState();
  const [getAllTypes, setGetAllTypes] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState([]);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
  const [attributesData, setAttributes] = useState([]);

  const [attData, setAttData] = useState();
  const [additionalTextFields, setAdditionalTextFields] = useState([]);
  const [additionalTextFieldsForNestedChildAtt1, setAdditionalTextFieldsForNestedChildAtt1] = useState([]);

  const [showBelowError, setShowBelowError] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const selectedTypeID = localStorage.getItem("type-selected-id");
  const selectedTypeName = localStorage.getItem("type-selected-name-user");

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const [selectedFileForAtt, setSelectedFileForAtt] = useState(null);
  const [selectedImageFileForAtt, setSelectedImageFileForAtt] = useState(null);
  const [postType, setPostType] = useState("");

  const [permissions, setPermissions] = useState([]);

  const [isAdvancePermission, setIsAdvancePermission] = useState(false);
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [getAllGroupsAndUsers, setGetAllGroupsAndUsers] = useState([]);

  const [getAllCategories, setGetAllCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState({})

  const [openMediaUpload, setOpenMediaUpload] = useState(false)
  const [folderList, setFolderList] = useState()
  const [myMediaFolderList, setMyFolderList] = useState([])
  const [systemMediaFolderList, setSystemFolderList] = useState([])
  const [selectedMediaTypeForImage, setSelectedMediaTypeForImage] = useState("")
  const [selectedMediaTypeForDoc, setSelectedMediaTypeForDoc] = useState("")

  const [selectedMediaFolderIDForImage, setSelectedMediaFolderIDForImage] = useState("")
  const [selectedMediaFolderIDFordoc, setSelectedMediaFolderIDForDoc] = useState("")

  const [mediaList, setMediaList] = useState([])
  const [mediaListResponse, setMediaListResponse] = useState()

  const [selectedMediaFileDetailsForImage, setSelectedMediaFileDetailsForImage] = useState()
  const [selectedMediaFileForImage, setSelectedMediaFileForImage] = useState("")

  const [selectedMediaFileDetailsForDoc, setSelectedMediaFileDetailsForDoc] = useState()
  const [selectedMediaFileForDoc, setSelectedMediaFileForDoc] = useState("")

  const [mediaDataFor, setMediaDataFor] = useState("")

  const [rowsPerPageForMedia, setRowsPerPageForMedia] = useState(18)
  const [pageForMedia, setPageForMedia] = useState(0);

  const [openMediaUploadForAtt, setOpenMediaUploadForAtt] = useState(false)
  const [folderListForAtt, setFolderListForAtt] = useState()
  const [myMediaFolderListForAtt, setMyFolderListForAtt] = useState([])
  const [systemMediaFolderListForAtt, setSystemFolderListForAtt] = useState([])
  const [selectedMediaTypeForImageAtt, setSelectedMediaTypeForImageAtt] = useState("")
  const [selectedMediaTypeForDocAtt, setSelectedMediaTypeForDocAtt] = useState("")

  const [selectedMediaFolderIDForImageAtt, setSelectedMediaFolderIDForImageAtt] = useState("")
  const [selectedMediaFolderIDFordocAtt, setSelectedMediaFolderIDForDocAtt] = useState("")

  const [mediaListAtt, setMediaListAtt] = useState([])
  const [mediaListResponseAtt, setMediaListResponseAtt] = useState()
  const [selectedMediaFileDetailsForImageAtt, setSelectedMediaFileDetailsForImageAtt] = useState()
  const [selectedMediaFileForImageAtt, setSelectedMediaFileForImageAtt] = useState("")

  const [selectedMediaFileDetailsForDocAtt, setSelectedMediaFileDetailsForDocAtt] = useState()
  const [selectedMediaFileForDocAtt, setSelectedMediaFileForDocAtt] = useState("")

  const [openMediaModelFor, setOpenMediaModelFor] = useState("")

  const [openFileUploadModelMedia, setOpenFileUploadModelMedia] = useState(false)

  const [openFileUploadModelMediaAtt, setOpenFileUploadModelMediaAtt] = useState(false)

  const [mediaTitle, setMediaTitle] = useState("")
  const [mediaTitleAtt, setMediaTitleAtt] = useState("")

  const [mediaAltText, setMediaAltText] = useState("")
  const [mediaAltTextAtt, setMediaAltTextAtt] = useState("")

  const [mediaDescription, setMediaDescription] = useState("")
  const [mediaDescriptionAtt, setMediaDescriptionAtt] = useState("")

  const [mediaDirectUrl, setMediaDirectUrl] = useState("")
  const [mediaDirectUrlAtt, setMediaDirectUrlAtt] = useState("")

  const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false)
  const [isSystemTypeMediaAtt, setIsSystemTypeMediaAtt] = useState(false)

  const [responseForMedia, setResponseForMedia] = useState()
  const [responseForMediaAtt, setResponseForMediaAtt] = useState()

  const [valueForMediaTab, setValueForMediaTab] = useState(0);
  const [editMediaType, setEditMediType] = useState("")
  const [selectedMedia, setSelectedMedia] = useState("")
  const [selectedMediaAtt, setSelectedMediaAtt] = useState("")

  const [permissionsForMedia, setPermissionsForMedia] = useState([]);
  const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] = useState(false);
  const [isChild, setIsChild] = useState(false)
  const [parentFolderId, setParentFolderId] = useState(0)
  const [parentFolderIdAtt, setParentFolderIdAtt] = useState(0)

  const [isParentFolderSystemType, setIsParentFolderSystemType] = useState(false)
  const [isParentFolderSystemTypeAtt, setIsParentFolderSystemTypeAtt] = useState(false)

  const [isEditMedia, setIsEditMedia] = useState(false)
  const [isEditMediaAtt, setIsEditMediaAtt] = useState(false)

  const [isRenameMedia, setIsRenameMedia] = useState(false)
  const [isRenameMediaAtt, setIsRenameMediaAtt] = useState(false)

  const [selectedFileMedia, setSelectedFileMedia] = useState(null);
  const [selectedFileMediaAtt, setSelectedFileMediaAtt] = useState(null);

  const [mediaFile, setMediaFile] = useState("")
  const [mediaFileAtt, setMediaFileAtt] = useState("")

  const [fetchAllUsers, setFetchAllUsers] = useState([])
  const [getAllGroupsAndUsersForMedia, setGetAllGroupsAndUsersForMedia] = useState([]);

  const [mediaDataForAtt, setMediaDataForAtt] = useState("")

  const [rowsPerPageForMediaAtt, setRowsPerPageForMediaAtt] = useState(18)
  const [pageForMediaAtt, setPageForMediaAtt] = useState(0);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  let allMenuTypes = [];

  const imageFileTypes = ["JPG", "PNG", "GIF"];

  const fileTypes = ["docx", "pdf"];

  let newWarningMessages = [];

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    activeType,
    drawerVariant,
    usersCollapsable,
    menuFor,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const typeName = localStorage.getItem("type-selected-name-user");
  const typeID = localStorage.getItem("type-selected-id-user");
  const typeSlug = localStorage.getItem("type-selected-slug-user");

  const selectedFolderID = localStorage?.getItem("selected-folder-item")
  const selectedFolderType = JSON.parse(localStorage?.getItem("selected-folder-type"))

  const initialValues = {
    title: newsId ? getNewsData?.title : "",
    content: newsId ? getNewsData?.content : "",
    image: newsId ? getNewsData?.image : "",
    typePoststatus: newsId ? getNewsData?.typePoststatus : true,
    file: newsId ? getNewsData?.file : "",
    fileDescription: newsId ? getNewsData?.fileDescription : "",
    postType:
      getAllTypes?.find((option) => option?.value == typeID)?.value,
    typeSlug: typeSlug,

  };

  const total_mediaFiles = parseInt(mediaListResponse?.meta?.total)

  const total_mediaFilesAtt = parseInt(mediaListResponseAtt?.meta?.total)


  const media_Pagination_options = [{ label: "18", value: 18 },
  { label: "36", value: 36 },
  { label: "54", value: 54 },
  { label: "108", value: 108 }]

  const getMimeType = (extension) => {
    const mimeTypes = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'mp4': 'video/mp4',
      'mp3': 'audio/mpeg'
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const handleChangePageForMedia = (event, newPage) => {
    if (newPage > pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      let folderID;
      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage
        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc
        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
    } else if (newPage < pageForMedia) {
      setPageForMedia(newPage);
      let media_id;
      let folderID;
      if (mediaDataFor === "image") {
        media_id = selectedMediaFileForImage
        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
      } else if (mediaDataFor === "doc") {
        media_id = selectedMediaFileForDoc
        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
      }
      fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
    }
  };

  const handleChangePageForMediaForAtt = (event, newPage) => {
    if (newPage > pageForMediaAtt) {
      setPageForMediaAtt(newPage);
      let type;
      let folder_id;
      if (mediaDataForAtt === "image") {
        folder_id = selectedMediaFolderIDForImageAtt
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      } else if (mediaDataForAtt === "doc") {
        folder_id = selectedMediaFolderIDFordocAtt
        if (selectedMediaTypeForDocAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
    } else if (newPage < pageForMediaAtt) {
      setPageForMediaAtt(newPage);
      let type;
      let folder_id;
      if (mediaDataForAtt === "image") {
        folder_id = selectedMediaFolderIDForImageAtt
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      } else if (mediaDataForAtt === "doc") {
        folder_id = selectedMediaFolderIDFordocAtt
        if (selectedMediaTypeForDocAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(newPage + 1, rowsPerPageForMediaAtt, mediaDataForAtt, folder_id, type)
    }
  };

  const handleChangeRowsPerPageForMedia = (event) => {
    setRowsPerPageForMedia(event.target.value);
    setPageForMedia(0);
    let media_id;
    let folderID;
    if (mediaDataFor === "image") {
      media_id = selectedMediaFileForImage
      folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
    } else if (mediaDataFor === "doc") {
      media_id = selectedMediaFileForDoc
      folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
    }
    fetchMediaFiles(0, event.target.value, mediaDataFor, media_id, folderID)

  };

  const handleChangeRowsPerPageForMediaAtt = (event) => {
    setRowsPerPageForMediaAtt(event.target.value);
    setPageForMediaAtt(0);
    let type;
    let folder_id;

    if (mediaDataForAtt === "image") {
      folder_id = selectedMediaFolderIDForImageAtt
      if (selectedMediaTypeForImageAtt === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (mediaDataForAtt === "doc") {
      folder_id = selectedMediaFolderIDFordocAtt
      if (selectedMediaTypeForDocAtt === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    fetchMediaFilesForAtt(0, event.target.value, mediaDataForAtt, folder_id, type)

  };

  const downloadFileForImage = async () => {

    var blob = new Blob([selectedMediaFileDetailsForImage?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForImage?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForImage?.title);

  };

  const downloadFileForImageAtt = async () => {

    var blob = new Blob([selectedMediaFileDetailsForImageAtt?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForImageAtt?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForImageAtt?.title);

  };

  const downloadFileFordoc = async () => {

    var blob = new Blob([selectedMediaFileDetailsForDoc?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForDoc?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForDoc?.title);

  };

  const downloadFileFordocAtt = async () => {

    var blob = new Blob([selectedMediaFileDetailsForDocAtt?.file_path], {
      type: getMimeType(selectedMediaFileDetailsForDocAtt?.type)
    });
    FileSaver.saveAs(blob, selectedMediaFileDetailsForDocAtt?.title);

  };

  const handleOpenUploadMedia = (dataFor) => {
    let folderID;
    if (dataFor === "image") {
      folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
    } else if (dataFor === "doc") {
      folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
    }
    setMediaDataFor(dataFor)
    fetchMediaFolders(dataFor, folderID)
    const allUserOption = fetchAllUsers.find(
      (user) => user.value == "all_users"
    );
    setPermissionsForMedia([allUserOption])
  }

  const handleOpenUploadMediaForAtt = (dataFor, attdatas) => {
    if (dataFor === "image") {
      setSelectedMediaFileForImageAtt(attdatas?.value)
      setSelectedMediaFolderIDForImageAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
      setSelectedMediaTypeForImageAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
    } else if (dataFor === "doc") {
      setSelectedMediaFileForDocAtt(attdatas?.value)
      setSelectedMediaFolderIDForDocAtt(attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
      setSelectedMediaTypeForDocAtt(attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "")
    }
    setMediaDataForAtt(dataFor)
    fetchMediaFoldersForAtt(dataFor, attdatas?.is_system === 0 ? "my-media" : attdatas?.is_system === 1 ? "system" : "", attdatas?.media_folder_id === "" ? 0 : attdatas?.media_folder_id)
    setAttData(attdatas);
    const allUserOption = fetchAllUsers.find(
      (user) => user.value == "all_users"
    );
    setPermissionsForMedia([allUserOption])
  }

  const handleCloseForUploadMedia = () => {
    setOpenMediaUpload(false)
    setFolderList()
    setMyFolderList([])
    setSystemFolderList([])
    setMediaDataFor("")
  }

  const handleCloseForUploadMediaForAtt = () => {
    setOpenMediaUploadForAtt(false)
    setFolderListForAtt()
    setMyFolderListForAtt([])
    setSystemFolderListForAtt([])
    setMediaDataForAtt("")

    setSelectedMediaFileForImageAtt("")
    setSelectedMediaFolderIDForImageAtt("")
    setSelectedMediaTypeForImageAtt("")
    setSelectedMediaTypeForDocAtt("")
    setSelectedMediaFolderIDForDocAtt("")
    setSelectedMediaTypeForImageAtt("")
  }

  const handleCloseMediaModelAddMedia = () => {
    setOpenFileUploadModelMedia(true)
    let folder_id;
    if (mediaDataFor === "image") {
      folder_id = selectedMediaFolderIDForImage
      if (selectedMediaTypeForImage === "system") {
        setIsSystemTypeMedia(true)
        setIsParentFolderSystemType(true)
      } else {
        setIsSystemTypeMedia(false)
        setIsParentFolderSystemType(true)
      }

    } else if (mediaDataFor === "doc") {
      folder_id = selectedMediaFolderIDFordoc
      if (selectedMediaTypeForDoc === "system") {
        setIsSystemTypeMedia(true)
        setIsParentFolderSystemType(true)
      } else {
        setIsSystemTypeMedia(false)
        setIsParentFolderSystemType(true)
      }
    }
    setParentFolderId(folder_id)
  }

  const handleCloseMediaModelAddMediaAtt = () => {
    setOpenFileUploadModelMediaAtt(true)
    let folder_id;
    if (mediaDataForAtt === "image") {
      folder_id = selectedMediaFolderIDForImageAtt
      if (selectedMediaTypeForImageAtt === "system") {
        setIsSystemTypeMediaAtt(true)
        setIsParentFolderSystemTypeAtt(true)
      } else {
        setIsSystemTypeMediaAtt(false)
        setIsParentFolderSystemTypeAtt(true)
      }

    }
    else if (mediaDataForAtt === "doc") {
      folder_id = selectedMediaFolderIDFordocAtt
      if (selectedMediaTypeForDocAtt === "system") {
        setIsSystemTypeMediaAtt(true)
        setIsParentFolderSystemTypeAtt(true)
      } else {
        setIsSystemTypeMediaAtt(false)
        setIsParentFolderSystemTypeAtt(true)
      }
    }
    setParentFolderIdAtt(folder_id)
  }

  const handleCloseForMediaModel = () => {

    setOpenFileUploadModelMedia(false)
    setMediaTitle("")
    setMediaAltText("")
    setMediaDescription("")
    setMediaDirectUrl("")
    setIsSystemTypeMedia(false)
    setSelectedFileMedia(null)
    setMediaFile("")
    setValueForMediaTab(0)
    setEditMediType("")
    setSelectedMedia("")
    setResponseForMedia()
    setIsEditMedia(false)
    setIsRenameMedia(false)
    setOpenRenameModel(false)

  }

  const handleCloseForMediaModelAtt = () => {

    setOpenFileUploadModelMediaAtt(false)
    setMediaTitleAtt("")
    setMediaAltTextAtt("")
    setMediaDescriptionAtt("")
    setMediaDirectUrlAtt("")
    setIsSystemTypeMediaAtt(false)
    setSelectedFileMediaAtt(null)
    setMediaFileAtt("")
    setValueForMediaTab(0)
    setEditMediType("")
    setSelectedMediaAtt("")
    setResponseForMediaAtt()
    setIsEditMediaAtt(false)
    setIsRenameMediaAtt(false)
    setOpenRenameModelAtt(false)
    setOpenMediaModelFor("")
  }

  const handleChangeForMedia = (event, newValue) => {
    setValueForMediaTab(newValue);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const fileUrl = URL?.createObjectURL(file); // Create a URL for the file

      // Set the selected file with both file object and its URL
      if (openMediaModelFor === "normal") {
        setSelectedFileMedia({
          file,
          url: fileUrl,
          type: file?.type, // Get the MIME type from the dropped file
          name: file?.name // Use the file's name for display
        });
      } else {
        setSelectedFileMediaAtt({
          file,
          url: fileUrl,
          type: file?.type, // Get the MIME type from the dropped file
          name: file?.name // Use the file's name for display
        });
      }

    }
  }, [openMediaModelFor]);

  const acceptedFilesForUpload = () => {
    if (mediaDataFor === "image") {
      return {
        "image/*": [], // Accept all image types
      }
    } else if (mediaDataFor === "doc") {
      return {
        "application/pdf": [], // Accept PDFs
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
      }
    }
  }

  const acceptedFilesForUploadAtt = () => {
    if (mediaDataForAtt === "image") {
      return {
        "image/*": [], // Accept all image types
      }
    } else if (mediaDataForAtt === "doc") {
      return {
        "application/pdf": [], // Accept PDFs
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: openMediaModelFor === "normal" ? acceptedFilesForUpload : acceptedFilesForUploadAtt,
    multiple: false,
  });

  const renderPreview = () => {
    if (!selectedFileMedia) return null;

    // Handle image files
    if (selectedFileMedia?.type?.startsWith('image/')) {
      return (
        <div className="image-preview">
          <img
            src={selectedFileMedia?.url}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Maintain aspect ratio
            }}
          />
        </div>
      );
    }

    // Handle PDF files
    if (selectedFileMedia?.type === 'application/pdf') {
      // return <p>PDF file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFilePdf
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle DOCX files
    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // return <p>DOCX file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle PPTX files
    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFileExcel
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMedia?.name}
          </Box>
        </Card>
      );
    }

    // Handle MP4 files
    if (selectedFileMedia?.type === 'video/mp4') {
      return (
        <Box className="w-100 h-100">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactPlayer
            url={selectedFileMedia?.url}
            className="react-player"
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </Box>
      );
    }

    // Handle MP3 files
    if (selectedFileMedia?.type === 'audio/mpeg') {
      return (
        <Box className="w-100 h-100 audio-file">
          <Typography>{selectedFileMedia?.name}</Typography>
          <ReactAudioPlayer
            src={selectedFileMedia?.url}
            controls
          />
        </Box>
      );
    }

    return (
      <Card
        key={"pdf"}
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          cursor: "pointer",
        }}
        className="pdf-doc"
      >
        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            size="3x"
            icon={
              faFilePdf
            }
            color="#660C60"
          />
        </Box>
        <Divider />
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {selectedFileMedia?.name}
        </Box>
      </Card>
    );
  };

  const renderPreviewAtt = () => {
    if (!selectedFileMediaAtt) return null;

    // Handle image files
    if (selectedFileMediaAtt?.type?.startsWith('image/')) {
      return (
        <div className="image-preview">
          <img
            src={selectedFileMediaAtt?.url}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover", // Maintain aspect ratio
            }}
          />
        </div>
      );
    }

    // Handle PDF files
    if (selectedFileMediaAtt?.type === 'application/pdf') {
      // return <p>PDF file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFilePdf
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle DOCX files
    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // return <p>DOCX file selected: {selectedFile.name}</p>;
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle PPTX files
    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFile
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    if (selectedFileMediaAtt?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return (
        <Card
          key={"pdf"}
          variant="outlined"
          sx={{
            p: 2,
            mb: 2,
            cursor: "pointer",
          }}
          className="pdf-doc"
        >
          <Box
            height={150}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FontAwesomeIcon
              size="3x"
              icon={
                faFileExcel
              }
              color="#660C60"
            />
          </Box>
          <Divider />
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            {selectedFileMediaAtt?.name}
          </Box>
        </Card>
      );
    }

    // Handle MP4 files
    if (selectedFileMediaAtt?.type === 'video/mp4') {
      return (
        <Box className="w-100 h-100">
          <Typography>{selectedFileMediaAtt?.name}</Typography>
          <ReactPlayer
            url={selectedFileMediaAtt?.url}
            className="react-player"
            playing={true}
            width="100%"
            height="100%"
            controls={true}
          />
        </Box>
      );
    }

    // Handle MP3 files
    if (selectedFileMediaAtt?.type === 'audio/mpeg') {
      return (
        <Box className="w-100 h-100 audio-file">
          <Typography>{selectedFileMediaAtt?.name}</Typography>
          <ReactAudioPlayer
            src={selectedFileMediaAtt?.url}
            controls
          />
        </Box>
      );
    }

    return (
      <Card
        key={"pdf"}
        variant="outlined"
        sx={{
          p: 2,
          mb: 2,
          cursor: "pointer",
        }}
        className="pdf-doc"
      >
        <Box
          height={150}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FontAwesomeIcon
            size="3x"
            icon={
              faFilePdf
            }
            color="#660C60"
          />
        </Box>
        <Divider />
        <Box
          sx={{
            paddingLeft: 1,
          }}
        >
          {selectedFileMediaAtt?.name}
        </Box>
      </Card>
    );
  };

  const validateMedia = () => {
    let isValid = true;

    if (mediaTitle === "") {
      Toast.error("Please enter a title for media");
      isValid = false;
    }
    if (mediaAltText === "") {
      Toast.error("Please enter a alt text for media");
      isValid = false;
    }
    if (mediaDescription === "") {
      Toast.error("Please enter a description for media");
      isValid = false;
    }
    // if (permissionsForMedia?.length < 1) {
    //   Toast.error("Please select at least one user/group for access permission");
    //   isValid = false;
    // }
    if (selectedFileMedia === null) {
      Toast.error("Please upload a media file");
      isValid = false;
    }
    return isValid;

  }

  const validateMediaAtt = () => {
    let isValid = true;

    if (mediaTitleAtt === "") {
      Toast.error("Please enter a title for media");
      isValid = false;
    }
    if (mediaAltTextAtt === "") {
      Toast.error("Please enter a alt text for media");
      isValid = false;
    }
    if (mediaDescriptionAtt === "") {
      Toast.error("Please enter a description for media");
      isValid = false;
    }
    // if (permissionsForMedia?.length < 1) {
    //   Toast.error("Please select at least one user/group for access permission");
    //   isValid = false;
    // }
    if (selectedFileMediaAtt === null) {
      Toast.error("Please upload a media file");
      isValid = false;
    }
    return isValid;

  }

  const fetchMediaDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMedia}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitle(responseData?.title)
        setMediaAltText(responseData?.alt_text)
        setMediaDescription(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
        setResponseForMedia(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenFileUploadModelMedia(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsAtt = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMediaAtt}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitleAtt(responseData?.title)
        setMediaAltTextAtt(responseData?.alt_text)
        setMediaDescriptionAtt(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
        setResponseForMediaAtt(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrlAtt(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMediaAtt({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenFileUploadModelMediaAtt(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsForRename = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMedia}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitle(responseData?.title)
        setMediaAltText(responseData?.alt_text)
        setMediaDescription(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
        setResponseForMedia(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrl(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMedia({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenRenameModel(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaDetailsForRenameAtt = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/file/edit/${selectedMediaAtt}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        const responseData = response?.response
        setLoading(false);
        setMediaTitleAtt(responseData?.title)
        setMediaAltTextAtt(responseData?.alt_text)
        setMediaDescriptionAtt(responseData?.description)
        const permissions = responseData?.permission;
        setIsSystemTypeMediaAtt(responseData?.is_system === 0 ? false : true)
        setResponseForMediaAtt(responseData)
        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view === 0 ? false : true,
            deny: data?.can_deny === 0 ? false : true,
            edit: data?.can_edit === 0 ? false : true,
            delete: data?.can_delete === 0 ? false : true,
            redacted: data?.can_resource === 0 ? false : true,
            type: data?.type,
          };
        });
        const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
        setMediaDirectUrlAtt(responseData?.file_path)
        setPermissionsForMedia(finalPermissions)
        setIsAdvancePermissionForMedia(
          responseData?.advance_permission === 0 ? false : true
        );
        setSelectedFileMediaAtt({
          url: responseData?.file_path,
          type: mimeType,
          name: responseData?.title || "Uploaded File"
        });
        setOpenRenameModelAtt(true)
        Toast.success(response?.message);

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 422) {
        setLoading(false);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const storeMedia = async () => {
    const validate = await validateMedia()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
      formData.append("title", mediaTitle);
      formData.append("alt_text", mediaAltText);
      formData.append("description", mediaDescription);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderId)

      if (isEditMedia === true) {
        formData.append("direct_url", responseForMedia?.file_path)
      }

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMedia?.file instanceof Blob ||
        selectedFileMedia?.file instanceof File
      ) {
        formData.append("file", selectedFileMedia?.file);
      }

      if (isEditMedia === true) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/update/${selectedMedia}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);
            let media_id;
            let folderID;
            if (mediaDataFor === "image") {
              setSelectedMediaFileForImage(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImage(response?.response)
              folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDoc(response?.response)
              folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc

            }

            await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
            Toast.success(response?.message);
            handleCloseForMediaModel()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/store`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);

            Toast.success(response?.message);
            let media_id;
            let folderID;
            if (mediaDataFor === "image") {
              console.log("2282", response?.response?.id)
              setSelectedMediaFileForImage(response?.response?.id)
              media_id = response?.response?.id
              folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
              setSelectedMediaFileDetailsForImage(response?.response)
            } else if (mediaDataFor === "doc") {
              setSelectedMediaFileForDoc(response?.response?.id)
              media_id = response?.response?.id
              folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
              setSelectedMediaFileDetailsForDoc(response?.response)
            }

            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
            handleCloseForMediaModel()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      }

    }
  }

  const storeMediaAtt = async () => {
    const validate = await validateMediaAtt()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
      formData.append("title", mediaTitleAtt);
      formData.append("alt_text", mediaAltTextAtt);
      formData.append("description", mediaDescriptionAtt);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderIdAtt)

      if (isEditMediaAtt === true) {
        formData.append("direct_url", responseForMediaAtt?.file_path)
      }

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMediaAtt?.file instanceof Blob ||
        selectedFileMediaAtt?.file instanceof File
      ) {
        formData.append("file", selectedFileMediaAtt?.file);
      }

      if (isEditMediaAtt === true) {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/update/${selectedMediaAtt}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);
            let media_id;
            let folder_id;
            let type;
            if (mediaDataForAtt === "image") {
              folder_id = selectedMediaFolderIDForImageAtt
              setSelectedMediaFileForImageAtt(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForImageAtt(response?.response)
              if (selectedMediaTypeForImageAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
              // setSelectedFileMedia(response?.response?.id)
            } else if (mediaDataForAtt === "doc") {
              folder_id = selectedMediaFolderIDFordocAtt
              setSelectedMediaFileForDocAtt(response?.response?.id)
              media_id = response?.response?.id;
              setSelectedMediaFileDetailsForDocAtt(response?.response)
              if (selectedMediaTypeForDoc === "system") {
                type = "system"
              } else {
                type = "user"
              }
              // setSelectedFileMedia(response?.response?.id)

            }

            await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
            Toast.success(response?.message);
            handleCloseForMediaModelAtt()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            `user/media/file/store`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success === true) {
            setLoading(false);

            Toast.success(response?.message);
            let media_id;
            let folder_id;
            let type;
            if (mediaDataForAtt === "image") {
              folder_id = selectedMediaFolderIDForImageAtt
              console.log("2282", response?.response?.id)
              setSelectedMediaFileForImageAtt(response?.response?.id)
              media_id = response?.response?.id
              setSelectedMediaFileDetailsForImageAtt(response?.response)
              if (selectedMediaTypeForImageAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
            } else if (mediaDataForAtt === "doc") {
              folder_id = selectedMediaFolderIDFordocAtt
              setSelectedMediaFileForDocAtt(response?.response?.id)
              media_id = response?.response?.id
              setSelectedMediaFileDetailsForDocAtt(response?.response)
              if (selectedMediaTypeForDocAtt === "system") {
                type = "system"
              } else {
                type = "user"
              }
            }

            fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
            handleCloseForMediaModelAtt()

          } else {
            setLoading(false);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      }

    }
  }

  const renameMedia = async () => {
    const validate = await validateMedia()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
      formData.append("title", mediaTitle);
      formData.append("alt_text", mediaAltText);
      formData.append("description", mediaDescription);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderId)

      formData.append("direct_url", responseForMedia?.file_path)

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMedia?.file instanceof Blob ||
        selectedFileMedia?.file instanceof File
      ) {
        formData.append("file", selectedFileMedia?.file);
      }
      try {
        setLoading(true);
        const response = await api.post(
          `user/media/file/update/${selectedMedia}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          setLoading(false);
          let media_id;
          let folderID;
          if (mediaDataFor === "image") {
            setSelectedMediaFileForImage(response?.response?.id)
            media_id = response?.response?.id;
            folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
            setSelectedMediaFileDetailsForImage(response?.response)
          } else if (mediaDataFor === "doc") {
            setSelectedMediaFileForDoc(response?.response?.id)
            media_id = response?.response?.id;
            folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
            setSelectedMediaFileDetailsForDoc(response?.response)

          }

          await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
          Toast.success(response?.message);
          handleCloseForMediaModel()

        } else {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    }
  }

  const renameMediaAtt = async () => {
    const validate = await validateMediaAtt()

    if (validate) {
      const formData = new FormData();
      let permissionData;
      formData.append("is_system", isSystemTypeMediaAtt === true ? 1 : 0);
      formData.append("title", mediaTitleAtt);
      formData.append("alt_text", mediaAltTextAtt);
      formData.append("description", mediaDescriptionAtt);
      formData.append("advance_permission", 0);

      if (isAdvancePermissionForMedia === true) {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissionsForMedia?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: 0,
            can_deny: 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("folder_id", parentFolderIdAtt)

      formData.append("direct_url", responseForMediaAtt?.file_path)

      formData.append("permission", JSON?.stringify(permissionData));

      if (
        selectedFileMediaAtt?.file instanceof Blob ||
        selectedFileMediaAtt?.file instanceof File
      ) {
        formData.append("file", selectedFileMediaAtt?.file);
      }
      try {
        setLoading(true);
        const response = await api.post(
          `user/media/file/update/${selectedMediaAtt}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success === true) {
          setLoading(false);
          let media_id;
          let folder_id;
          let type;
          if (mediaDataForAtt === "image") {
            folder_id = selectedMediaFolderIDForImageAtt
            setSelectedMediaFileForImageAtt(response?.response?.id)
            media_id = response?.response?.id;
            setSelectedMediaFileDetailsForImageAtt(response?.response)
            if (selectedMediaTypeForImageAtt === "system") {
              type = "system"
            } else {
              type = "user"
            }
            // setSelectedFileMedia(response?.response?.id)
          } else if (mediaDataForAtt === "doc") {
            folder_id = selectedMediaFolderIDFordocAtt
            setSelectedMediaFileForDocAtt(response?.response?.id)
            media_id = response?.response?.id;
            setSelectedMediaFileDetailsForDocAtt(response?.response)
            if (selectedMediaTypeForDoc === "system") {
              type = "system"
            } else {
              type = "user"
            }
            // setSelectedFileMedia(response?.response?.id)

          }

          await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
          Toast.success(response?.message);
          handleCloseForMediaModelAtt()

        } else {
          setLoading(false);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status === 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status === 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    }
  }

  const transformData = (data) => {
    const result = {};

    data?.forEach(category => {
      const termIds = category?.term_value?.split(',')?.map(Number);
      result[category?.slug] = category?.categoryterm?.filter(term =>
        termIds?.includes(term?.id)).map(term => ({
          label: term?.name,
          value: term?.id,
          categoryId: term?.category_id
        }));
    });

    return result;
  };

  const isValidURL = (url) => {
    const pattern = new RegExp(
      /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?$/
    );
    return !!pattern.test(url);
  };

  const isValidEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const transformCategoryData = (categories) => {
    return categories?.map(category => ({
      label: category.title,
      slug: category.slug,
      id: category.id,
      options: category.categoryterm.map(term => ({
        label: term.name,
        value: term.id,
        categoryId: category.id
      }))
    }));
  };

  const handleCategoryChange = (categoryLabel, selectedOption) => {
    setSelectedCategories(prevState => ({
      ...prevState,
      [categoryLabel]: selectedOption
    }));
  };

  const transformSelectedCategories = (selectedCategories, allCategories) => {
    const payload = {};

    allCategories?.forEach(category => {
      const categoryLabel = category?.slug?.toLowerCase();
      const terms = selectedCategories[categoryLabel] || [];
      const categoryId = category?.id;

      payload[categoryLabel] = {
        category_id: categoryId,
        term_values: terms?.length > 0 ? terms?.map(term => term?.value).join(',') : ""
      };
    });

    return payload;
  };

  const handleToggle = (value, key) => {
    setPermissions((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (permission.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
              delete: false,
              redacted: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );

    setGetAllGroupsAndUsers((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (permission.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
              delete: false,
              redacted: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );
  };

  const handleAddTextField = (
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt
  ) => {

    const lastId =
      additionalTextFields.length > 0
        ? additionalTextFields[additionalTextFields.length - 1].id
        : null;

    const newTextField = {
      id: lastId + 1, // Generate a unique ID for the new text field
      nameValue: nameValue,
      value: "",
      label: label,
      section_id: section_id, // Set the appropriate tab value
      fieldtype: fieldtype,
      is_mandatory: is_mandatory,
      ...(options && { options: options }), // Conditionally include options if available
    };
    setAdditionalTextFields([...additionalTextFields, newTextField]);
    if (repeatableAtt == true) {
      const updatedValue = Array.isArray(valueAtt)
        ? [...valueAtt, newTextField]
        : newTextField;
      handleChangeForFormData(nameValue, updatedValue, section_id, false, "", true);
    }
  };

  const handleAddNestedTextField = (
    parentId,
    nameValue,
    label,
    section_id,
    fieldtype,
    options,
    is_mandatory,
    repeatableAtt,
    valueAtt,
    attdata,
    show_option_value,
    max_repeat,
    nestedChildAtt1
  ) => {

    const fetch_repeatableAtts = additionalTextFieldsForNestedChildAtt1?.filter((data) => data?.nameValue === nameValue)

    if (fetch_repeatableAtts?.length < max_repeat) {
      const lastId =
        additionalTextFieldsForNestedChildAtt1.length > 0
          ? additionalTextFieldsForNestedChildAtt1[additionalTextFieldsForNestedChildAtt1.length - 1].id
          : null;

      const newNestedTextField = {
        id: lastId + 1, // Generate a unique ID for the new text field
        parentId: nestedChildAtt1?.parentID, // Parent ID to link with the parent attribute
        nameValue: nameValue,
        value: "",
        label: label,
        section_id: section_id, // Set the appropriate tab value
        fieldtype: fieldtype,
        is_mandatory: is_mandatory,
        show_option_value: show_option_value,
        ...(options && { options: options }), // Conditionally include options if available
      };

      setAdditionalTextFieldsForNestedChildAtt1([...additionalTextFieldsForNestedChildAtt1, newNestedTextField]);

      if (repeatableAtt == true) {
        const updatedValue = [...additionalTextFieldsForNestedChildAtt1, newNestedTextField]

        handleNestedChildChange(
          nameValue,
          updatedValue,
          section_id,
          parentId,
          attdata,
          show_option_value
        );
      }
    }
  };

  const handleChangeForFormData = (
    nameValue,
    value,
    section_id,
    isChildAtt,
    subField,
    isAppendDirectly,
    mediaData
  ) => {
    setAttributes((prevAttributes) => {
      const updatedAttributes = prevAttributes?.map((attribute) => {
        if (
          attribute?.section_id === section_id &&
          attribute?.nameValue === nameValue
        ) {
          // Update the value of the attribute
          let updatedAttribute = { ...attribute, value };

          if (attribute.fieldtype === "link_object" && isAppendDirectly == false) {
            const currentLinkObject = attribute.value || {};
            updatedAttribute.value = {
              ...currentLinkObject,
              [subField]: value
            };

          } else {
            updatedAttribute.value = value;

            // If the fieldtype is radio_single_choice and options are provided
            if (
              attribute?.fieldtype === "radio_single_choice" &&
              attribute?.options
            ) {
              const updatedOptions = attribute?.options?.map((option) => {
                // Set the checked property based on the selected value
                return { ...option, checked: option?.id == parseInt(value) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (attribute?.fieldtype === "tags") {
              // Initialize options with current options or an empty array
              let options = attribute?.options || [];

              // Separate existing and new options
              const existingOptions = value?.filter((option) => !option.__isNew__);
              const newOptions = value?.filter((option) => option?.__isNew__);

              // Add new options to the existing options list
              if (newOptions?.length > 0) {
                options = [
                  ...options,
                  ...newOptions?.map((option) => ({
                    value: option?.value,
                    label: option?.label,
                  })),
                ];
              }

              // Extract selected values from the value array
              const selectedValues = value?.map((option) => option?.value);

              // Update the attribute with new options and selected values
              updatedAttribute = {
                ...updatedAttribute,
                options: options,
                value: selectedValues,
              };
            }

            // If the fieldtype is checkbox_multiple_choice
            if (
              attribute?.fieldtype === "checkbox_multiple_choice" &&
              attribute?.options
            ) {
              const updatedOptions = attribute?.options?.map((option) => {
                // Set the checked property based on whether the option ID is included in the selected values array
                return { ...option, checked: value?.includes(option?.id) };
              });
              // Update the options of the attribute
              updatedAttribute = { ...updatedAttribute, options: updatedOptions };
            }

            if (attribute?.fieldtype === "image") {
              updatedAttribute = {
                ...updatedAttribute,
                url: mediaData?.file_path,
                media_folder_id: selectedMediaFolderIDForImageAtt,
                is_system: selectedMediaTypeForImageAtt === "my-media" ? 0 : 1
              };
            }

            if (attribute?.fieldtype === "file") {
              updatedAttribute = {
                ...updatedAttribute,
                name: mediaData?.title,
                media_folder_id: selectedMediaFolderIDFordocAtt,
                is_system: selectedMediaTypeForDocAtt === "my-media" ? 0 : 1
              };
            }

            if (isChildAtt == true) {
              updatedAttribute = { ...updatedAttribute, childAtts: value };
            }
          }

          return updatedAttribute;
        }
        return attribute;
      });

      return updatedAttributes;
    });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [nameValue]: value,
    }));
  };

  const handleChangeForChildAtts = (
    nameValue,
    value,
    section_id,
    childAtts,
    attdata
  ) => {
    const findAtt = attributesData
      .filter((data) => data?.id == attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          childAtts: data?.childAtts,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt?.flatMap((att) => {
      return att?.childAtts?.map((childAtt) => {
        if (
          childAtt?.section_id === section_id &&
          childAtt?.nameValue === nameValue
        ) {
          // If the fieldtype is checkbox_multiple_choice
          if (
            childAtt?.fieldtype === "checkbox_multiple_choice" &&
            childAtt?.options
          ) {
            const updatedOptions = childAtt?.options?.map((option) => {
              // Set the checked property based on whether the option ID is included in the selected values array
              return { ...option, checked: value?.includes(option?.id) };
            });

            // Update the options of the child attribute
            return { ...childAtt, options: updatedOptions, value };
          } else {
            // If the fieldtype is not checkbox_multiple_choice, simply update the value
            return { ...childAtt, value };
          }
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData?.map((att) => {
      if (att?.id == attdata?.id) {
        return { ...att, childAtts: updatedChildAtts };
      }
      return att;
    });
    setAttributes(updatedAttData);
  };

  const handleNestedChildChange = (
    nameValue,
    value,
    section_id,
    parentChildAtt,
    attdata,
    show_option_value
  ) => {

    const findAtt = attributesData
      .filter((data) => data?.id == attdata?.id)
      .map((data) => {
        return {
          id: data?.id,
          section_id: data?.section_id,
          label: data?.label,
          is_mandatory: data?.is_mandatory,
          nameValue: data?.nameValue,
          fieldtype: data?.fieldtype,
          options: data?.options,
          parentID: data?.parentID,
          childAtts: data?.childAtts,
          value: data?.value,
        };
      });

    const updatedChildAtts = findAtt?.flatMap((att) => {
      return att?.childAtts?.map((childAtt) => {

        if (
          childAtt?.section_id === section_id &&
          childAtt?.nameValue === parentChildAtt?.nameValue
        ) {
          const updatedNestedChildAtts = childAtt?.childAtts?.map((nestedChildAtt) => {
            if (
              nestedChildAtt?.section_id === section_id &&
              nestedChildAtt?.nameValue === nameValue &&
              nestedChildAtt?.show_option_value == show_option_value
            ) {
              return { ...nestedChildAtt, value };
            }
            return nestedChildAtt;
          });

          return { ...childAtt, childAtts: updatedNestedChildAtts };
        }
        return childAtt;
      });
    });

    const updatedAttData = attributesData?.map((att) => {
      if (att?.id == attdata?.id) {
        return { ...att, childAtts: updatedChildAtts };
      }
      return att;
    });

    setAttributes(updatedAttData);

  };

  const handleChangeForAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id,
    fieldtype,
    subField
  ) => {
    let updatedTextFields;
    if (fieldtype === "link_object") {
      updatedTextFields = additionalTextFields?.map((field) =>
        field?.id == id && field?.nameValue === nameValue
          ? { ...field, value: { ...field?.value, [subField]: value } }
          : field
      );
    } else {
      updatedTextFields = additionalTextFields?.map((field) =>
        field?.id == id && field?.nameValue === nameValue
          ? { ...field, value: value }
          : field
      );
    }

    setAdditionalTextFields(updatedTextFields);

    const finalData = updatedTextFields?.filter(
      (data) => data?.nameValue === nameValue
    );

    handleChangeForFormData(nameValue, finalData, section_id, false, "", true);
  };

  const handleChangeForNestedChildAdditionalTextField = (
    id,
    value,
    nameValue,
    section_id,
    fieldtype,
    subField,
    parentId,
    attdata,
    show_option_value,
    textField
  ) => {

    let updatedTextFields;
    if (fieldtype === "link_object") {
      updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
        field?.id == id && field?.nameValue === nameValue
          ? { ...field, value: { ...field?.value, [subField]: value } }
          : field
      );
    } else {
      updatedTextFields = additionalTextFieldsForNestedChildAtt1?.map((field) =>
        field?.id == id && field?.nameValue === nameValue
          ? { ...field, value: value }
          : field
      );
    }

    setAdditionalTextFieldsForNestedChildAtt1(updatedTextFields);

    const anotherUpdatedNestedTextFields = updatedTextFields?.filter(
      (field) => field?.nameValue === nameValue && field?.parentId == parentId?.attribute_id
    );

    handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
  };

  const removeExtraFields = (id, nameValue, section_id) => {
    const updatedTextFields = additionalTextFields?.filter(
      (field) => !(field?.id == id && field?.nameValue === nameValue)
    );
    setAdditionalTextFields(updatedTextFields);
    const anotherupdatedTextFields = updatedTextFields?.filter((field) => field?.nameValue === nameValue)
    handleChangeForFormData(nameValue, anotherupdatedTextFields, section_id, false, "", true);
  };

  const removeNestedExtraFields = (id, nameValue, section_id, parentId, show_option_value, attdata) => {

    // Filter out the nested text field to be removed
    const updatedNestedTextFields = additionalTextFieldsForNestedChildAtt1?.filter(
      (field) => !(field?.id == id && field?.nameValue === nameValue && field?.parentId == parentId?.attribute_id)
    );
    // Update the state
    setAdditionalTextFieldsForNestedChildAtt1(updatedNestedTextFields);

    // Filter the updated text fields for the specific nameValue

    const anotherUpdatedNestedTextFields = updatedNestedTextFields?.filter(
      (field) => field?.nameValue === nameValue && field?.parentId == parentId?.attribute_id
    );

    // console.log("anotherUpdatedNestedTextFields::", anotherUpdatedNestedTextFields)

    // Log the updated text fields

    // Update the form data with the new list of nested text fields
    handleNestedChildChange(nameValue, anotherUpdatedNestedTextFields, section_id, parentId, attdata, show_option_value);
  };

  const handleClickForAtt = (content, attdatas) => {
    setContentForPostForAtt(content);
    setAttData(attdatas);
    setOpenFileUploadModelForAtt(true);
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
    if (attdatas?.value !== "") {
      if (content === "image") {
        setSelectedImageFileForAtt(selectedImageFileForAtt);
      }
      if (content === "file") {
        setSelectedFileForAtt(selectedFileForAtt);
      }
    }
  };

  const handleCloseWarningMessage = (index) => {
    const updatedMessages = [...warningMessage];
    updatedMessages.splice(index, 1);
    setWarningMessage(updatedMessages);
  };

  const handleClick = (content, attdatas) => {
    setContentForPost(content);
    setAttData(attdatas);
    setOpenFileUploadModel(true);
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      setIsEdit(false)
    } else {
      setIsEdit(true)
    }
  };

  const handleFileChange = (file, setFieldValue) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPost === "image") {
          setSelectedImageFile(file);
          setIsEdit(false);

        } else if (contentForPost === "file") {
          setSelectedFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  const handleOpen = () => setOpenFileUploadModel(true);
  const handleOpenForAtt = () => setOpenFileUploadModelForAtt(true);

  const handleClose = () => setOpenFileUploadModel(false);
  const handleCloseForAtt = () => setOpenFileUploadModelForAtt(false);

  const handleFileChangeForAtt = (file) => {

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPostForAtt === "image") {
          setSelectedImageFileForAtt(file);
          setIsEdit(false);

        } else if (contentForPostForAtt === "file") {
          setSelectedFileForAtt(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageFileValueForAtt = () => {
    if (
      selectedImageFileForAtt instanceof Blob ||
      selectedImageFileForAtt instanceof File ||
      selectedFileForAtt instanceof Blob ||
      selectedFileForAtt instanceof File
    ) {
      if (contentForPostForAtt === "image") {
        handleChangeForFormData(
          attData?.nameValue,
          URL.createObjectURL(selectedImageFileForAtt),
          attData?.section_id
        );
        setOpenFileUploadModelForAtt(false);

      } else {
        handleChangeForFormData(
          attData?.nameValue,
          selectedFileForAtt,
          attData?.section_id
        );

        setOpenFileUploadModelForAtt(false);

      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const handleImageFileValueCustAttForAtt = () => {
    if (mediaDataForAtt === "image") {
      handleChangeForFormData(
        attData?.nameValue,
        selectedMediaFileForImageAtt,
        attData?.section_id,
        false,
        "",
        true,
        selectedMediaFileDetailsForImageAtt
      );
    } else if (mediaDataForAtt === "doc") {
      handleChangeForFormData(
        attData?.nameValue,
        selectedMediaFileForDocAtt,
        attData?.section_id,
        false,
        "",
        true,
        selectedMediaFileDetailsForDocAtt
      );
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleImageFileValue = (setFieldValue) => {
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      if (contentForPost === "image") {
        setFieldValue("image", URL.createObjectURL(selectedImageFile));

        setOpenFileUploadModel(false);
      } else {
        setFieldValue("file", selectedFile);

        setOpenFileUploadModel(false);

      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const validateAttributes = () => {
    let isValid = true;
    let errorMessages = [];

    const validateField = (attribute, labelPrefix = "") => {
      if (attribute?.is_mandatory === true) {
        if (!attribute?.is_repeatable) {
          if (
            attribute?.value === null ||
            attribute?.value === "" ||
            attribute?.value == NaN && attribute?.fieldtype !== "link_object"
          ) {
            isValid = false;
            // isValid = true;

            Toast.error(
              `The field "${labelPrefix}${attribute.label}" is required.`
            );

            errorMessages.push(
              `The field1 "${labelPrefix}${attribute.label}" is required.`
            );
          }
          // Email validation for non-repeatable fields
          if (attribute?.fieldtype === "email" && attribute?.value) {
            if (!isValidEmail(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid email.`
              );
            }
          }
          if (attribute?.fieldtype === "url" && attribute?.value) {
            if (!isValidURL(attribute.value)) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" must be a valid url.`
              );
            }
          }
          if (
            attribute?.fieldtype === "checkbox_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (
            attribute?.fieldtype === "dropdodown_multiple_choice" &&
            attribute?.value
          ) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute?.fieldtype === "tags" && attribute?.value) {
            if (attribute?.value?.length < 1) {
              isValid = false;
              Toast.error(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
              errorMessages.push(
                `The field "${labelPrefix}${attribute.label}" have atleast one selection.`
              );
            }
          }
          if (attribute.fieldtype === "link_object" && attribute?.value) {
            if (!attribute.value?.title || attribute.value.title === "") {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label}  Title" is required.`);
            }
            if (attribute.value?.url && !isValidURL(attribute.value.url)) {
              isValid = false;
              Toast.error(`The field "${labelPrefix}${attribute.label}  URL" must be a valid URL.`);
              errorMessages.push(`The field "${labelPrefix}${attribute.label} URL" must be a valid URL.`);
            }
          }
        } else {
          if (
            !Array.isArray(attribute?.value) ||
            attribute?.value.length == 0
          ) {
            isValid = false;
            // isValid = true;

            Toast.error(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
            errorMessages.push(
              `At least one "${labelPrefix}${attribute.label}" is required.`
            );
          }

          else {
            attribute.value.forEach((item, index) => {
              if (
                item?.is_mandatory &&
                (!item.value || item.value === "" || item.value == NaN)
              ) {
                isValid = false;
                // isValid = true;

                Toast.error(
                  `The field "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
                errorMessages.push(
                  `The field2 "${labelPrefix}${attribute.label} #${index + 1
                  }" is required.`
                );
              }

              if (attribute.fieldtype === "link_object") {
                if (!item.value?.title || item.value.title === "") {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} Title" is required.`);
                }
                if (item.value?.url && !isValidURL(item.value.url)) {
                  isValid = false;
                  Toast.error(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                  errorMessages.push(`The field "${labelPrefix}${attribute.label} #${index + 1} URL" must be a valid URL.`);
                }
              }
            });
          }
        }
      }

      if (attribute?.nameValue === "checkbox_single_choice_acknowledgement" && attribute?.value == true) {

        if (attribute?.childAtts[0]?.value?.length < 1) {
          isValid = false;
          Toast.error("Please add atleast one user for acknowledge")
        } if (attribute?.childAtts[1]?.value === "") {
          isValid = false;
          Toast.error("Please select due by date for acknowledge")
        } if (attribute?.childAtts[2]?.value === "") {
          isValid = false;
          Toast.error("Please select due by expiry action for acknowledge")
        } if (attribute?.childAtts[3]?.value === "") {
          isValid = false;
          Toast.error("Please select reminder notification for acknowledge")
        } if (attribute?.childAtts[4]?.value === 1) {
          if (attribute?.childAtts[4]?.childAtts[0]?.value === "") {
            isValid = false;
            Toast.error("Please enter acknowledgement texts")
          }
        }
        if (attribute?.childAtts[4]?.value == 2) {
          if (attribute?.childAtts[4]?.childAtts[1]?.value?.length < 1) {
            isValid = false;
            Toast.error("Please add atleast one acknowledgement question")
          } else if (attribute?.childAtts[4]?.childAtts[1]?.value?.length > 0) {
            attribute?.childAtts[4]?.childAtts[1]?.value?.forEach((childAtt, index) => {
              if (childAtt?.value == "") {
                isValid = false;
                Toast.error(
                  `Please add acknowledgement question for ${index + 1} is required.`
                );
              }
            })
          }
        }
      }
      // Check child attributes if they exist
      if (
        (attribute?.value === true ||
          attribute?.value === 1 ||
          attribute?.value == NaN) &&
        attribute?.childAtts?.length > 0
      ) {
        attribute?.childAtts?.forEach((childAtt) =>
          validateField(childAtt, `${labelPrefix}${attribute?.label} - `)
        );
      }
    };

    attributesData?.forEach((attribute) => validateField(attribute));

    return { isValid, errorMessages };
  };

  function extractFileName(url) {
    const baseUrl =
      "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
    return url.replace(baseUrl, "");
  }


  function processAttributes(attributes) {
    const result = {};

    attributes.forEach(attribute => {
      const { nameValue, fieldtype, attribute_id, value, is_repeatable, childAtts = [], ...rest } = attribute;

      let attributeValue = value;

      if (
        fieldtype === "checkbox_multiple_choice" ||
        fieldtype === "dropdodown_multiple_choice" ||
        fieldtype === "users_list" ||
        fieldtype === "group_list" ||
        fieldtype === "tags"
      ) {
        attributeValue = Array?.isArray(value)
          ? value?.join(",")
          : value;
      }

      if (fieldtype === "checkbox_single_choice") {
        attributeValue = String(value);
      }

      // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator

      if (fieldtype === "date" && value !== "") {
        const date = dayjs(value)?.format("YYYY-MM-DD");
        attributeValue = date;
      }
      if (fieldtype === "time" && value !== "") {
        const time = dayjs(value, "HH:mm A")?.format("HH:mm A");
        attributeValue = time;
      }
      if (fieldtype === "date_time" && value !== "") {
        const dateTime = dayjs(value)?.format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
        attributeValue = dateTime;
      }
      if (fieldtype === "date_range") {
        const startDate = moment(value.startDate);
        const endDate = moment(value.endDate);
        const formattedStartDate = startDate?.format("YYYY-MM-DD");
        const formattedEndDate = endDate?.format("YYYY-MM-DD");
        // If the fieldtype is date_range, format the value as a string with start date and end date separated by a custom separator
        attributeValue = `${formattedStartDate} - ${formattedEndDate}`;
      }

      if (is_repeatable == true) {
        if (Array?.isArray(value)) {
          attributeValue = value?.map(item => {
            if (item?.fieldtype === "time") {
              return {
                ...item,
                value: dayjs(item?.value, "HH:mm A")?.format("HH:mm A")
              };
            }

            return item;
          });
        }
        attributeValue = JSON?.stringify(attributeValue);
      }


      // Process the main attribute
      result[nameValue] = {
        attribute_id: attribute_id,
        attribute_core_name: nameValue,
        attribute_data_type: fieldtype === "checkbox_multiple_choice" ||
          fieldtype === "dropdodown_multiple_choice" || fieldtype === "users_group_list" || fieldtype === "Link object"
          ? "array"
          : fieldtype === "file"
            ? "file"
            : fieldtype === "image"
              ? "image"
              : "string",
        attribute_value: attributeValue,
        is_child: "no",
        childAtts: {}
      };

      // Recursively process child attributes if any
      if (childAtts.length > 0) {
        result[nameValue].childAtts = processAttributes(childAtts);
        result[nameValue].is_child = "yes";
      }
    });

    return result;
  }

  function generatePayload(attributeData) {
    const payload = processAttributes(attributeData);
    return payload;
  }

  const onSubmit2 = async (values) => {
    const validate = await validateAttributes();

    if (validate?.isValid === false) {
      setShowBelowError(true);
    } else {
      setShowBelowError(false);
      const formData = new FormData();
      let permissionData
      formData.append("title", values?.title);
      formData.append("editor_content", values?.content);
      formData.append("type_master_id", values?.postType);
      if (id) {
        formData.append("id", id);
      }
      formData.append(
        "status",
        values?.typePoststatus == true ? "enable" : "disable"
      );
      const fileAttribute = attributesData?.find(
        (data) => data?.fieldtype === "file"
      );
      const imageAttribute = attributesData?.find(
        (data) => data?.fieldtype === "image"
      );

      if (selectedMediaFileForImage !== "") {
        formData.append("image", selectedMediaFileDetailsForImage?.id);
      }

      if (selectedMediaFileForDoc !== "") {
        formData.append("file", selectedMediaFileDetailsForDoc?.id)
      }
      formData.append("file_summary", values?.fileDescription)


      if (selectedFolderID) {
        if (selectedFolderType && Number(selectedFolderType?.id) == Number(typeID)) {
          console.log("Inide in same type as folder");
          formData.append("folder_id", selectedFolderID)
        } else {
          console.log("Inide in different type as folder");
          formData.append("folder_id", 0)
        }
      }


      formData.append("type_master_id", values?.postType);
      formData.append("publish", publicationOption == "draft" ? 0 : 1);

      function extractFileName(url) {
        const baseUrl =
          "https://staging-intranet.s3.ap-southeast-2.amazonaws.com/images/";
        return url.replace(baseUrl, "");
      }

      const payload = generatePayload(attributesData);

      formData.append("attributes", JSON?.stringify([payload]));

      const categoryData = transformSelectedCategories(selectedCategories, getAllCategories);

      formData.append("category_data", JSON.stringify(categoryData))

      if (isAdvancePermission == true) {
        permissionData = permissions?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: data?.view == true ? 1 : 0,
            can_deny: data?.deny == true ? 1 : 0,
            can_edit: data?.edit == true ? 1 : 0,
            can_delete: data?.delete == true ? 1 : 0,
            can_resource: data?.redacted == true ? 1 : 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissions?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: data?.view == true ? 1 : 0,
            can_deny: data?.deny == true ? 1 : 0,
            can_edit: 0,
            can_delete: 0,
            can_resource: 0,
            label: data?.label,
          };
        });
      }

      formData.append("permission", JSON.stringify(permissionData));
      formData.append(
        "advance_permission",
        isAdvancePermission == false ? 0 : 1
      );

      if (
        (fileAttribute?.value !== "" && selectedFileForAtt instanceof Blob) ||
        selectedFileForAtt instanceof File
      ) {
        formData.append(`${fileAttribute?.nameValue}`, selectedFileForAtt);
      }

      if (
        (imageAttribute?.value !== "" &&
          selectedImageFileForAtt instanceof Blob) ||
        selectedImageFileForAtt instanceof File
      ) {
        formData.append(
          `${imageAttribute?.nameValue}`,
          selectedImageFileForAtt
        );
      }
      if (newsId) {
        try {
          setLoading(true);
          const response = await api.post(`user/post/update/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setPublicationOption("");

            Toast.success(response?.message);
            setSuccessMessage(response?.message);
            if (loggedUser?.user_type === "user") {
              localStorage.setItem("last-redirect-page-user", `/user-type/${typeSlug}`)
              navigate(`/user-type/${typeSlug}`);
            }
            else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
              localStorage.setItem("last-redirect-page-admin-user", `/user-type/${typeSlug}`)
              navigate(`/user-type/${typeSlug}`);
            }
            else {
              localStorage.setItem("last-redirect-page", `/type/${typeSlug}`)
              navigate(`/type/${typeSlug}`)
            }

            setPublicationOption("");
          } else {
            setLoading(false);
            setPublicationOption("");
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          setPublicationOption("");

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.image);
            const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post("user/post/store", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userToken}`,
            },
          });
          if (response?.success == true) {
            setLoading(false);
            setPublicationOption("");

            Toast.success(response?.message);
            setSuccessMessage(response?.message);

            if (loggedUser?.user_type === "user") {
              localStorage.setItem("last-redirect-page-user", `/user-type/${typeSlug}`)
              navigate(`/user-type/${typeSlug}`);
            }
            else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
              localStorage.setItem("last-redirect-page-admin-user", `/user-type/${typeSlug}`)
              navigate(`/user-type/${typeSlug}`);
            }
            else {
              localStorage.setItem("last-redirect-page", `/type/${typeSlug}`)
              navigate(`/type/${typeSlug}`)
            }
            setPublicationOption("");
          } else {
            setLoading(false);
            setPublicationOption("");
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);
          setPublicationOption("");

          if (err?.response?.status === 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status === 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.image);
            const errorMessages = Object.values(err?.response?.data?.errors);
            errorMessages.forEach((message) => {
              console.log(message);
              // Optionally, you can display the message using your Toast library or other UI elements
              Toast.error(message);
            });
          }
          if (err?.response?.status === 429) {
            Toast.error("Too many attemps wait for 2 min.")
          }
        }
      }
    }
  };

  const fetchAllTypes = async (status) => {
    try {
      setLoading(true);
      const response = await api.get(`user/type/list/${status}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const types = await response?.data?.map((type) => ({
        value: type?.id,
        label: type?.name,
        slug: type?.slug,
      }));
      allMenuTypes = types;
      setGetAllTypes(types);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAttributeSection = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/attribute-section`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllAttributeSections(response?.response);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchElementList = async (value) => {
    try {
      setLoading(true);
      const response = await api.get(`user/attribute/${value}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        const users = response?.response?.users;
        const groups = response?.response?.groups;
        const categories = response?.response?.category

        if (!id) {
          setAttributes(response?.response?.attributes);
        }
        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value == "all_users"
        );

        const allCategories = transformCategoryData(categories);
        setGetAllCategories(allCategories);
        setGetAllGroupsAndUsers(allGroupsAndUsersData);

        // Preselect "all_user"
        if (allUserOption) {
          setPermissions([allUserOption]);
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchElementListForUser = async (value) => {
    try {
      setLoading(true);
      const response = await api.get(`user/attribute/${value}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        const users = response?.response?.users;
        const groups = response?.response?.groups;

        const categories = response?.response?.category;

        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value == "all_users"
        );

        setGetAllGroupsAndUsers(allGroupsAndUsersData);

        // Preselect "all_user"
        if (allUserOption && !id) {
          setPermissions([allUserOption]);
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }

      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        Toast.warning(err?.response?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }

      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchTypePostDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/post/edit/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      if (response?.success === true) {
        let childAttData;
        const categories = response?.response?.category

        const contentData = await Promise?.all(
          response?.response?.attributes?.map(async (data) => {
            let contentValue = data?.value;
            let url = data?.value;
            let isSystem = data?.is_system ? data?.is_system : 0;
            let mediaFolderID = data?.media_folder_id ? data?.media_folder_id : ""
            let name = data?.value ? data?.value : "";

            if (data?.fieldtype === "date" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value);
            }

            if (data?.fieldtype === "date" && data?.value === "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype === "date_time" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value);
            }

            if (data?.fieldtype === "date_time" && data?.value === "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype === "time" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = dayjs(data?.value, "HH:mm A");
            }

            if (data?.fieldtype === "time" && data?.value === "" && data?.is_repeatable == false) {
              contentValue = "";
            }

            if (data?.fieldtype === "image" && data?.is_repeatable == false) {
              contentValue = data?.media_id
              setSelectedMediaFileForImageAtt(data?.media_id)
              setSelectedMediaFolderIDForImageAtt(data?.media_folder_id)
              setSelectedMediaTypeForImageAtt(data?.is_system === 0 ? "my-media" : "system")
            }

            if (data?.fieldtype === "file" && data?.is_repeatable == false) {
              contentValue = data?.media_id
            }

            if (data?.fieldtype === "dropdodown_single_choice" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = parseInt(data?.value);
            }

            if (data?.fieldtype === "checkbox_single_choice" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = data?.value == "true" ? true : false;
            }

            if (data?.fieldtype === "checkbox_multiple_choice" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = data?.value?.map((value) => {
                return value; // Using unary plus operator to convert string to number
              });
            }

            if (data?.fieldtype === "dropdodown_multiple_choice" && data?.value !== "" && data?.is_repeatable == false) {
              contentValue = data?.value?.map((value) => {
                return value; // Using unary plus operator to convert string to number
              });
            }

            if (data?.fieldtype === "users_list" && data?.value !== "" && data?.is_repeatable == false) {
              const transformedValue = data.value.map((item) => {
                const parsedItem = parseInt(item, 10);
                return isNaN(parsedItem) ? item : parsedItem;
              });
              contentValue = transformedValue;
            }


            if (data?.is_repeatable == true) {
              if (data?.fieldtype === "link_object") {
                if (typeof data?.value === "string" && data?.value !== "") {
                  const jsonString = data?.value;
                  console.log("jsonString:::", jsonString);
                  const cleanString = jsonString?.replace(/value\s*:\s*/g, "");
                  console.log("cleanString:::", cleanString);

                  // Parse the cleaned string into an array
                  const resultArray = JSON?.parse(cleanString);
                  console.log("resultArray1::", resultArray);

                  contentValue = resultArray;
                  if (resultArray?.length > 0) {
                    setAdditionalTextFields((prevFields) => [
                      ...prevFields,
                      ...resultArray,
                    ]);
                  }
                }
              } else if (data?.fieldtype === "time") {
                if (Array.isArray(data?.value) && data?.value.length > 0) {
                  const timeValues = data.value.map((item) => ({
                    ...item,
                    value: dayjs(item?.value, "HH:mm A"),
                  }));
                  console.log("timeValues::", timeValues);
                  setAdditionalTextFields((prevFields) => [
                    ...prevFields,
                    ...timeValues,
                  ]);
                  contentValue = timeValues;
                }
              } else {
                // Remove unwanted characters
                if (typeof data?.value === "string" && data?.value !== "") {
                  const jsonString = data?.value;
                  console.log("jsonString:::", jsonString);
                  const cleanString = jsonString?.replace(/value\s*:\s*/g, "");
                  console.log("cleanString:::", cleanString);

                  // Parse the cleaned string into an array
                  const resultArray = JSON?.parse(cleanString);

                  contentValue = resultArray;

                  if (resultArray?.length > 0) {
                    setAdditionalTextFields((prevFields) => [
                      ...prevFields,
                      ...resultArray,
                    ]);
                  }
                }

              }
            }
            if (data?.fieldtype === "date_range" && data?.value !== "" && data?.is_repeatable == false) {
              const dateString = data?.value;
              const [startDateString, endDateString] = dateString?.split(" - ");

              const startDate = moment(startDateString, "YYYY-MM-DD");
              const endDate = moment(endDateString, "YYYY-MM-DD");

              contentValue = {
                startDate: startDate?.isValid() ? startDate : null,
                endDate: endDate?.isValid() ? endDate : null,
              };
            }
            if (data?.childAtts?.length > 0) {
              const childData = await Promise?.all(
                data?.childAtts?.map(async (data) => {
                  let contentValue = data?.value;
                  let url = data?.value;
                  let isSystem = data?.is_system ? data?.is_system : 0;
                  let mediaFolderID = data?.media_folder_id ? data?.media_folder_id : "";
                  let name = data?.value ? data?.value : "";

                  if (
                    data?.fieldtype === "date" && data?.value !== "" && data?.is_repeatable == false
                  ) {
                    contentValue = dayjs(data?.value);
                  }

                  if (data?.fieldtype === "date" && data?.value === "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }

                  if (
                    data?.fieldtype === "date_time" && data?.value !== "" && data?.is_repeatable == false
                  ) {
                    contentValue = dayjs(data?.value);
                  }

                  if (data?.fieldtype === "date_time" && data?.value === "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }

                  if (data?.fieldtype === "time" && data?.value !== "" && data?.is_repeatable == false) {
                    contentValue = dayjs(data?.value, "HH:mm A");
                  }
                  if (data?.fieldtype === "time" && data?.value === "" && data?.is_repeatable == false) {
                    contentValue = "";
                  }


                  if (data?.fieldtype === "image" && data?.is_repeatable == false) {
                    contentValue = data?.media_id
                  }

                  if (data?.fieldtype === "file" && data?.is_repeatable == false) {
                    // Fetch the file from the URL and create a File object
                    contentValue = data?.media_id
                  }

                  if (data?.fieldtype === "dropdodown_single_choice" && data?.value !== "" && data?.is_repeatable == false) {
                    contentValue = parseInt(data?.value) || data?.value;
                  }

                  if (data?.fieldtype === "checkbox_single_choice" && data?.value !== "" && data?.is_repeatable == false) {
                    contentValue = data?.value === "true" ? true : false;
                  }

                  if (data?.fieldtype === "checkbox_multiple_choice" && data?.value !== "" && data?.is_repeatable == false) {
                    contentValue = data?.value?.map((value) => {
                      return value; // Using unary plus operator to convert string to number
                    });
                  }

                  if (data?.fieldtype === "users_list" && data?.value !== "" && data?.is_repeatable == false) {
                    if (data?.value?.length > 0) {
                      const transformedValue = data.value.map((item) => {
                        const parsedItem = parseInt(item, 10);
                        return isNaN(parsedItem) ? item : parsedItem;
                      });
                      contentValue = transformedValue;
                    } else {
                      contentValue = "";
                    }
                  }

                  if (data?.fieldtype === "dropdodown_multiple_choice" && data?.value !== "" && data?.is_repeatable == false) {
                    if (data?.value?.length > 0) {
                      contentValue = data?.value?.map((value) => {
                        return value; // Using unary plus operator to convert string to number
                      });
                    } else {
                      contentValue = "";
                    }
                  }

                  if (data?.is_repeatable == true) {
                    // Remove unwanted characters
                    const jsonString = data?.value;
                    const cleanString = jsonString?.replace(/value\s*:\s*/g, "");

                    // Parse the cleaned string into an array
                    const resultArray = JSON?.parse(cleanString);

                    contentValue = resultArray;

                    if (resultArray?.length > 0) {
                      setAdditionalTextFields((prevFields) => [
                        ...prevFields,
                        ...resultArray,
                      ]);
                    }
                  }
                  if (data?.fieldtype === "date_range" && data?.value !== "" && data?.is_repeatable == false) {
                    const dateString = data?.value;
                    const [startDateString, endDateString] =
                      dateString.split(" - ");

                    const startDate = moment(startDateString, "YYYY-MM-DD");
                    const endDate = moment(endDateString, "YYYY-MM-DD");

                    contentValue = {
                      startDate: startDate?.isValid() ? startDate : null,
                      endDate: endDate?.isValid() ? endDate : null,
                    };
                  }

                  if (data?.childAtts?.length > 0) {
                    const childData = await Promise?.all(
                      data?.childAtts?.map(async (data) => {
                        if (data?.is_repeatable == true) {
                          try {
                            // Remove unwanted characters
                            const jsonString = data?.value;
                            let properJsonString = jsonString;

                            // Ensure the string starts and ends with quotes
                            if (properJsonString?.startsWith('"') && properJsonString?.endsWith('"')) {
                              properJsonString = properJsonString?.slice(1, -1);
                            }

                            // Replace escaped quotes
                            properJsonString = properJsonString?.replace(/\\"/g, '"');

                            // Parse the cleaned string into an array
                            const resultArray = JSON?.parse(properJsonString);

                            // Check if the resultArray is actually an array
                            if (Array.isArray(resultArray) && resultArray.length > 0) {
                              setAdditionalTextFieldsForNestedChildAtt1((prevFields) => [
                                ...prevFields,
                                ...resultArray,
                              ]);
                            } else {
                              console.error("Parsed JSON is not an array or is empty:", resultArray);
                            }
                          } catch (error) {
                            console.error("Error parsing JSON:", error, data?.value);
                          }
                        }
                      })
                    );
                  }

                  return {
                    attribute_id: data?.attribute_id,
                    fieldtype: data?.fieldtype,
                    id: data?.id,
                    is_mandatory: data?.is_mandatory,
                    is_repeatable: data?.is_repeatable,
                    label: data?.label,
                    nameValue: data?.nameValue,
                    options: data?.options,
                    section_id: data?.section_id,
                    value: contentValue,
                    url: url,
                    is_system: isSystem,
                    media_folder_id: mediaFolderID,
                    name: name,
                    childAtts: data?.childAtts,
                  };
                })
              );
              childAttData = childData;
            }

            return {
              attribute_id: data?.attribute_id,
              fieldtype: data?.fieldtype,
              id: data?.id,
              is_mandatory: data?.is_mandatory,
              is_repeatable: data?.is_repeatable,
              label: data?.label,
              nameValue: data?.nameValue,
              options: data?.options,
              section_id: data?.section_id,
              value: contentValue,
              url: url,
              is_system: isSystem,
              media_folder_id: mediaFolderID,
              name: name,
              childAtts: childAttData,
            };
          })
        );

        const permissions = response?.response?.permission;

        const finalPermissions = permissions?.map((data) => {
          return {
            label: data?.label,
            value: data?.user_group_id,
            view: data?.can_view == 0 ? false : true,
            deny: data?.can_deny == 0 ? false : true,
            edit: data?.can_edit == 0 ? false : true,
            delete: data?.can_delete == 0 ? false : true,
            redacted: data?.can_resource == 0 ? false : true,
            type: data?.type,
          };
        });
        setPermissions(finalPermissions);
        setIsAdvancePermission(
          response?.response?.advance_permission == 0 ? false : true
        );

        const allCategories = transformCategoryData(categories);
        setGetAllCategories(allCategories);

        const transformedData = transformData(response?.response?.category);
        setSelectedCategories(transformedData)

        const users = response?.response?.users;
        const groups = response?.response?.groups;
        if (!id) {
          setAttributes(response?.response?.attributes);
        }
        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];

        setGetAllGroupsAndUsers(allGroupsAndUsersData);

        let fileData = {
          id: response?.response?.file?.id,
          title: response?.response?.file?.file_title
        }
        console.log("filedata:::", fileData)
        let imgFileData = {
          id: response?.response?.media_id,
          file_path: response?.response?.image,
          type: "png"
        }

        const typePostData = {
          title: response?.response?.title,
          content: response?.response?.editor_content,
          typePoststatus: response?.response?.status == "enable" ? true : false,
          image: response?.response?.image,
          postType: response?.response?.type_master_id,
          published_date: response?.response?.published_date,
          file: response?.response?.file?.file_title,
          fileDescription: response?.response?.file?.file_summary
        };

        setGetNewsData(typePostData);
        const selectedPostType = allMenuTypes?.find(
          (option) => option?.value === response?.response?.type_master_id
        );

        localStorage.setItem("type-selected-name", selectedPostType?.label);

        localStorage.setItem("type-selected-id", selectedPostType?.value);
        localStorage.setItem("type-selected-slug", selectedPostType?.slug);
        setPostType(selectedPostType);
        // setSelectedImageFile(response?.response?.image);
        // setSelectedFile(response?.response?.file?.original_name)
        setSelectedMediaTypeForImage(response?.response?.is_system === 0 ? "my-media" : "system")
        setSelectedMediaFolderIDForImage(response?.response?.media_folder_id)

        setSelectedMediaTypeForDoc(response?.response?.file?.is_system === 0 ? "my-media" : "system")
        setSelectedMediaFolderIDForDoc(response?.response?.file?.media_folder_id)
        setSelectedMediaFileForImage(response?.response?.media_id)
        setSelectedMediaFileForDoc(fileData?.id)
        setSelectedMediaFileDetailsForDoc(fileData)
        setSelectedMediaFileDetailsForImage(imgFileData)

        setAttributes(contentData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }
      }

      if (err?.response?.status === 403) {
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);

          navigate("/");
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        } else {
          localStorage.setItem("last-redirect-page", `/`)
          navigate(`/`);
        }
      }

      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const deleteMedia = async (id) => {
    let mediID;
    if (mediaDataFor === "image") {
      mediID = selectedMediaFileForImage
    } else if (mediaDataFor === "doc") {
      mediID = selectedMediaFileForDoc
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/file/delete/${mediID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setLoading(false);

        Toast.success(response?.message);
        let media_id;
        let folderID;
        if (mediaDataFor === "image") {
          setSelectedMediaFileForImage("")
          media_id = "";
          folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
          setSelectedMediaFileDetailsForImage()
        } else if (mediaDataFor === "doc") {
          setSelectedMediaFileForDoc("")
          media_id = "";
          folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc
          setSelectedMediaFileDetailsForDoc()
        }

        await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
        handleCloseForMediaModel()

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const deleteMediaAtt = async (id) => {
    let mediID;
    if (mediaDataForAtt === "image") {
      mediID = selectedMediaFileForImageAtt
    } else if (mediaDataForAtt === "doc") {
      mediID = selectedMediaFileForDocAtt
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/file/delete/${mediID}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setLoading(false);

        Toast.success(response?.message);
        let media_id;
        let folder_id;
        let type;
        if (mediaDataForAtt === "image") {
          folder_id = selectedMediaFolderIDForImageAtt
          setSelectedMediaFileForImageAtt("")
          media_id = "";
          setSelectedMediaFileDetailsForImageAtt()
          if (selectedMediaTypeForImageAtt === "system") {
            type = "system"
          } else {
            type = "user"
          }
        } else if (mediaDataForAtt === "doc") {
          folder_id = selectedMediaFolderIDFordocAtt
          setSelectedMediaFileForDocAtt("")
          media_id = "";
          setSelectedMediaFileDetailsForDocAtt()
          if (selectedMediaTypeForDocAtt === "system") {
            type = "system"
          } else {
            type = "user"
          }
        }

        await fetchMediaFilesForAtt(pageForMedia, rowsPerPageForMedia, mediaDataForAtt, folder_id, type)
        handleCloseForMediaModel()

      } else {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaFolders = async (dataFor, folderID) => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/browse/folders`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      // setLoading(false)
      if (response?.success === true) {
        setFolderList(response?.response)
        setMyFolderList(response?.response?.my_media_folder)
        setSystemFolderList(response?.response?.system_media_folder)

        if (dataFor === "image") {
          await setSelectedMediaTypeForImage(selectedMediaTypeForImage === "" ? "my-media" : selectedMediaTypeForImage)
        } else if (dataFor === "doc") {
          await setSelectedMediaTypeForDoc(selectedMediaTypeForDoc === "" ? "my-media" : selectedMediaTypeForDoc)
        }
        await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, dataFor, folderID)
        setOpenMediaUpload(true)
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaFoldersForAtt = async (dataFor, mediaType, folder_id) => {
    try {
      setLoading(true);
      const response = await api.get(
        `user/media/browse/folders`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setFolderListForAtt(response?.response)
        setMyFolderListForAtt(response?.response?.my_media_folder)
        setSystemFolderListForAtt(response?.response?.system_media_folder)
        let type;

        if (dataFor === "image") {
          if (dataFor === "image") {
            if (mediaType === "system") {
              type = "system"
            } else {
              type = "user"
            }
          }
        } else if (dataFor === "doc") {
          if (dataFor === "doc") {
            if (mediaType === "system") {
              type = "system"
            } else {
              type = "user"
            }
          }
        }
        await fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, dataFor, folder_id, type)
        setOpenMediaUploadForAtt(true)
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }


  const fetchMediaFiles = async (pageNo, perPage, dataFor, mediaFile, folderID) => {
    let type;
    if (dataFor === "image") {
      if (selectedMediaTypeForImage === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (dataFor === "doc") {
      if (selectedMediaTypeForDoc === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/browse/files?page=${pageNo}`,
        {
          folder_id: folderID,
          type: type,
          per_page: perPage,
          file_type: dataFor
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {

        setMediaListResponse(response?.response)
        setMediaList(response?.response?.data)
        if (dataFor === "image" && mediaFile !== "") {
          console.log("line 3928", mediaFile)
          setSelectedMediaFileForImage(mediaFile)
          setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage)
        } else if (dataFor === "doc" && selectedMediaFileForDoc !== "") {
          setSelectedMediaFileForDoc(selectedMediaFileForDoc)
          setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc)
        }
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchMediaFilesForAtt = async (pageNo, perPage, dataFor, folder_id, mediatype) => {
    let type;
    if (dataFor === "image") {
      if (mediatype === "system") {
        type = "system"
      } else {
        type = "user"
      }
    } else if (dataFor === "doc") {
      if (mediatype === "system") {
        type = "system"
      } else {
        type = "user"
      }
    }
    try {
      setLoading(true);
      const response = await api.post(
        `user/media/browse/files?page=${pageNo}`,
        {
          folder_id: folder_id,
          type: type,
          per_page: perPage,
          file_type: dataFor
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success === true) {
        setMediaListResponseAtt(response?.response)
        setMediaListAtt(response?.response?.data)
        if (dataFor === "image" && selectedMediaFileForImageAtt !== "") {

        } else if (dataFor === "doc" && selectedMediaFileForDocAtt !== "") {

        }
        setLoading(false)
      } else if (response?.success === false) {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/categories`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchGroupsAndUsersList = async () => {
    try {
      const response = await api.get(`/group-users-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        const users = response?.response?.users;
        const groups = response?.response?.groups;

        const categories = response?.response?.category

        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: false,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        setFetchAllUsers(finalUsers)

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value == "all_users"
        );

        setGetAllGroupsAndUsersForMedia(allGroupsAndUsersData)

        // Preselect "all_user"
        if (allUserOption && !isEdit) {
          setPermissionsForMedia([allUserOption])
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/type/news`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value === index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    const apiCall = async () => {
      if (id) {
        setGetAllAttributeSections([]);
        setAttributes([]);
        setAttData();
        setAdditionalTextFields([]);
        setSelectedFile(null);
        setSelectedFileForAtt(null);
        setSelectedImageFile(null);
        setSelectedImageFileForAtt(null);
        setSelectedCategories([])
        setPostType("");
        await fetchAllTypes("menu");
        await fetchAttributeSection();
        await fetchElementListForUser(typeID);
        await fetchTypePostDetails();
        await fetchGroupsAndUsersList()
      } else {
        setGetAllAttributeSections([]);
        setAttributes([]);
        setAttData();
        setAdditionalTextFields([]);
        setSelectedFile(null);
        setSelectedFileForAtt(null);
        setSelectedImageFile(null);
        setSelectedImageFileForAtt(null);
        setSelectedCategories([])
        setPostType("");
        await fetchAllTypes("menu");
        await fetchAttributeSection();
        await fetchElementList(typeID);
        fetchGroupsAndUsersList()

      }

    };
    apiCall();
  }, [id, typeID]);

  useEffect(() => {
    if (selectedMediaFolderIDForImage !== "" && selectedMediaTypeForImage !== "") {
      fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForImage, selectedMediaFolderIDForImage)
    }
  }, [selectedMediaFolderIDForImage, selectedMediaTypeForImage])

  useEffect(() => {
    if (selectedMediaFolderIDForImageAtt !== "" && selectedMediaTypeForImageAtt !== "") {
      let type;
      if (mediaDataForAtt === "image") {
        if (selectedMediaTypeForImageAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDForImageAtt === "" ? 0 : selectedMediaFolderIDForImageAtt, type)
    }
  }, [selectedMediaFolderIDForImageAtt, selectedMediaTypeForImageAtt])

  useEffect(() => {
    if (selectedMediaFolderIDFordoc !== "" && selectedMediaTypeForDoc !== "") {
      fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForDoc, selectedMediaFolderIDFordoc)
    }
  }, [selectedMediaFolderIDFordoc, selectedMediaTypeForDoc])

  useEffect(() => {
    if (selectedMediaFolderIDFordocAtt !== "" && selectedMediaTypeForDocAtt !== "") {
      let type;
      if (mediaDataForAtt === "doc") {
        if (selectedMediaTypeForDocAtt === "system") {
          type = "system"
        } else {
          type = "user"
        }
      }
      fetchMediaFilesForAtt(pageForMediaAtt, rowsPerPageForMediaAtt, mediaDataForAtt, selectedMediaFolderIDFordocAtt === "" ? 0 : selectedMediaFolderIDFordocAtt, type)
    }
  }, [selectedMediaFolderIDFordocAtt, selectedMediaTypeForDocAtt])

  useEffect(() => {
    if (selectedMedia !== "" && isEditMedia) {
      fetchMediaDetails()
    }
  }, [selectedMedia])

  useEffect(() => {
    if (selectedMedia !== "" && isRenameMedia) {
      fetchMediaDetailsForRename()
    }
  }, [selectedMedia])

  useEffect(() => {
    if (selectedMediaAtt !== "" && isEditMediaAtt) {
      fetchMediaDetailsAtt()
    }
  }, [selectedMediaAtt])

  useEffect(() => {
    if (selectedMediaAtt !== "" && isRenameMediaAtt) {
      fetchMediaDetailsForRenameAtt()
    }
  }, [selectedMediaAtt])

  return (
    <>
      {/* START :: MAIN CONTENT WRAPPER */}
      <div
        className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link underline="hover" key="1" color="inherit" component="h3">
                  {`${id ? "Edit" : "Create"} Content`}
                </Link>
                {id && (
                  <Typography
                    key="3"
                    component="h3"
                    color="inherit"
                    className="cursor-pointer"
                    onClick={() => {
                      if (loggedUser?.user_type === "user") {
                        localStorage.setItem("last-redirect-page-user", `/user-type/${typeName}`);

                        navigate(`/user-type/${typeName}`);
                      }
                      else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
                        localStorage.setItem("last-redirect-page-admin-user", `/user-type/${typeName}`)
                        navigate(`/user-type/${typeName}`);
                      } else {
                        localStorage.setItem("last-redirect-page", `/user-type/${typeName}`)
                        navigate(`/user-type/${typeName}`);
                      }

                    }}
                  >
                    {typeName}
                  </Typography>
                )}
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewNewsvalidationSchema}
            onSubmit={(values) => {
              onSubmit2(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
            }) => {
              return (
                <Form>
                  <Row className="mt-1">
                    <Col lg="9" md="7">
                      <Stack direction="column" className="gap-30">
                        <Accordion
                          defaultActiveKey={["0", "1", "2"]}
                          alwaysOpen
                          className="w-100"
                        >
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Page Content
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text">
                                    Page Type<span className="required">*</span>
                                  </Typography>
                                  <Select
                                    closeMenuOnSelect={true}
                                    options={getAllTypes}
                                    menuPlacement="auto"
                                    name="postType"
                                    value={getAllTypes.find(
                                      (option) =>
                                        option.value === values.postType
                                    )}
                                    placeholder="Select page type..."
                                    onChange={(selectedOption) => {
                                      setFieldValue(
                                        "postType",
                                        selectedOption
                                          ? selectedOption.value
                                          : ""
                                      );
                                      localStorage.setItem(
                                        "type-selected-name",
                                        selectedOption?.label
                                      );

                                      localStorage.setItem(
                                        "type-selected-id",
                                        selectedOption.value
                                      );
                                      localStorage.setItem(
                                        "type-selected-slug",
                                        selectedOption?.slug
                                      );
                                      setPostType(selectedOption);
                                      fetchElementList(selectedOption?.value);
                                    }}
                                    className="muilt-select-field mt-2"
                                    classNamePrefix="select"
                                    isDisabled
                                    styles={{
                                      option: (provided, state) => {
                                        return {
                                          ...provided,
                                          backgroundColor: state.isSelected
                                            ? "#660c605c"
                                            : provided.backgroundColor,
                                          color: state.isSelected
                                            ? "var(--dark-color)"
                                            : provided.color,
                                        };
                                      },
                                    }}
                                  />
                                  {errors?.postType && touched.postType && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.postType}
                                    </p>
                                  )}
                                </Col>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text">
                                    Page Title
                                    <span className="required">*</span>
                                  </Typography>
                                  <TextField
                                    className="input-field mt-2"
                                    fullWidth
                                    id="outlined-basic username"
                                    variant="outlined"
                                    size="small"
                                    name="title"
                                    placeholder="Enter title here..."
                                    value={values?.title}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "title",
                                        event.target.value
                                      );
                                    }}
                                    helperText={touched.title && errors.title}
                                    error={
                                      touched.title && Boolean(errors.title)
                                    }
                                  />
                                </Col>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text mb-2">
                                    Content<span className="required">*</span>
                                  </Typography>

                                  <CustomEditor
                                    setFieldValue={setFieldValue}
                                    value={values?.content}
                                    editorConfiguration={configForContentEditor}
                                    setFieldValueFor="content"
                                  />
                                  {errors?.content && touched.content && (
                                    <p
                                      style={{ color: "#d32f2f" }}
                                      className="error-msg"
                                    >
                                      {errors?.content}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text ">
                              Attributes
                            </Accordion.Header>
                            <Accordion.Body className="att-card-body">
                              <Box sx={{ width: "100%" }}>
                                <Box
                                  sx={{
                                    borderBottom: 1,
                                    borderColor: "divider",
                                  }}
                                  className="box-attribute"
                                >
                                  <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    // variant={isTablet && "scrollable"}
                                    variant="scrollable"
                                    className={
                                      isErrorOnClassificationTab === true ||
                                        isErrorOnEngTab === true ||
                                        isErrorOnIRTab === true
                                        ? "tabs"
                                        : ""
                                    }
                                    TabIndicatorProps={{
                                      style: {
                                        backgroundColor:
                                          (isErrorOnClassificationTab ===
                                            true &&
                                            value === 0) ||
                                            (isErrorOnIRTab === true &&
                                              value === 1) ||
                                            (isErrorOnEngTab === true &&
                                              value === 2)
                                            ? "red"
                                            : "#1976d2",
                                      },
                                    }}
                                  >
                                    {getAllAttributeSections?.map(
                                      (data, index) => (
                                        <Tab
                                          icon={
                                            <FontAwesomeIcon icon={faFolder} />
                                          }
                                          label={data?.name}
                                          iconPosition="start"
                                          {...a11yProps(index)}
                                        />
                                      )
                                    )}
                                  </Tabs>
                                </Box>
                                {getAllAttributeSections?.map((data, index) => (
                                  <CustomTabPanel
                                    value={value}
                                    index={index}
                                    errorTab={errorTab}
                                  >
                                    <Row>
                                      <>

                                        {attributesData?.map((attdata, index) => (
                                          <>
                                            {attdata?.section_id === data?.id && (
                                              <>
                                                {attdata?.is_repeatable ==
                                                  true ? (
                                                  <Col lg="12">
                                                    {/* {attdata?.fieldtype !==
                                                      "checkbox_single_choice" && ( */}
                                                    <>
                                                      <div className="extra-links-div">
                                                        <Typography
                                                          className={`${attdata?.fieldtype ===
                                                            "text_editor" ||
                                                            attdata?.fieldtype ===
                                                            "html" ||
                                                            attdata?.fieldtype ===
                                                            "image" ||
                                                            attdata?.fieldtype ===
                                                            "file"
                                                            ? "mb-2 fs-14"
                                                            : "fs-14"
                                                            }`}
                                                        >
                                                          {attdata?.label}{" "}
                                                          {attdata?.is_mandatory ==
                                                            true && (
                                                              <span className="required">
                                                                *
                                                              </span>
                                                            )}
                                                        </Typography>
                                                        <Button
                                                          className="btn primary-btn"
                                                          onClick={() =>
                                                            handleAddTextField(
                                                              attdata?.nameValue,
                                                              attdata?.label,
                                                              attdata?.section_id,
                                                              attdata?.fieldtype,
                                                              attdata?.options,
                                                              attdata?.is_mandatory,
                                                              attdata?.is_repeatable,
                                                              attdata?.value
                                                            )
                                                          }
                                                        >
                                                          <FontAwesomeIcon
                                                            icon={faPlus}
                                                          />
                                                          {`Add ${attdata?.label}`}
                                                        </Button>
                                                      </div>
                                                      <div>
                                                        {additionalTextFields.map(
                                                          (
                                                            textField,
                                                            index
                                                          ) => (
                                                            <div key={index}>
                                                              {textField?.nameValue ==
                                                                attdata?.nameValue && (
                                                                  <div className={`${attdata?.fieldtype === "link_object" ? 'add-title-div-2' : 'add-title-div'}`}>
                                                                    {textField?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <div className="w-100">
                                                                          <Select
                                                                            closeMenuOnSelect={
                                                                              true
                                                                            }
                                                                            options={
                                                                              textField?.options
                                                                            }
                                                                            menuPlacement="auto"
                                                                            name={
                                                                              attdata?.nameValue
                                                                            }
                                                                            placeholder={`Select ${textField?.label}...`}
                                                                            className="muilt-select-field mt-2 w-100"
                                                                            classNamePrefix="select"
                                                                            value={textField?.options.find(
                                                                              (
                                                                                option
                                                                              ) =>
                                                                                option?.value ==
                                                                                textField?.value
                                                                            )}
                                                                            onChange={(
                                                                              selectedOption
                                                                            ) => {
                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                selectedOption?.value,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype
                                                                              );
                                                                            }}
                                                                            styles={{
                                                                              option:
                                                                                (
                                                                                  provided,
                                                                                  state
                                                                                ) => {
                                                                                  return {
                                                                                    ...provided,
                                                                                    backgroundColor:
                                                                                      state.isSelected
                                                                                        ? "#660c605c"
                                                                                        : provided.backgroundColor,
                                                                                    color:
                                                                                      state.isSelected
                                                                                        ? "var(--dark-color)"
                                                                                        : provided.color,
                                                                                  };
                                                                                },
                                                                            }}
                                                                          />
                                                                          {textField?.is_mandatory ==
                                                                            true &&
                                                                            showBelowError ==
                                                                            true &&
                                                                            textField?.value ===
                                                                            "" && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label
                                                                                  } ${index +
                                                                                  1
                                                                                  } is required.`}
                                                                              </p>
                                                                            )}
                                                                        </div>
                                                                      </>
                                                                    ) : textField?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <div className="w-100">
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            multiline
                                                                            maxRows={
                                                                              2
                                                                            }
                                                                            name={
                                                                              textField?.nameValue
                                                                            }
                                                                            placeholder={`Enter ${textField?.label}...`}
                                                                            value={
                                                                              textField?.value
                                                                            }
                                                                            onChange={(
                                                                              event
                                                                            ) =>
                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                event
                                                                                  ?.target
                                                                                  ?.value,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype

                                                                              )
                                                                            }
                                                                          />
                                                                          {textField?.is_mandatory ==
                                                                            true &&
                                                                            showBelowError ==
                                                                            true &&
                                                                            textField?.value ===
                                                                            "" && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label
                                                                                  } ${index +
                                                                                  1
                                                                                  } is required.`}
                                                                              </p>
                                                                            )}
                                                                        </div>
                                                                      </>
                                                                    ) : textField?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <div className="w-100">
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            name={
                                                                              textField?.nameValue
                                                                            }
                                                                            placeholder={`Enter ${textField?.label}...`}
                                                                            value={
                                                                              textField?.value
                                                                            }
                                                                            onChange={(
                                                                              event
                                                                            ) =>
                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                event
                                                                                  ?.target
                                                                                  ?.value,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype

                                                                              )
                                                                            }
                                                                          />
                                                                          {textField?.is_mandatory ==
                                                                            true &&
                                                                            showBelowError ==
                                                                            true &&
                                                                            textField?.value ===
                                                                            "" && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label
                                                                                  } ${index +
                                                                                  1
                                                                                  } is required.`}
                                                                              </p>
                                                                            )}
                                                                        </div>
                                                                      </>
                                                                    ) : textField?.fieldtype ===
                                                                      "date" ? (
                                                                      <div className="w-100">
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                textField?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                textField?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForAdditionalTextField(
                                                                                  textField?.id,
                                                                                  newValue,
                                                                                  attdata?.nameValue,
                                                                                  attdata?.section_id,
                                                                                  textField?.fieldtype

                                                                                );
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "radio_single_choice" ? (
                                                                      <div className="w-100">
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                        >
                                                                          {textField?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      textField?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "link" ? (
                                                                      <div className="w-100">
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${textField?.label}...`}
                                                                          value={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) =>
                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            )
                                                                          }
                                                                          type="text"
                                                                        />
                                                                        {textField?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {textField?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${textField?.label} ${index} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {textField?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  textField?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${textField?.label} ${index} must be a valid URL..`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "email" ? (
                                                                      <div className="w-100">
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${textField?.label}...`}
                                                                          value={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) =>
                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            )
                                                                          }
                                                                          type="text"
                                                                        />
                                                                        {textField?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {textField?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${textField?.label} ${index} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {textField?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  textField?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${textField?.label} ${index} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "number" ? (
                                                                      <div className="w-100">
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${textField?.label}...`}
                                                                          value={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) =>
                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            )
                                                                          }
                                                                          type="number"
                                                                        />
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <div className="w-100">
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                textField?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                textField?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                handleChangeForAdditionalTextField(
                                                                                  textField?.id,
                                                                                  event
                                                                                    ?.target
                                                                                    ?.checked,
                                                                                  attdata?.nameValue,
                                                                                  attdata?.section_id,
                                                                                  textField?.fieldtype

                                                                                );
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            textField?.label
                                                                          }
                                                                          className="font-inter"
                                                                        />
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <div className="w-100">
                                                                        <>
                                                                          {textField?.options?.map(
                                                                            (
                                                                              multiData,
                                                                              index
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attributesData
                                                                                        ?.find(attr => attr.nameValue === textField.nameValue && attr.section_id === textField.section_id)
                                                                                        ?.value?.find(val => typeof val === 'object' && val.id === textField.id)
                                                                                        ?.value?.includes(multiData.id) || false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                        ?.toString()
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;
                                                                                      const attributeIndex = attributesData?.findIndex(
                                                                                        attr => attr.nameValue === textField.nameValue && attr.section_id === textField.section_id
                                                                                      );
                                                                                      let updatedValues;


                                                                                      if (attributeIndex !== -1) {
                                                                                        const updatedAttributes = [...attributesData];
                                                                                        const attribute = updatedAttributes[attributeIndex];
                                                                                        const repeatableIndex = attribute?.value?.findIndex(val => typeof val === 'object' && val.id === textField.id);

                                                                                        if (repeatableIndex !== -1) {
                                                                                          const repeatable = { ...attribute.value[repeatableIndex] };

                                                                                          if (checked) {
                                                                                            updatedValues = [...(repeatable.value || []), optionId];
                                                                                          } else {
                                                                                            updatedValues = (repeatable.value || [])?.filter(val => val !== optionId);
                                                                                          }

                                                                                          repeatable.value = updatedValues;
                                                                                          updatedAttributes[attributeIndex].value[repeatableIndex] = repeatable;

                                                                                          // setAttributesData(updatedAttributes);

                                                                                          console.log("updatedValues::", updatedValues);
                                                                                        }
                                                                                      }
                                                                                      handleChangeForAdditionalTextField(
                                                                                        textField?.id,
                                                                                        updatedValues,
                                                                                        attdata?.nameValue,
                                                                                        attdata?.section_id,
                                                                                        textField?.fieldtype

                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                          {textField?.is_mandatory ==
                                                                            true &&
                                                                            showBelowError ==
                                                                            true &&
                                                                            textField
                                                                              ?.value
                                                                              ?.length <
                                                                            1 && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label
                                                                                  } ${index +
                                                                                  1
                                                                                  } is required at least one selection.`}
                                                                              </p>
                                                                            )}
                                                                        </>
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <div className="w-100">
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            textField?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Select ${textField?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={textField?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              textField?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              selectedValues,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "time" ? (
                                                                      <div className="w-100">
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                textField?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                textField?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForAdditionalTextField(
                                                                                  textField?.id,
                                                                                  newValue,
                                                                                  attdata?.nameValue,
                                                                                  attdata?.section_id,
                                                                                  textField?.fieldtype

                                                                                );
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <div className="w-100">
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                textField?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                textField?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForAdditionalTextField(
                                                                                  textField?.id,
                                                                                  newValue,
                                                                                  attdata?.nameValue,
                                                                                  attdata?.section_id,
                                                                                  textField?.fieldtype

                                                                                );
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <div className="w-100">
                                                                        <>
                                                                          <DateRangePicker
                                                                            // ref={keyRef}
                                                                            initialSettings={{
                                                                              startDate:
                                                                                textField
                                                                                  ?.value
                                                                                  ?.startDate,
                                                                              endDate:
                                                                                textField
                                                                                  ?.value
                                                                                  ?.endDate,
                                                                              autoUpdateInput: false,
                                                                            }}
                                                                            onApply={(
                                                                              _,
                                                                              range
                                                                            ) => {
                                                                              const startDate =
                                                                                range?.startDate;
                                                                              const endDate =
                                                                                range?.endDate;
                                                                              let data =
                                                                              {
                                                                                startDate:
                                                                                  startDate,
                                                                                endDate:
                                                                                  endDate,
                                                                              };

                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                data,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype

                                                                              );
                                                                            }}
                                                                          >
                                                                            <TextField
                                                                              className="input-field mt-2"
                                                                              fullWidth
                                                                              id="outlined-basic short summary"
                                                                              variant="outlined"
                                                                              placeholder={`Enter ${textField?.label}...`}
                                                                              value={
                                                                                textField?.value
                                                                                  ? `${moment(
                                                                                    textField
                                                                                      ?.value
                                                                                      ?.startDate
                                                                                  ).format(
                                                                                    "DD/MM/YYYY"
                                                                                  )} - ${moment(
                                                                                    textField
                                                                                      ?.value
                                                                                      ?.endDate
                                                                                  ).format(
                                                                                    "DD/MM/YYYY"
                                                                                  )}`
                                                                                  : ""
                                                                              }
                                                                              type="text"
                                                                            />
                                                                          </DateRangePicker>
                                                                        </>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <div className="w-100">
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor.getData();

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              data,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                        />
                                                                        {/* ) :
                                                                        textField?.fieldtype
                                                                        === "html"
                                                                        ? (
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor.getData();

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              data,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                        /> */}
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "html" ? (
                                                                      <div className="w-100">
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            textField?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor.getData();

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              data,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                        />

                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "image" ? (
                                                                      <div className="w-100">
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {textField?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  textField?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                textField
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "file" ? (
                                                                      <div className="w-100">
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {textField?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {textField?.value !==
                                                                                "" &&
                                                                                `File name: ${textField
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                textField?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                textField
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <div className="w-100">
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            textField?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Select ${textField?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti

                                                                          value={textField?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              textField?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              selectedValues,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <div className="w-100">
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            textField?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            textField?.nameValue
                                                                          }
                                                                          placeholder={`Select ${textField?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={textField?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              textField?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForAdditionalTextField(
                                                                              textField?.id,
                                                                              selectedValues,
                                                                              attdata?.nameValue,
                                                                              attdata?.section_id,
                                                                              textField?.fieldtype

                                                                            );
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {textField?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          textField?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${textField?.label
                                                                                } ${index +
                                                                                1
                                                                                } is required.`}
                                                                            </p>
                                                                          )}
                                                                      </div>
                                                                    ) : textField?.fieldtype ===
                                                                      "link_object" ? (
                                                                      <>
                                                                        <div className="w-100">
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            name={
                                                                              textField?.nameValue
                                                                            }
                                                                            placeholder={`Enter ${textField?.label} Title...`}
                                                                            value={
                                                                              textField?.value?.title || ""
                                                                            }
                                                                            onChange={(
                                                                              event
                                                                            ) =>
                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                event
                                                                                  ?.target
                                                                                  ?.value,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype,
                                                                                "title"
                                                                              )
                                                                            }
                                                                          />
                                                                          {textField?.is_mandatory ==
                                                                            true &&
                                                                            showBelowError ==
                                                                            true &&
                                                                            textField?.value ===
                                                                            "" && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label
                                                                                  } ${index +
                                                                                  1
                                                                                  } is required.`}
                                                                              </p>
                                                                            )}
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            name={
                                                                              textField?.nameValue
                                                                            }
                                                                            placeholder={`Enter ${textField?.label} URL...`}
                                                                            value={
                                                                              textField?.value?.url || ""
                                                                            }
                                                                            onChange={(
                                                                              event
                                                                            ) =>
                                                                              handleChangeForAdditionalTextField(
                                                                                textField?.id,
                                                                                event
                                                                                  ?.target
                                                                                  ?.value,
                                                                                attdata?.nameValue,
                                                                                attdata?.section_id,
                                                                                textField?.fieldtype,
                                                                                "url"
                                                                              )
                                                                            }
                                                                            type="text"
                                                                          />


                                                                          {textField?.value?.url &&
                                                                            !isValidURL(
                                                                              textField?.value?.url
                                                                            ) && (
                                                                              <p
                                                                                style={{
                                                                                  color:
                                                                                    "#d32f2f",
                                                                                }}
                                                                                className="error-msg"
                                                                              >
                                                                                {`${textField?.label} ${index} must be a valid URL..`}
                                                                              </p>
                                                                            )}

                                                                        </div>
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    <div className={`${attdata?.fieldtype === "link_object" ? "add-title-div-btn-2" : "add-title-div-btn"}`}>
                                                                      <Button
                                                                        className="btn btn-danger"
                                                                        onClick={() =>
                                                                          removeExtraFields(
                                                                            textField.id,
                                                                            attdata?.nameValue,
                                                                            attdata?.section_id
                                                                          )
                                                                        }
                                                                      >
                                                                        <FontAwesomeIcon
                                                                          icon={
                                                                            faTimes
                                                                          }
                                                                        />
                                                                      </Button>
                                                                    </div>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </>
                                                    {/* )} */}
                                                  </Col>
                                                ) : (
                                                  <Col lg="12">
                                                    {attdata?.fieldtype !==
                                                      "checkbox_single_choice" && (
                                                        <Typography
                                                          className={`${attdata?.fieldtype ===
                                                            "text_editor" ||
                                                            attdata?.fieldtype ===
                                                            "html" ||
                                                            attdata?.fieldtype ===
                                                            "image" ||
                                                            attdata?.fieldtype ===
                                                            "file"
                                                            ? "mb-2 fs-14"
                                                            : "fs-14"
                                                            }`}
                                                        >
                                                          {attdata?.label}{" "}
                                                          {attdata?.is_mandatory ==
                                                            true && (
                                                              <span className="required">
                                                                *
                                                              </span>
                                                            )}
                                                        </Typography>
                                                      )}

                                                    {attdata?.fieldtype ===
                                                      "dropdodown_single_choice" && attdata?.nameValue !== "dropdodown_single_choice_review" && attdata?.nameValue !== "dropdodown_single_choice_highlightasmodal" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={true}
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2"
                                                          classNamePrefix="select"
                                                          value={attdata?.options.find(
                                                            (option) =>
                                                              option?.value ==
                                                              attdata?.value
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedOption?.value,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                        {attdata?.childAtts
                                                          ?.length > 0 && (
                                                            <>
                                                              {attdata?.childAtts?.map(
                                                                (
                                                                  childAtt,
                                                                  index
                                                                ) => (
                                                                  <Col lg="12 mt-3 test-12">
                                                                    {childAtt?.fieldtype !==
                                                                      "checkbox_single_choice" && (
                                                                        <Typography
                                                                          className={`${childAtt?.fieldtype ===
                                                                            "text_editor" ||
                                                                            childAtt?.fieldtype ===
                                                                            "html" ||
                                                                            childAtt?.fieldtype ===
                                                                            "image" ||
                                                                            childAtt?.fieldtype ===
                                                                            "file"
                                                                            ? "mb-2 fs-14"
                                                                            : "fs-14"
                                                                            }`}
                                                                        >
                                                                          {
                                                                            childAtt?.label
                                                                          }{" "}
                                                                          {childAtt?.is_mandatory ==
                                                                            true && (
                                                                              <span className="required">
                                                                                *
                                                                              </span>
                                                                            )}
                                                                        </Typography>
                                                                      )}
                                                                    {childAtt?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ==
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedOption?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              selectedOption?.value;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          multiline
                                                                          maxRows={
                                                                            2
                                                                          }
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                .target
                                                                                .value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enters ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={
                                                                                childAtt?.value
                                                                              }
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "link" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid URL.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "email" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "number" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="number"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <>
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                childAtt?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                const newValue =
                                                                                  event
                                                                                    .target
                                                                                    .checked;

                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            childAtt?.label
                                                                          }
                                                                          className="font-inter ack-1"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" && childAtt?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <>
                                                                        {childAtt?.options?.map(
                                                                          (
                                                                            multiData,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attdata?.childAtts
                                                                                        .find(
                                                                                          (
                                                                                            attr
                                                                                          ) =>
                                                                                            attr?.id ==
                                                                                            childAtt?.id &&
                                                                                            attr?.section_id ==
                                                                                            childAtt?.section_id
                                                                                        )
                                                                                        ?.value?.includes(
                                                                                          multiData?.id
                                                                                        ) ||
                                                                                      false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;

                                                                                      let updatedValues;
                                                                                      if (
                                                                                        checked
                                                                                      ) {
                                                                                        // Add the optionId to the array if checked
                                                                                        updatedValues =
                                                                                          [
                                                                                            ...(attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                              []),
                                                                                            optionId,
                                                                                          ];
                                                                                      } else {
                                                                                        // Remove the optionId from the array if unchecked

                                                                                        updatedValues =
                                                                                          (
                                                                                            attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                            []
                                                                                          ).filter(
                                                                                            (
                                                                                              val
                                                                                            ) =>
                                                                                              val !==
                                                                                              optionId
                                                                                          );
                                                                                      }
                                                                                      handleChangeForChildAtts(
                                                                                        childAtt?.nameValue,
                                                                                        updatedValues,
                                                                                        childAtt?.section_id,
                                                                                        childAtt,
                                                                                        attdata
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            );
                                                                          }
                                                                        )}
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <>
                                                                        <DateRangePicker
                                                                          // ref={keyRef}
                                                                          initialSettings={{
                                                                            startDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.startDate,
                                                                            endDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.endDate,
                                                                            autoUpdateInput: false,
                                                                          }}
                                                                          onApply={(
                                                                            _,
                                                                            range
                                                                          ) => {
                                                                            const startDate =
                                                                              range?.startDate;
                                                                            const endDate =
                                                                              range?.endDate;
                                                                            let data =
                                                                            {
                                                                              startDate:
                                                                                startDate,
                                                                              endDate:
                                                                                endDate,
                                                                            };

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        >
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            placeholder={`Enter ${childAtt?.label}...`}
                                                                            value={
                                                                              childAtt?.value
                                                                                ? `${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.startDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )} - ${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.endDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )}`
                                                                                : ""
                                                                            }
                                                                            type="text"
                                                                          />
                                                                        </DateRangePicker>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "html" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "image" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  childAtt?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "file" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                `File name: ${childAtt
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                childAtt?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}

                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Col>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "dropdodown_single_choice" && attdata?.nameValue === "dropdodown_single_choice_review" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={true}
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2"
                                                          classNamePrefix="select"
                                                          value={attdata?.options.find(
                                                            (option) =>
                                                              option?.value ==
                                                              attdata?.value
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedOption?.value,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                        {attdata?.childAtts
                                                          ?.length > 0 && attdata?.value != 1 && (
                                                            <>
                                                              {attdata?.childAtts?.map(
                                                                (
                                                                  childAtt,
                                                                  index
                                                                ) => (
                                                                  <Col lg="12 mt-3 test-12">
                                                                    {childAtt?.fieldtype !==
                                                                      "checkbox_single_choice" && (
                                                                        <Typography
                                                                          className={`${childAtt?.fieldtype ===
                                                                            "text_editor" ||
                                                                            childAtt?.fieldtype ===
                                                                            "html" ||
                                                                            childAtt?.fieldtype ===
                                                                            "image" ||
                                                                            childAtt?.fieldtype ===
                                                                            "file"
                                                                            ? "mb-2 fs-14"
                                                                            : "fs-14"
                                                                            }`}
                                                                        >
                                                                          {
                                                                            childAtt?.label
                                                                          }{" "}
                                                                          {childAtt?.is_mandatory ==
                                                                            true && (
                                                                              <span className="required">
                                                                                *
                                                                              </span>
                                                                            )}
                                                                        </Typography>
                                                                      )}
                                                                    {childAtt?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ==
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedOption?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              selectedOption?.value;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          multiline
                                                                          maxRows={
                                                                            2
                                                                          }
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                .target
                                                                                .value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enters ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={
                                                                                childAtt?.value
                                                                              }
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "link" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid URL.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "email" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "number" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="number"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <>
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                childAtt?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                const newValue =
                                                                                  event
                                                                                    .target
                                                                                    .checked;

                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            childAtt?.label
                                                                          }
                                                                          className="font-inter ack-2"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" && childAtt?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <>
                                                                        {childAtt?.options?.map(
                                                                          (
                                                                            multiData,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attdata?.childAtts
                                                                                        .find(
                                                                                          (
                                                                                            attr
                                                                                          ) =>
                                                                                            attr?.id ==
                                                                                            childAtt?.id &&
                                                                                            attr?.section_id ==
                                                                                            childAtt?.section_id
                                                                                        )
                                                                                        ?.value?.includes(
                                                                                          multiData?.id
                                                                                        ) ||
                                                                                      false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;

                                                                                      let updatedValues;
                                                                                      if (
                                                                                        checked
                                                                                      ) {
                                                                                        // Add the optionId to the array if checked
                                                                                        updatedValues =
                                                                                          [
                                                                                            ...(attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                              []),
                                                                                            optionId,
                                                                                          ];
                                                                                      } else {
                                                                                        // Remove the optionId from the array if unchecked

                                                                                        updatedValues =
                                                                                          (
                                                                                            attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                            []
                                                                                          ).filter(
                                                                                            (
                                                                                              val
                                                                                            ) =>
                                                                                              val !==
                                                                                              optionId
                                                                                          );
                                                                                      }
                                                                                      handleChangeForChildAtts(
                                                                                        childAtt?.nameValue,
                                                                                        updatedValues,
                                                                                        childAtt?.section_id,
                                                                                        childAtt,
                                                                                        attdata
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            );
                                                                          }
                                                                        )}
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <>
                                                                        <DateRangePicker
                                                                          // ref={keyRef}
                                                                          initialSettings={{
                                                                            startDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.startDate,
                                                                            endDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.endDate,
                                                                            autoUpdateInput: false,
                                                                          }}
                                                                          onApply={(
                                                                            _,
                                                                            range
                                                                          ) => {
                                                                            const startDate =
                                                                              range?.startDate;
                                                                            const endDate =
                                                                              range?.endDate;
                                                                            let data =
                                                                            {
                                                                              startDate:
                                                                                startDate,
                                                                              endDate:
                                                                                endDate,
                                                                            };

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        >
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            placeholder={`Enter ${childAtt?.label}...`}
                                                                            value={
                                                                              childAtt?.value
                                                                                ? `${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.startDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )} - ${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.endDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )}`
                                                                                : ""
                                                                            }
                                                                            type="text"
                                                                          />
                                                                        </DateRangePicker>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "html" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "image" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  childAtt?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "file" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                `File name: ${childAtt
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                childAtt?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}

                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Col>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "dropdodown_single_choice" && attdata?.nameValue === "dropdodown_single_choice_highlightasmodal" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={true}
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2 xyz-single"
                                                          classNamePrefix="select"
                                                          value={attdata?.options.find(
                                                            (option) =>
                                                              option?.value ==
                                                              attdata?.value
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedOption?.value,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                        {attdata?.childAtts
                                                          ?.length > 0 && attdata?.value == 1 && (
                                                            <>
                                                              {attdata?.childAtts?.map(
                                                                (
                                                                  childAtt,
                                                                  index
                                                                ) => (
                                                                  <Col lg="12 mt-3 test-12">
                                                                    {childAtt?.fieldtype !==
                                                                      "checkbox_single_choice" && (
                                                                        <Typography
                                                                          className={`${childAtt?.fieldtype ===
                                                                            "text_editor" ||
                                                                            childAtt?.fieldtype ===
                                                                            "html" ||
                                                                            childAtt?.fieldtype ===
                                                                            "image" ||
                                                                            childAtt?.fieldtype ===
                                                                            "file"
                                                                            ? "mb-2 fs-14"
                                                                            : "fs-14"
                                                                            }`}
                                                                        >
                                                                          {
                                                                            childAtt?.label
                                                                          }{" "}
                                                                          {childAtt?.is_mandatory ==
                                                                            true && (
                                                                              <span className="required">
                                                                                *
                                                                              </span>
                                                                            )}
                                                                        </Typography>
                                                                      )}
                                                                    {childAtt?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ==
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedOption?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              selectedOption?.value;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          multiline
                                                                          maxRows={
                                                                            2
                                                                          }
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                .target
                                                                                .value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enters ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={
                                                                                childAtt?.value
                                                                              }
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "link" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid URL.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "email" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "number" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="number"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <>
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                childAtt?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                const newValue =
                                                                                  event
                                                                                    .target
                                                                                    .checked;

                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            childAtt?.label
                                                                          }
                                                                          className="font-inter ack-3"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" && childAtt?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <>
                                                                        {childAtt?.options?.map(
                                                                          (
                                                                            multiData,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attdata?.childAtts
                                                                                        .find(
                                                                                          (
                                                                                            attr
                                                                                          ) =>
                                                                                            attr?.id ==
                                                                                            childAtt?.id &&
                                                                                            attr?.section_id ==
                                                                                            childAtt?.section_id
                                                                                        )
                                                                                        ?.value?.includes(
                                                                                          multiData?.id
                                                                                        ) ||
                                                                                      false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;

                                                                                      let updatedValues;
                                                                                      if (
                                                                                        checked
                                                                                      ) {
                                                                                        // Add the optionId to the array if checked
                                                                                        updatedValues =
                                                                                          [
                                                                                            ...(attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                              []),
                                                                                            optionId,
                                                                                          ];
                                                                                      } else {
                                                                                        // Remove the optionId from the array if unchecked

                                                                                        updatedValues =
                                                                                          (
                                                                                            attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                            []
                                                                                          ).filter(
                                                                                            (
                                                                                              val
                                                                                            ) =>
                                                                                              val !==
                                                                                              optionId
                                                                                          );
                                                                                      }
                                                                                      handleChangeForChildAtts(
                                                                                        childAtt?.nameValue,
                                                                                        updatedValues,
                                                                                        childAtt?.section_id,
                                                                                        childAtt,
                                                                                        attdata
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            );
                                                                          }
                                                                        )}
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <>
                                                                        <DateRangePicker
                                                                          // ref={keyRef}
                                                                          initialSettings={{
                                                                            startDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.startDate,
                                                                            endDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.endDate,
                                                                            autoUpdateInput: false,
                                                                          }}
                                                                          onApply={(
                                                                            _,
                                                                            range
                                                                          ) => {
                                                                            const startDate =
                                                                              range?.startDate;
                                                                            const endDate =
                                                                              range?.endDate;
                                                                            let data =
                                                                            {
                                                                              startDate:
                                                                                startDate,
                                                                              endDate:
                                                                                endDate,
                                                                            };

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        >
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            placeholder={`Enter ${childAtt?.label}...`}
                                                                            value={
                                                                              childAtt?.value
                                                                                ? `${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.startDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )} - ${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.endDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )}`
                                                                                : ""
                                                                            }
                                                                            type="text"
                                                                          />
                                                                        </DateRangePicker>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "html" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "image" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  childAtt?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "file" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                `File name: ${childAtt
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                childAtt?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}

                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Col>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "long_text" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id="outlined-basic short summary"
                                                          variant="outlined"
                                                          multiline
                                                          maxRows={2}
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label}...`}
                                                          value={attdata?.value}
                                                          onChange={(event) =>
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            )
                                                          }
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "short_text" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id="outlined-basic short summary"
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label}...`}
                                                          value={attdata?.value}
                                                          onChange={(event) =>
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            )
                                                          }
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "date" ? (
                                                      <>
                                                        <LocalizationProvider
                                                          dateAdapter={
                                                            AdapterDayjs
                                                          }
                                                        >
                                                          <DemoContainer
                                                            components={[
                                                              "DatePicker",
                                                              "DatePicker",
                                                            ]}
                                                            className="p-0 date"
                                                          >
                                                            <DatePicker
                                                              name={
                                                                attdata?.nameValue
                                                              }
                                                              value={dayjs(
                                                                attdata?.value
                                                              )}
                                                              className="w-100 pt-0"
                                                              onChange={(
                                                                newValue
                                                              ) => {
                                                                handleChangeForFormData(
                                                                  attdata?.nameValue,
                                                                  newValue,
                                                                  attdata?.section_id
                                                                );
                                                              }}
                                                              views={[
                                                                "year",
                                                                "month",
                                                                "day",
                                                              ]}
                                                              format="DD/MM/YYYY"
                                                              slotProps={{
                                                                field: {
                                                                  clearable: true,
                                                                },
                                                              }}
                                                            />
                                                          </DemoContainer>
                                                        </LocalizationProvider>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "radio_single_choice" ? (
                                                      <>
                                                        <RadioGroup
                                                          row
                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          className="mt-1"
                                                          value={attdata?.value}
                                                          onChange={(event) => {
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                        >
                                                          {attdata?.options?.map(
                                                            (attribute) => (
                                                              <FormControlLabel
                                                                key={
                                                                  attribute?.id
                                                                }
                                                                value={
                                                                  attribute?.id
                                                                }
                                                                control={
                                                                  <Radio
                                                                    size="small"
                                                                    checked={
                                                                      attdata?.value ==
                                                                      attribute?.id
                                                                    }
                                                                    value={
                                                                      attribute?.id
                                                                    }
                                                                  />
                                                                }
                                                                label={
                                                                  attribute?.name
                                                                }
                                                                className="font-inter"
                                                              />
                                                            )
                                                          )}
                                                        </RadioGroup>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                        {attdata?.childAtts
                                                          ?.length > 0 &&
                                                          attdata?.value == 1 && (
                                                            <>
                                                              {attdata?.childAtts?.map(
                                                                (
                                                                  childAtt,
                                                                  index
                                                                ) => (
                                                                  <Col lg="12 mt-3">
                                                                    {childAtt?.fieldtype !==
                                                                      "checkbox_single_choice" && (
                                                                        <Typography
                                                                          className={`${childAtt?.fieldtype ===
                                                                            "text_editor" ||
                                                                            childAtt?.fieldtype ===
                                                                            "html" ||
                                                                            childAtt?.fieldtype ===
                                                                            "image" ||
                                                                            childAtt?.fieldtype ===
                                                                            "file"
                                                                            ? "mb-2 fs-14"
                                                                            : "fs-14"
                                                                            }`}
                                                                        >
                                                                          {
                                                                            childAtt?.label
                                                                          }{" "}
                                                                          {childAtt?.is_mandatory ==
                                                                            true && (
                                                                              <span className="required">
                                                                                *
                                                                              </span>
                                                                            )}
                                                                        </Typography>
                                                                      )}
                                                                    {childAtt?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ==
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedOption?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              selectedOption?.value;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          multiline
                                                                          maxRows={
                                                                            2
                                                                          }
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                .target
                                                                                .value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enters ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "link" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid URL.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "email" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "number" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="number"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <>
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                childAtt?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                const newValue =
                                                                                  event
                                                                                    .target
                                                                                    .checked;

                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            childAtt?.label
                                                                          }
                                                                          className="font-inter ack-4"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      attdata?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <>
                                                                        {childAtt?.options?.map(
                                                                          (
                                                                            multiData,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attdata?.childAtts
                                                                                        .find(
                                                                                          (
                                                                                            attr
                                                                                          ) =>
                                                                                            attr?.id ==
                                                                                            childAtt?.id &&
                                                                                            attr?.section_id ==
                                                                                            childAtt?.section_id
                                                                                        )
                                                                                        ?.value?.includes(
                                                                                          multiData?.id
                                                                                        ) ||
                                                                                      false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;

                                                                                      let updatedValues;
                                                                                      if (
                                                                                        checked
                                                                                      ) {
                                                                                        // Add the optionId to the array if checked
                                                                                        updatedValues =
                                                                                          [
                                                                                            ...(attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                              []),
                                                                                            optionId,
                                                                                          ];
                                                                                      } else {
                                                                                        // Remove the optionId from the array if unchecked

                                                                                        updatedValues =
                                                                                          (
                                                                                            attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                            []
                                                                                          ).filter(
                                                                                            (
                                                                                              val
                                                                                            ) =>
                                                                                              val !==
                                                                                              optionId
                                                                                          );
                                                                                      }

                                                                                      handleChangeForChildAtts(
                                                                                        childAtt?.nameValue,
                                                                                        updatedValues,
                                                                                        childAtt?.section_id,
                                                                                        childAtt,
                                                                                        attdata
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            );
                                                                          }
                                                                        )}
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <>
                                                                        <DateRangePicker
                                                                          // ref={keyRef}
                                                                          initialSettings={{
                                                                            startDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.startDate,
                                                                            endDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.endDate,
                                                                            autoUpdateInput: false,
                                                                          }}
                                                                          onApply={(
                                                                            _,
                                                                            range
                                                                          ) => {
                                                                            const startDate =
                                                                              range?.startDate;
                                                                            const endDate =
                                                                              range?.endDate;
                                                                            let data =
                                                                            {
                                                                              startDate:
                                                                                startDate,
                                                                              endDate:
                                                                                endDate,
                                                                            };

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        >
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            placeholder={`Enter ${childAtt?.label}...`}
                                                                            value={
                                                                              childAtt?.value
                                                                                ? `${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.startDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )} - ${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.endDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )}`
                                                                                : ""
                                                                            }
                                                                            type="text"
                                                                          />
                                                                        </DateRangePicker>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "html" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "image" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  childAtt?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "file" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                `File name: ${childAtt
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                childAtt?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Col>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "link" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id="outlined-basic short summary"
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label}...`}
                                                          value={attdata?.value}
                                                          onChange={(event) =>
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            )
                                                          }
                                                          type="text"
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true && (
                                                            <>
                                                              {attdata?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${attdata?.label} is required.`}
                                                                  </p>
                                                                )}
                                                              {attdata?.value !==
                                                                "" &&
                                                                !isValidURL(
                                                                  attdata?.value
                                                                ) && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${attdata?.label} must be a valid URL.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "email" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id="outlined-basic short summary"
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label}...`}
                                                          value={attdata?.value}
                                                          onChange={(event) =>
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            )
                                                          }
                                                          type="text"
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true && (
                                                            <>
                                                              {attdata?.value ===
                                                                "" && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${attdata?.label} is required.`}
                                                                  </p>
                                                                )}
                                                              {attdata?.value !==
                                                                "" &&
                                                                !isValidEmail(
                                                                  attdata?.value
                                                                ) && (
                                                                  <p
                                                                    style={{
                                                                      color:
                                                                        "#d32f2f",
                                                                    }}
                                                                    className="error-msg"
                                                                  >
                                                                    {`${attdata?.label} must be a valid email address.`}
                                                                  </p>
                                                                )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "number" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id="outlined-basic short summary"
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label}...`}
                                                          value={attdata?.value}
                                                          onChange={(event) =>
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target
                                                                ?.value,
                                                              attdata?.section_id
                                                            )
                                                          }
                                                          type="number"
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "checkbox_single_choice" ? (
                                                      <>
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              checked={
                                                                attdata?.value ==
                                                                true
                                                              }
                                                              name={
                                                                attdata?.nameValue
                                                              }
                                                              onChange={(
                                                                event
                                                              ) => {
                                                                handleChangeForFormData(
                                                                  attdata?.nameValue,
                                                                  event?.target
                                                                    ?.checked,
                                                                  attdata?.section_id
                                                                );
                                                              }}
                                                              sx={{ marginRight: "0" }}
                                                            />
                                                          }
                                                          label={attdata?.label}
                                                          className="font-inter ack-5"
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true && (
                                                            <span className="required">
                                                              *
                                                            </span>
                                                          )}
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                        {attdata?.childAtts
                                                          ?.length > 0 &&
                                                          attdata?.value ==
                                                          true && (
                                                            <>
                                                              {attdata?.childAtts?.map(
                                                                (
                                                                  childAtt,
                                                                  index
                                                                ) => (
                                                                  <Col lg="12 mt-3">
                                                                    {childAtt?.fieldtype !==
                                                                      "checkbox_single_choice" && (
                                                                        <Typography
                                                                          className={`${childAtt?.fieldtype ===
                                                                            "text_editor" ||
                                                                            childAtt?.fieldtype ===
                                                                            "html" ||
                                                                            childAtt?.fieldtype ===
                                                                            "image" ||
                                                                            childAtt?.fieldtype ===
                                                                            "file"
                                                                            ? "mb-2 fs-14"
                                                                            : "fs-14"
                                                                            }`}
                                                                        >
                                                                          {
                                                                            childAtt?.label
                                                                          }{" "}
                                                                          {childAtt?.is_mandatory ==
                                                                            true && (
                                                                              <span className="required">
                                                                                *
                                                                              </span>
                                                                            )}
                                                                        </Typography>
                                                                      )}
                                                                    {childAtt?.fieldtype ===
                                                                      "dropdodown_single_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ==
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedOption?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              selectedOption?.value;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "long_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          multiline
                                                                          maxRows={
                                                                            2
                                                                          }
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                .target
                                                                                .value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "short_text" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enters ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            // Update the value in the childAtt directly
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DatePicker",
                                                                              "DatePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DatePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={
                                                                                childAtt?.value
                                                                              }
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              views={[
                                                                                "year",
                                                                                "month",
                                                                                "day",
                                                                              ]}
                                                                              format="DD/MM/YYYY"
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "link" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="text"
                                                                        />
                                                                        {childAtt?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {childAtt?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                !isValidURL(
                                                                                  childAtt?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${childAtt?.label} must be a valid URL.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "email" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="email"
                                                                        />
                                                                        {attdata?.is_mandatory ===
                                                                          true && (
                                                                            <>
                                                                              {attdata?.value ===
                                                                                "" && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${attdata?.label} is required.`}
                                                                                  </p>
                                                                                )}
                                                                              {attdata?.value !==
                                                                                "" &&
                                                                                !isValidEmail(
                                                                                  attdata?.value
                                                                                ) && (
                                                                                  <p
                                                                                    style={{
                                                                                      color:
                                                                                        "#d32f2f",
                                                                                    }}
                                                                                    className="error-msg"
                                                                                  >
                                                                                    {`${attdata?.label} must be a valid email address.`}
                                                                                  </p>
                                                                                )}
                                                                            </>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "number" ? (
                                                                      <>
                                                                        <TextField
                                                                          className="input-field mt-2"
                                                                          fullWidth
                                                                          id="outlined-basic short summary"
                                                                          variant="outlined"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Enter ${childAtt?.label}...`}
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            const newValue =
                                                                              event
                                                                                .target
                                                                                .value;

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              newValue,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              newValue;
                                                                          }}
                                                                          type="number"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_single_choice" ? (
                                                                      <>
                                                                        <FormControlLabel
                                                                          control={
                                                                            <Checkbox
                                                                              checked={
                                                                                childAtt?.value ==
                                                                                true
                                                                              }
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              onChange={(
                                                                                event
                                                                              ) => {
                                                                                const newValue =
                                                                                  event
                                                                                    .target
                                                                                    .checked;

                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                            />
                                                                          }
                                                                          label={
                                                                            childAtt?.label
                                                                          }
                                                                          className="font-inter ack-6"
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" && childAtt?.nameValue !== "radio_single_choice_acknowledgement_type" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}

                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "radio_single_choice" && childAtt?.nameValue === "radio_single_choice_acknowledgement_type" ? (
                                                                      <>
                                                                        <RadioGroup
                                                                          row
                                                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          className="mt-1"
                                                                          value={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event
                                                                          ) => {
                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              event
                                                                                ?.target
                                                                                ?.value,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                          }}
                                                                        >
                                                                          {childAtt?.options?.map(
                                                                            (
                                                                              attribute
                                                                            ) => (
                                                                              <FormControlLabel
                                                                                key={
                                                                                  attribute?.id
                                                                                }
                                                                                value={
                                                                                  attribute?.id
                                                                                }
                                                                                control={
                                                                                  <Radio
                                                                                    size="small"
                                                                                    checked={
                                                                                      childAtt?.value ==
                                                                                      attribute?.id
                                                                                    }
                                                                                    value={
                                                                                      attribute?.id
                                                                                    }
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  attribute?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            )
                                                                          )}
                                                                        </RadioGroup>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                        {childAtt?.childAtts?.map((nestedChildAtt1) => {
                                                                          if (childAtt?.value == nestedChildAtt1?.show_option_value) {
                                                                            return (
                                                                              <>
                                                                                {nestedChildAtt1?.fieldtype === "short_text" && nestedChildAtt1?.is_repeatable == false && (
                                                                                  <Col lg="12 mt-2 test-12">
                                                                                    {nestedChildAtt1?.fieldtype !==
                                                                                      "checkbox_single_choice" && (
                                                                                        <Typography
                                                                                          className={`${nestedChildAtt1?.fieldtype ===
                                                                                            "text_editor" ||
                                                                                            nestedChildAtt1?.fieldtype ===
                                                                                            "html" ||
                                                                                            nestedChildAtt1?.fieldtype ===
                                                                                            "image" ||
                                                                                            nestedChildAtt1?.fieldtype ===
                                                                                            "file"
                                                                                            ? "mb-2 fs-14"
                                                                                            : "fs-14"
                                                                                            }`}
                                                                                        >
                                                                                          {
                                                                                            nestedChildAtt1?.label
                                                                                          }{" "}
                                                                                          {nestedChildAtt1?.is_mandatory ==
                                                                                            true && (
                                                                                              <span className="required">
                                                                                                *
                                                                                              </span>
                                                                                            )}
                                                                                        </Typography>
                                                                                      )}

                                                                                    <TextField
                                                                                      className="input-field mt-2"
                                                                                      fullWidth
                                                                                      id="outlined-basic short summary"
                                                                                      variant="outlined"
                                                                                      name={
                                                                                        nestedChildAtt1?.nameValue
                                                                                      }
                                                                                      placeholder={`Enters ${nestedChildAtt1?.label}...`}
                                                                                      value={
                                                                                        nestedChildAtt1?.value
                                                                                      }
                                                                                      onChange={(
                                                                                        event
                                                                                      ) => {
                                                                                        const newValue =
                                                                                          event
                                                                                            .target
                                                                                            .value;

                                                                                        handleNestedChildChange(
                                                                                          nestedChildAtt1?.nameValue,
                                                                                          event.target.value,
                                                                                          nestedChildAtt1?.section_id,
                                                                                          childAtt,
                                                                                          attdata,
                                                                                          nestedChildAtt1?.show_option_value
                                                                                        );
                                                                                        // Update the value in the childAtt directly
                                                                                        nestedChildAtt1.value =
                                                                                          newValue;
                                                                                      }}
                                                                                    />
                                                                                  </Col>
                                                                                )}

                                                                                {nestedChildAtt1?.fieldtype === "short_text" && nestedChildAtt1?.is_repeatable == true && (
                                                                                  <Col lg="12">
                                                                                    {nestedChildAtt1?.fieldtype !==
                                                                                      "checkbox_single_choice" && (
                                                                                        <>
                                                                                          <div className="extra-links-div">
                                                                                            <Typography
                                                                                              className={`${nestedChildAtt1?.fieldtype ===
                                                                                                "text_editor" ||
                                                                                                nestedChildAtt1?.fieldtype ===
                                                                                                "html" ||
                                                                                                nestedChildAtt1?.fieldtype ===
                                                                                                "image" ||
                                                                                                nestedChildAtt1?.fieldtype ===
                                                                                                "file"
                                                                                                ? "mb-2 fs-14"
                                                                                                : "fs-14"
                                                                                                }`}
                                                                                            >
                                                                                              {nestedChildAtt1?.label}{" "}
                                                                                              {nestedChildAtt1?.is_mandatory ==
                                                                                                true && (
                                                                                                  <span className="required">
                                                                                                    *
                                                                                                  </span>
                                                                                                )}
                                                                                            </Typography>
                                                                                            <Button
                                                                                              className="btn primary-btn"
                                                                                              onClick={() =>
                                                                                                handleAddNestedTextField(
                                                                                                  childAtt,
                                                                                                  nestedChildAtt1?.nameValue,
                                                                                                  nestedChildAtt1?.label,
                                                                                                  childAtt?.section_id,
                                                                                                  nestedChildAtt1?.fieldtype,
                                                                                                  nestedChildAtt1?.options,
                                                                                                  nestedChildAtt1?.is_mandatory,
                                                                                                  true, // Set repeatable attribute to true
                                                                                                  nestedChildAtt1?.value, // Pass current value
                                                                                                  attdata,
                                                                                                  nestedChildAtt1?.show_option_value,
                                                                                                  nestedChildAtt1?.max_repeat,
                                                                                                  nestedChildAtt1
                                                                                                )
                                                                                              }
                                                                                            >
                                                                                              <FontAwesomeIcon
                                                                                                icon={faPlus}
                                                                                              />
                                                                                              {`Add`}
                                                                                            </Button>
                                                                                          </div>
                                                                                          <div>
                                                                                            {additionalTextFieldsForNestedChildAtt1?.map((textField,
                                                                                              index) => (
                                                                                              <div key={index}>
                                                                                                {textField?.nameValue == nestedChildAtt1?.nameValue && (
                                                                                                  <>
                                                                                                    <div className={`${nestedChildAtt1?.fieldtype === "link_object" ? 'add-title-div-2' : 'add-title-div'}`}>
                                                                                                      {textField?.fieldtype === "short_text" ? (
                                                                                                        <>
                                                                                                          <div className="w-100">
                                                                                                            <TextField
                                                                                                              className="input-field mt-2"
                                                                                                              fullWidth
                                                                                                              id="outlined-basic short summary"
                                                                                                              variant="outlined"
                                                                                                              name={textField?.nameValue}
                                                                                                              placeholder={`Enter ${textField?.label}...`}
                                                                                                              value={textField?.value}
                                                                                                              onChange={(
                                                                                                                event
                                                                                                              ) => handleChangeForNestedChildAdditionalTextField(
                                                                                                                textField?.id,
                                                                                                                event
                                                                                                                  ?.target
                                                                                                                  ?.value,
                                                                                                                textField?.nameValue,
                                                                                                                textField?.section_id,
                                                                                                                textField?.fieldtype,
                                                                                                                "",
                                                                                                                childAtt,
                                                                                                                attdata,
                                                                                                                textField?.show_option_value,
                                                                                                                textField
                                                                                                              )}
                                                                                                            />
                                                                                                            {textField?.is_mandatory ==
                                                                                                              true &&
                                                                                                              showBelowError ==
                                                                                                              true &&
                                                                                                              textField?.value ===
                                                                                                              "" && (
                                                                                                                <p
                                                                                                                  style={{
                                                                                                                    color: "#d32f2f",
                                                                                                                  }}
                                                                                                                  className="error-msg"
                                                                                                                >
                                                                                                                  {`${textField?.label} ${index +
                                                                                                                    1} is required.`}
                                                                                                                </p>
                                                                                                              )}
                                                                                                          </div>
                                                                                                          <div className={`${nestedChildAtt1?.fieldtype === "link_object" ? "add-title-div-btn-2" : "add-title-div-btn"}`}>
                                                                                                            <Button
                                                                                                              className="btn btn-danger"
                                                                                                              onClick={() => removeNestedExtraFields(
                                                                                                                textField.id,
                                                                                                                textField.nameValue,
                                                                                                                textField.section_id,
                                                                                                                childAtt,
                                                                                                                textField?.show_option_value,
                                                                                                                attdata
                                                                                                              )}
                                                                                                            >
                                                                                                              <FontAwesomeIcon
                                                                                                                icon={faTimes} />
                                                                                                            </Button>
                                                                                                          </div>

                                                                                                        </>
                                                                                                      ) : ""}
                                                                                                    </div>

                                                                                                  </>
                                                                                                )}
                                                                                              </div>
                                                                                            ))}
                                                                                          </div>
                                                                                        </>
                                                                                      )}
                                                                                  </Col>
                                                                                )}

                                                                                {/* Add more conditions here for different fieldtypes */}
                                                                              </>
                                                                            );
                                                                          }
                                                                          return null;
                                                                        })}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "checkbox_multiple_choice" ? (
                                                                      <>
                                                                        {childAtt?.options?.map(
                                                                          (
                                                                            multiData,
                                                                            index
                                                                          ) => {
                                                                            return (
                                                                              <FormControlLabel
                                                                                control={
                                                                                  <Checkbox
                                                                                    checked={
                                                                                      attdata?.childAtts
                                                                                        .find(
                                                                                          (
                                                                                            attr
                                                                                          ) =>
                                                                                            attr?.id ==
                                                                                            childAtt?.id &&
                                                                                            attr?.section_id ==
                                                                                            childAtt?.section_id
                                                                                        )
                                                                                        ?.value?.includes(
                                                                                          multiData?.id
                                                                                        ) ||
                                                                                      false
                                                                                    }
                                                                                    name={
                                                                                      multiData?.id
                                                                                    }
                                                                                    onChange={(
                                                                                      event
                                                                                    ) => {
                                                                                      const checked =
                                                                                        event
                                                                                          ?.target
                                                                                          ?.checked;
                                                                                      const optionId =
                                                                                        multiData?.id;

                                                                                      let updatedValues;
                                                                                      if (
                                                                                        checked
                                                                                      ) {
                                                                                        // Add the optionId to the array if checked
                                                                                        updatedValues =
                                                                                          [
                                                                                            ...(attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                              []),
                                                                                            optionId,
                                                                                          ];
                                                                                      } else {
                                                                                        // Remove the optionId from the array if unchecked

                                                                                        updatedValues =
                                                                                          (
                                                                                            attdata?.childAtts.find(
                                                                                              (
                                                                                                attr
                                                                                              ) =>
                                                                                                attr?.id ==
                                                                                                childAtt?.id &&
                                                                                                attr?.section_id ===
                                                                                                childAtt?.section_id
                                                                                            )
                                                                                              ?.value ||
                                                                                            []
                                                                                          ).filter(
                                                                                            (
                                                                                              val
                                                                                            ) =>
                                                                                              val !==
                                                                                              optionId
                                                                                          );
                                                                                      }

                                                                                      handleChangeForChildAtts(
                                                                                        childAtt?.nameValue,
                                                                                        updatedValues,
                                                                                        childAtt?.section_id,
                                                                                        childAtt,
                                                                                        attdata
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                }
                                                                                label={
                                                                                  multiData?.name
                                                                                }
                                                                                className="font-inter"
                                                                              />
                                                                            );
                                                                          }
                                                                        )}
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "dropdodown_multiple_choice" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options?.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.value}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt
                                                                            ?.value
                                                                            ?.length <
                                                                          1 && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required at least one selection.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "TimePicker",
                                                                              "TimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <TimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value,
                                                                                "HH:mm A"
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_time" ? (
                                                                      <>
                                                                        <LocalizationProvider
                                                                          dateAdapter={
                                                                            AdapterDayjs
                                                                          }
                                                                        >
                                                                          <DemoContainer
                                                                            components={[
                                                                              "DateTimePicker",
                                                                            ]}
                                                                            className="p-0 date"
                                                                          >
                                                                            <DateTimePicker
                                                                              name={
                                                                                childAtt?.nameValue
                                                                              }
                                                                              value={dayjs(
                                                                                childAtt?.value
                                                                              )}
                                                                              className="w-100 pt-0"
                                                                              onChange={(
                                                                                newValue
                                                                              ) => {
                                                                                handleChangeForChildAtts(
                                                                                  childAtt?.nameValue,
                                                                                  newValue,
                                                                                  childAtt?.section_id,
                                                                                  childAtt,
                                                                                  attdata
                                                                                );
                                                                                childAtt.value =
                                                                                  newValue;
                                                                              }}
                                                                              slotProps={{
                                                                                field:
                                                                                {
                                                                                  clearable: true,
                                                                                },
                                                                              }}
                                                                            />
                                                                          </DemoContainer>
                                                                        </LocalizationProvider>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "date_range" ? (
                                                                      <>
                                                                        <DateRangePicker
                                                                          // ref={keyRef}
                                                                          initialSettings={{
                                                                            startDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.startDate,
                                                                            endDate:
                                                                              childAtt
                                                                                ?.value
                                                                                ?.endDate,
                                                                            autoUpdateInput: false,
                                                                          }}
                                                                          onApply={(
                                                                            _,
                                                                            range
                                                                          ) => {
                                                                            const startDate =
                                                                              range?.startDate;
                                                                            const endDate =
                                                                              range?.endDate;
                                                                            let data =
                                                                            {
                                                                              startDate:
                                                                                startDate,
                                                                              endDate:
                                                                                endDate,
                                                                            };

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        >
                                                                          <TextField
                                                                            className="input-field mt-2"
                                                                            fullWidth
                                                                            id="outlined-basic short summary"
                                                                            variant="outlined"
                                                                            placeholder={`Enter ${childAtt?.label}...`}
                                                                            value={
                                                                              childAtt?.value
                                                                                ? `${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.startDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )} - ${moment(
                                                                                  childAtt
                                                                                    ?.value
                                                                                    ?.endDate
                                                                                ).format(
                                                                                  "DD/MM/YYYY"
                                                                                )}`
                                                                                : ""
                                                                            }
                                                                            type="text"
                                                                          />
                                                                        </DateRangePicker>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "text_editor" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditor
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "html" ? (
                                                                      <>
                                                                        <CKEditor
                                                                          editor={
                                                                            Editor
                                                                          }
                                                                          className="mt-2"
                                                                          config={
                                                                            configForContentEditorForHTML
                                                                          }
                                                                          data={
                                                                            childAtt?.value
                                                                          }
                                                                          onChange={(
                                                                            event,
                                                                            editor
                                                                          ) => {
                                                                            const data =
                                                                              editor?.getData();

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              data,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              data;
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "image" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={4}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper">
                                                                              <Image
                                                                                src={
                                                                                  childAtt?.value
                                                                                }
                                                                              />
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "image",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change Image"
                                                                              : "Upload Image"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "file" ? (
                                                                      <>
                                                                        <Stack
                                                                          gap={3}
                                                                        >
                                                                          {childAtt?.value && (
                                                                            <div className="upload-img-wrapper mt-2">
                                                                              {childAtt?.value !==
                                                                                "" &&
                                                                                `File name: ${childAtt
                                                                                  ?.value
                                                                                  ?.name ||
                                                                                childAtt?.value
                                                                                }`}
                                                                            </div>
                                                                          )}

                                                                          <Button
                                                                            className="btn ternary-btn w-100 mt-3"
                                                                            onClick={() =>
                                                                              handleClickForAtt(
                                                                                "file",
                                                                                attdata
                                                                              )
                                                                            }
                                                                          >
                                                                            <FontAwesomeIcon
                                                                              icon={
                                                                                faUpload
                                                                              }
                                                                            />
                                                                            {attdata?.value
                                                                              ? "Change File"
                                                                              : "Upload File"}
                                                                          </Button>
                                                                        </Stack>
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "users_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.filter(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              childAtt?.value?.includes(
                                                                                option?.value
                                                                              )
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : childAtt?.fieldtype ===
                                                                      "group_list" ? (
                                                                      <>
                                                                        <Select
                                                                          closeMenuOnSelect={
                                                                            false
                                                                          }
                                                                          options={
                                                                            childAtt?.options
                                                                          }
                                                                          menuPlacement="auto"
                                                                          name={
                                                                            childAtt?.nameValue
                                                                          }
                                                                          placeholder={`Select ${childAtt?.label}...`}
                                                                          className="muilt-select-field mt-2"
                                                                          classNamePrefix="select"
                                                                          isMulti
                                                                          value={childAtt?.options.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option?.value ===
                                                                              childAtt?.value
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) => {
                                                                            const selectedValues =
                                                                              selectedOption.map(
                                                                                (
                                                                                  option
                                                                                ) =>
                                                                                  option?.value
                                                                              );

                                                                            handleChangeForChildAtts(
                                                                              childAtt?.nameValue,
                                                                              selectedValues,
                                                                              childAtt?.section_id,
                                                                              childAtt,
                                                                              attdata
                                                                            );
                                                                            childAtt.value =
                                                                              selectedValues;
                                                                          }}
                                                                          styles={{
                                                                            option:
                                                                              (
                                                                                provided,
                                                                                state
                                                                              ) => {
                                                                                return {
                                                                                  ...provided,
                                                                                  backgroundColor:
                                                                                    state.isSelected
                                                                                      ? "#660c605c"
                                                                                      : provided.backgroundColor,
                                                                                  color:
                                                                                    state.isSelected
                                                                                      ? "var(--dark-color)"
                                                                                      : provided.color,
                                                                                };
                                                                              },
                                                                          }}
                                                                        />
                                                                        {childAtt?.is_mandatory ==
                                                                          true &&
                                                                          showBelowError ==
                                                                          true &&
                                                                          childAtt?.value ===
                                                                          "" && (
                                                                            <p
                                                                              style={{
                                                                                color:
                                                                                  "#d32f2f",
                                                                              }}
                                                                              className="error-msg"
                                                                            >
                                                                              {`${childAtt?.label} is required.`}
                                                                            </p>
                                                                          )}
                                                                      </>
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Col>
                                                                )
                                                              )}
                                                            </>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "checkbox_multiple_choice" ? (
                                                      <>
                                                        {attdata?.options?.map(
                                                          (multiData, index) => (
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  checked={
                                                                    attributesData
                                                                      .find(
                                                                        (attr) =>
                                                                          attr?.id ==
                                                                          attdata?.id &&
                                                                          attr?.section_id ==
                                                                          attdata?.section_id
                                                                      )
                                                                      ?.value?.includes(
                                                                        multiData?.id
                                                                      ) || false
                                                                  }
                                                                  name={
                                                                    multiData?.id
                                                                  }
                                                                  onChange={(
                                                                    event
                                                                  ) => {
                                                                    const checked =
                                                                      event
                                                                        ?.target
                                                                        ?.checked;
                                                                    const optionId =
                                                                      multiData?.id;

                                                                    let updatedValues;
                                                                    if (checked) {
                                                                      // Add the optionId to the array if checked
                                                                      updatedValues =
                                                                        [
                                                                          ...(attributesData.find(
                                                                            (
                                                                              attr
                                                                            ) =>
                                                                              attr?.id ==
                                                                              attdata?.id &&
                                                                              attr?.section_id ===
                                                                              attdata?.section_id
                                                                          )
                                                                            ?.value ||
                                                                            []),
                                                                          optionId,
                                                                        ];
                                                                    } else {
                                                                      // Remove the optionId from the array if unchecked
                                                                      updatedValues =
                                                                        (
                                                                          attributesData.find(
                                                                            (
                                                                              attr
                                                                            ) =>
                                                                              attr?.id ==
                                                                              attdata?.id &&
                                                                              attr?.section_id ===
                                                                              attdata?.section_id
                                                                          )
                                                                            ?.value ||
                                                                          []
                                                                        ).filter(
                                                                          (val) =>
                                                                            val !==
                                                                            optionId
                                                                        );
                                                                    }

                                                                    handleChangeForFormData(
                                                                      attdata?.nameValue,
                                                                      updatedValues,
                                                                      attdata?.section_id
                                                                    );
                                                                  }}
                                                                />
                                                              }
                                                              label={
                                                                multiData?.name
                                                              }
                                                              className="font-inter"
                                                            />
                                                          )
                                                        )}
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value?.length <
                                                          1 && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required at least one selection.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "dropdodown_multiple_choice" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2"
                                                          classNamePrefix="select"
                                                          isMulti
                                                          value={attdata?.options?.filter(
                                                            (option) =>
                                                              attdata?.value?.includes(
                                                                option?.value
                                                              )
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            const selectedValues =
                                                              selectedOption.map(
                                                                (option) =>
                                                                  option?.value
                                                              );
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedValues,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value?.length <
                                                          1 && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required at least one selection.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "tags" ? (
                                                      <>
                                                        <CreatableSelect
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          isMulti
                                                          options={
                                                            attdata?.options
                                                          }
                                                          className="muilt-select-field  mt-2 boder-radius-8"
                                                          menuPlacement="auto"
                                                          placeholder={`Select or enter ${attdata?.label}...`}
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          value={attdata?.options?.filter(
                                                            (option) =>
                                                              attdata?.value?.includes(
                                                                option?.value
                                                              )
                                                          )}
                                                          onChange={(
                                                            selectedOptions
                                                          ) => {
                                                            const selectedValues =
                                                              selectedOptions.map(
                                                                (option) => option
                                                              );
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedValues,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value?.length <
                                                          1 && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required at least one selection.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "time" &&
                                                      attdata?.value !== null ? (
                                                      <>
                                                        <LocalizationProvider
                                                          dateAdapter={
                                                            AdapterDayjs
                                                          }
                                                        >
                                                          <DemoContainer
                                                            components={[
                                                              "TimePicker",
                                                            ]}
                                                            className="p-0 date"
                                                          >
                                                            <TimePicker
                                                              name={
                                                                attdata?.nameValue
                                                              }
                                                              value={dayjs(
                                                                attdata?.value,
                                                                "HH:mm A"
                                                              )}
                                                              className="w-100 pt-0"
                                                              onChange={(
                                                                newValue
                                                              ) => {
                                                                handleChangeForFormData(
                                                                  attdata?.nameValue,
                                                                  newValue,
                                                                  attdata?.section_id
                                                                );
                                                              }}
                                                              slotProps={{
                                                                field: {
                                                                  clearable: true,
                                                                },
                                                              }}
                                                            />
                                                          </DemoContainer>
                                                        </LocalizationProvider>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "date_time" ? (
                                                      <>
                                                        <LocalizationProvider
                                                          dateAdapter={
                                                            AdapterDayjs
                                                          }
                                                        >
                                                          <DemoContainer
                                                            components={[
                                                              "DateTimePicker",
                                                            ]}
                                                            className="p-0 date"
                                                          >
                                                            <DateTimePicker
                                                              name={
                                                                attdata?.nameValue
                                                              }
                                                              value={dayjs(
                                                                attdata?.value
                                                              )}
                                                              className="w-100 pt-0"
                                                              onChange={(
                                                                newValue
                                                              ) => {
                                                                handleChangeForFormData(
                                                                  attdata?.nameValue,
                                                                  newValue,
                                                                  attdata?.section_id
                                                                );
                                                              }}
                                                              slotProps={{
                                                                field: {
                                                                  clearable: true,
                                                                },
                                                              }}
                                                            />
                                                          </DemoContainer>
                                                        </LocalizationProvider>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "date_range" ? (
                                                      <>
                                                        <DateRangePicker
                                                          initialSettings={{
                                                            startDate:
                                                              attdata?.value
                                                                ?.startDate,
                                                            endDate:
                                                              attdata?.value
                                                                ?.endDate,
                                                            autoUpdateInput: false,
                                                          }}
                                                          onApply={(_, range) => {
                                                            const startDate =
                                                              range?.startDate;
                                                            const endDate =
                                                              range?.endDate;
                                                            let data = {
                                                              startDate:
                                                                startDate,
                                                              endDate: endDate,
                                                            };
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              data,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                        >
                                                          <TextField
                                                            className="input-field mt-2"
                                                            fullWidth
                                                            id="outlined-basic short summary"
                                                            variant="outlined"
                                                            placeholder={`Enter ${attdata?.label}...`}
                                                            value={
                                                              attdata?.value
                                                                ? `${moment(
                                                                  attdata?.value
                                                                    ?.startDate
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )} - ${moment(
                                                                  attdata?.value
                                                                    ?.endDate
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )}`
                                                                : ""
                                                            }
                                                            type="text"
                                                          />
                                                        </DateRangePicker>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "text_editor" ? (
                                                      <>
                                                        <CKEditor
                                                          editor={Editor}
                                                          className="mt-2"
                                                          config={
                                                            configForContentEditor
                                                          }
                                                          data={attdata?.value}
                                                          onChange={(
                                                            event,
                                                            editor
                                                          ) => {
                                                            const data =
                                                              editor?.getData();
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              data,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "html" ? (
                                                      <>
                                                        <CKEditor
                                                          editor={Editor}
                                                          className="mt-2"
                                                          config={
                                                            configForContentEditorForHTML
                                                          }
                                                          data={attdata?.value}
                                                          onChange={(
                                                            event,
                                                            editor
                                                          ) => {
                                                            const data =
                                                              editor?.getData();
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              data,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "image" ? (
                                                      <>
                                                        <Stack gap={4}>
                                                          {attdata?.value && (
                                                            <div className="upload-img-wrapper">
                                                              <Image
                                                                src={
                                                                  attdata?.url
                                                                }
                                                              />
                                                            </div>
                                                          )}

                                                          <Button
                                                            className="btn ternary-btn w-100"
                                                            onClick={() => {
                                                              setOpenMediaModelFor("attribute")
                                                              handleOpenUploadMediaForAtt("image", attdata)
                                                            }
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faUpload}
                                                            />
                                                            {attdata?.value
                                                              ? "Change Image"
                                                              : "Upload Image"}
                                                          </Button>
                                                        </Stack>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "file" ? (
                                                      <>
                                                        <Stack gap={3}>
                                                          {attdata?.value && (
                                                            <div className="upload-img-wrapper mt-2">
                                                              {attdata?.value !==
                                                                "" &&
                                                                `File name: ${attdata?.value
                                                                  ?.name ||
                                                                attdata?.name
                                                                }`}
                                                            </div>
                                                          )}

                                                          <Button
                                                            className="btn ternary-btn w-100 mt-3"
                                                            onClick={() => {
                                                              setOpenMediaModelFor("attribute")
                                                              handleOpenUploadMediaForAtt("doc", attdata)
                                                            }
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faUpload}
                                                            />
                                                            {attdata?.value
                                                              ? "Change File"
                                                              : "Upload File"}
                                                          </Button>
                                                        </Stack>
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "users_list" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2"
                                                          classNamePrefix="select"
                                                          isMulti

                                                          value={attdata?.options.filter(
                                                            (
                                                              option
                                                            ) =>
                                                              attdata?.value?.includes(
                                                                option?.value
                                                              )
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            const selectedValues =
                                                              selectedOption.map(
                                                                (option) =>
                                                                  option?.value
                                                              );
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedValues,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required at least one selection.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "group_list" ? (
                                                      <>
                                                        <Select
                                                          closeMenuOnSelect={
                                                            false
                                                          }
                                                          options={
                                                            attdata?.options
                                                          }
                                                          menuPlacement="auto"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Select ${attdata?.label}...`}
                                                          className="muilt-select-field mt-2"
                                                          classNamePrefix="select"
                                                          isMulti
                                                          value={attdata?.options.find(
                                                            (option) =>
                                                              option?.value ===
                                                              attdata?.value
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) => {
                                                            const selectedValues =
                                                              selectedOption.map(
                                                                (option) =>
                                                                  option?.value
                                                              );
                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              selectedValues,
                                                              attdata?.section_id
                                                            );
                                                          }}
                                                          styles={{
                                                            option: (
                                                              provided,
                                                              state
                                                            ) => {
                                                              return {
                                                                ...provided,
                                                                backgroundColor:
                                                                  state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color:
                                                                  state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                              };
                                                            },
                                                          }}
                                                        />
                                                        {attdata?.is_mandatory ==
                                                          true &&
                                                          showBelowError ==
                                                          true &&
                                                          attdata?.value ===
                                                          "" && (
                                                            <p
                                                              style={{
                                                                color: "#d32f2f",
                                                              }}
                                                              className="error-msg"
                                                            >
                                                              {`${attdata?.label} is required at least one selection.`}
                                                            </p>
                                                          )}
                                                      </>
                                                    ) : attdata?.fieldtype ===
                                                      "link_object" ? (
                                                      <>
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id={`outlined-basic-title-${index}`}
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label} Title...`}
                                                          value={attdata?.value?.title || ""}
                                                          onChange={(event) =>

                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target?.value,
                                                              attdata?.section_id,
                                                              false,
                                                              "title", // Specify this is the title subfield
                                                              false
                                                            )
                                                          }
                                                        />

                                                        {attdata?.is_mandatory && showBelowError && !attdata?.value?.title && (
                                                          <p style={{ color: "#d32f2f" }} className="error-msg">
                                                            {`${attdata?.label} Title is required.`}
                                                          </p>
                                                        )}
                                                        <TextField
                                                          className="input-field mt-2"
                                                          fullWidth
                                                          id={`outlined-basic-url-${index}`}
                                                          variant="outlined"
                                                          name={
                                                            attdata?.nameValue
                                                          }
                                                          placeholder={`Enter ${attdata?.label} URL...`}
                                                          value={attdata?.value?.url || ""}
                                                          onChange={(event) =>

                                                            handleChangeForFormData(
                                                              attdata?.nameValue,
                                                              event?.target?.value,
                                                              attdata?.section_id,
                                                              false,
                                                              "url", // Specify this is the url subfield
                                                              false
                                                            )
                                                          }
                                                          type="text"
                                                        />
                                                        {attdata?.value?.url && !isValidURL(attdata?.value?.url) && (
                                                          <p style={{ color: "#d32f2f" }} className="error-msg">
                                                            {`${attdata?.label} URL must be a valid URL.`}
                                                          </p>
                                                        )}
                                                      </>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Col>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}

                                        {value == 0 && (
                                          <>
                                            {getAllCategories?.length > 0 && (
                                              <>
                                                {getAllCategories?.map((category) => (
                                                  <>
                                                    <Col lg="12" className="">
                                                      <Typography className="fw-400 fs-14 dark-text ">
                                                        {category?.label}
                                                      </Typography><Select
                                                        closeMenuOnSelect={false}
                                                        options={category?.options}
                                                        menuPlacement="top"
                                                        name="groupManagers"
                                                        value={selectedCategories[category.slug] || []}
                                                        placeholder={`Select Terms For ${category?.label}...`}
                                                        menuPosition="fixed"
                                                        isMulti
                                                        onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}

                                                        className={`muilt-select-field mt-2 `}
                                                        classNamePrefix="select"
                                                        styles={{
                                                          option: (provided, state) => {
                                                            return {
                                                              ...provided,
                                                              backgroundColor: state.isSelected
                                                                ? "#660c605c"
                                                                : provided.backgroundColor,
                                                              color: state.isSelected
                                                                ? "var(--dark-color)"
                                                                : provided.color,
                                                            };
                                                          },
                                                        }} />
                                                    </Col>
                                                  </>
                                                ))}
                                              </>
                                            )}

                                          </>
                                        )}
                                      </>
                                    </Row>
                                  </CustomTabPanel>
                                ))}
                              </Box>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Access and Permissions
                            </Accordion.Header>
                            <Accordion.Body className="content-card-body">
                              <Row>
                                <Col lg="12" className="">
                                  <Typography className="fw-400 fs-14 dark-text">
                                    Select Users
                                  </Typography>
                                  <Select
                                    closeMenuOnSelect={false}
                                    options={getAllGroupsAndUsers}
                                    menuPlacement="bottom"
                                    name="users"
                                    value={permissions}
                                    placeholder="Select users..."
                                    isMulti
                                    menuPosition="fixed"
                                    onChange={(selectedOption) => {
                                      const users = selectedOption.map(
                                        (option) => option
                                      );
                                      setPermissions(users);
                                    }}
                                    className={`muilt-select-field mt-2 `}
                                    classNamePrefix="select"
                                    styles={{
                                      option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#660c605c"
                                          : provided.backgroundColor,
                                        color: state.isSelected
                                          ? "var(--dark-color)"
                                          : provided.color,
                                      }),
                                      multiValueLabel: (provided, state) => {
                                        return {
                                          ...provided,
                                          color:
                                            state.data?.type === "group"
                                              ? "rgb(0, 135, 90)"
                                              : "rgb(0, 82, 204)",
                                        };
                                      },
                                    }}
                                  />
                                </Col>
                                <Col lg="12" className="repeatable-col">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isAdvancePermission === true}
                                        name="allowEdit"
                                        onChange={() =>
                                          setIsAdvancePermission(
                                            !isAdvancePermission
                                          )
                                        }
                                      />
                                    }
                                    label="Show Advanced Permissions"
                                    className="font-inter fs-14"
                                  />
                                </Col>
                                <Col lg="12" className="repeatable-col">
                                  <TableContainer className="z-0">
                                    <Table
                                      sx={{ minWidth: 750 }}
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={
                                          isAdvancePermission == false
                                            ? columns_atttributes_headers
                                            : columns_advace_atttributes_headers
                                        }
                                      />
                                      <TableBody>
                                        {permissions?.length > 0 && (
                                          <>
                                            {permissions?.map(
                                              (permission, index) => (
                                                <>
                                                  <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={permission.value}
                                                    className="cursor-pointer tbl-row-attribute-row"
                                                  >
                                                    <TableCell
                                                      align="left"
                                                      className={` tbl-border-left tbl-cell-attribute-2`}
                                                      style={{
                                                        width: "100%",
                                                        top: 0,
                                                        left: 0,
                                                      }}
                                                    >
                                                      <Typography className="fs-14">
                                                        {permission?.label}
                                                      </Typography>
                                                    </TableCell>

                                                    <TableCell
                                                      align="right"
                                                      className={`tbl-cell-attribute-2`}
                                                      style={{
                                                        width: "100%",
                                                        top: 0,
                                                        left: 0,
                                                      }}
                                                    >
                                                      <AntSwitch
                                                        name="view"
                                                        checked={
                                                          permission.view
                                                        }
                                                        onChange={() =>
                                                          handleToggle(
                                                            permission.value,
                                                            "view"
                                                          )
                                                        }
                                                        inputProps={{
                                                          "aria-label":
                                                            "ant design",
                                                        }}
                                                      />
                                                    </TableCell>
                                                    <TableCell
                                                      align="right"
                                                      className={`tbl-cell-attribute-2`}
                                                      style={{
                                                        width: "100%",
                                                        top: 0,
                                                        left: 0,
                                                      }}
                                                    >
                                                      <AntSwitch
                                                        name="deny"
                                                        checked={
                                                          permission.deny
                                                        }
                                                        onChange={() =>
                                                          handleToggle(
                                                            permission.value,
                                                            "deny"
                                                          )
                                                        }
                                                        inputProps={{
                                                          "aria-label":
                                                            "ant design",
                                                        }}
                                                      />
                                                    </TableCell>

                                                    {isAdvancePermission && (
                                                      <>
                                                        <TableCell
                                                          align="right"
                                                          className={`tbl-cell-attribute-2`}
                                                          style={{
                                                            width: "100%",
                                                            top: 0,
                                                            left: 0,
                                                          }}
                                                        >
                                                          <AntSwitch
                                                            name="edit"
                                                            checked={
                                                              permission.edit
                                                            }
                                                            onChange={() =>
                                                              handleToggle(
                                                                permission.value,
                                                                "edit"
                                                              )
                                                            }
                                                            inputProps={{
                                                              "aria-label":
                                                                "ant design",
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell
                                                          align="right"
                                                          className={`tbl-cell-attribute-2`}
                                                          style={{
                                                            width: "100%",
                                                            top: 0,
                                                            left: 0,
                                                          }}
                                                        >
                                                          <AntSwitch
                                                            name="delete"
                                                            checked={
                                                              permission.delete
                                                            }
                                                            onChange={() =>
                                                              handleToggle(
                                                                permission.value,
                                                                "delete"
                                                              )
                                                            }
                                                            inputProps={{
                                                              "aria-label":
                                                                "ant design",
                                                            }}
                                                          />
                                                        </TableCell>
                                                        <TableCell
                                                          align="right"
                                                          className={`tbl-border-right tbl-cell-attribute-2`}
                                                          style={{
                                                            width: "100%",
                                                            top: 0,
                                                            left: 0,
                                                          }}
                                                        >
                                                          <AntSwitch
                                                            name="redacted"
                                                            checked={
                                                              permission.redacted
                                                            }
                                                            onChange={() =>
                                                              handleToggle(
                                                                permission.value,
                                                                "redacted"
                                                              )
                                                            }
                                                            inputProps={{
                                                              "aria-label":
                                                                "ant design",
                                                            }}
                                                          />
                                                        </TableCell>
                                                      </>
                                                    )}
                                                  </TableRow>
                                                  <TableRow className="extra-row"></TableRow>
                                                </>
                                              )
                                            )}
                                          </>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Stack>
                    </Col>
                    <Col lg="3" md="5">
                      <Stack
                        className="gap-30 change-direction"
                        direction="column"
                        justifyContent="space-between"
                        gap={2}
                        flexWrap="wrap"
                      >
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Publication Options
                          </Card.Header>
                          <Card.Body className="p-15">
                            <Stack direction="row" gap={1} flexWrap="wrap">
                              {getNewsData?.published_date === null && (
                                <Button
                                  className="btn btn-secondary w-100 text-white"
                                  type="submit"
                                  onClick={() => {
                                    setPublicationOption("draft");
                                    setShowError(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faClipboard} />
                                  Save As Draft
                                </Button>
                              )}

                              <Button
                                className="btn primary-btn w-100"
                                type="submit"
                                onClick={() => {
                                  setShowError(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="d-md-block d-sm-none d-none"
                                />
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="d-md-none d-sm-block"
                                />
                                Publish Page
                              </Button>
                              <div className="w-100">
                                <div className="mt-2 enable-div d-flex flex-wrap justify-content-between">
                                  <Box className="d-flex gap-3 flex-wrap justify-content-center">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      {`Enable ${selectedTypeName}`}
                                    </Typography>
                                    <BootstrapTooltip
                                      title={`You can enable/disable Post.`}
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-haspopup="true"
                                      />

                                    </BootstrapTooltip>
                                  </Box>
                                  <AntSwitch
                                    name="typePoststatus"
                                    checked={values?.typePoststatus}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "typePoststatus",
                                        event.target.checked
                                      );
                                    }}
                                    inputProps={{
                                      "aria-label": "ant design",
                                    }}
                                  />
                                </div>
                              </div>
                            </Stack>
                          </Card.Body>
                        </Card>
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Post Image {typeSlug?.toLowerCase() !== 'documents' && (<span className="required">*</span>)}
                          </Card.Header>
                          <Card.Body className="p-15">
                            <ImageUpload
                              value={values?.image}
                              handleClick={() => {
                                setOpenMediaModelFor("normal")
                                handleOpenUploadMedia("image")
                              }
                              }
                              setFieldValue={setFieldValue}
                            />
                            {errors?.image && touched.image && (
                              <p
                                style={{ color: "#d32f2f" }}
                                className="error-msg"
                              >
                                {errors?.image}
                              </p>
                            )}
                          </Card.Body>
                        </Card>
                        {values?.postType !== 1 && (
                          <Card className="bg-white card-wrapper">
                            <Card.Header className="bg-white fw-700 primary-text">
                              Post File {typeSlug?.toLowerCase() === 'documents' && (<span className="required">*</span>)}
                            </Card.Header>
                            <Card.Body className="p-15">
                              <FileUpload
                                value={values?.file}
                                handleClick={() => {
                                  setOpenMediaModelFor("normal")
                                  handleOpenUploadMedia("doc")
                                }}
                                handleFileChange={handleFileChange}
                                setFieldValue={setFieldValue}
                                values={values}
                                touched={touched}
                                errors={errors}
                              />
                              {errors?.file && touched.file && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.file}
                                </p>
                              )}
                            </Card.Body>
                          </Card>
                        )}
                      </Stack>
                    </Col>
                  </Row>
                  <FileUploadModal
                    open={openFileUploadModel}
                    setOpen={setOpenFileUploadModel}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    title={contentForPost}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPost === "image" ? imageFileTypes : fileTypes
                    }
                    handleFileChange={handleFileChange}
                    file={
                      contentForPost === "image"
                        ? selectedImageFile
                        : selectedFile
                    }
                    setFileValue={() => handleImageFileValue(setFieldValue)}
                    isImage={contentForPost === "image" ? true : false}
                    isImageEditable={isEdit}
                  />
                  <FileUploadModalAttribue
                    open={openFileUploadModelForAtt}
                    setOpen={setOpenFileUploadModelForAtt}
                    handleOpen={handleOpenForAtt}
                    handleClose={handleCloseForAtt}
                    title={contentForPostForAtt}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPostForAtt === "image"
                        ? imageFileTypes
                        : fileTypes
                    }
                    handleFileChange={handleFileChangeForAtt}
                    file={
                      contentForPostForAtt === "image"
                        ? selectedImageFileForAtt
                        : selectedFileForAtt
                    }
                    setFileValue={handleImageFileValueForAtt}
                    isImage={contentForPostForAtt === "image" ? true : false}
                    isImageEditable={isEdit}
                  />

                  <UploadMediaModel
                    openMediaUpload={openMediaUpload}
                    handleCloseForUploadMedia={handleCloseForUploadMedia}
                    selectedMediaTypeForImage={selectedMediaTypeForImage}
                    selectedMediaTypeForDoc={selectedMediaTypeForDoc}
                    setSelectedMediaTypeForImage={setSelectedMediaTypeForImage}
                    setSelectedMediaTypeForDoc={setSelectedMediaTypeForDoc}
                    setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImage}
                    setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDoc}
                    systemMediaFolderList={systemMediaFolderList}
                    selectedMediaFolderIDForImage={selectedMediaFolderIDForImage}
                    selectedMediaFolderIDFordoc={selectedMediaFolderIDFordoc}
                    myMediaFolderList={myMediaFolderList}
                    downloadFileForImage={downloadFileForImage}
                    downloadFileFordoc={downloadFileFordoc}
                    selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImage}
                    setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImage}
                    selectedMediaFileForImage={selectedMediaFileForImage}
                    setSelectedMediaFileForImage={setSelectedMediaFileForImage}
                    selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDoc}
                    setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDoc}
                    selectedMediaFileForDoc={selectedMediaFileForDoc}
                    setSelectedMediaFileForDoc={setSelectedMediaFileForDoc}
                    setFieldValue={setFieldValue}
                    mediaList={mediaList}
                    total_mediaFiles={total_mediaFiles}
                    pageForMedia={pageForMedia}
                    setPageForMedia={setPageForMedia}
                    handleChangePageForMedia={handleChangePageForMedia}
                    rowsPerPageForMedia={rowsPerPageForMedia}
                    handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMedia}
                    media_Pagination_options={media_Pagination_options}
                    mediaDataFor={mediaDataFor}
                    handleCloseMediaModelAddMedia={handleCloseMediaModelAddMedia}
                    setSelectedFileMedia={setSelectedFileMedia}
                    selectedFileMedia={selectedFileMedia}
                    isEditMedia={isEditMedia}
                    setIsEditMedia={setIsEditMedia}
                    setSelectedMedia={setSelectedMedia}
                    selectedMedia={selectedMedia}
                    deleteMedia={deleteMedia}
                    setOpenRenameModel={setOpenRenameModel}
                    setIsRenameMedia={setIsRenameMedia}
                  />

                  <UploadMediaModelAtt
                    openMediaUpload={openMediaUploadForAtt}
                    handleCloseForUploadMedia={handleCloseForUploadMediaForAtt}
                    selectedMediaTypeForImage={selectedMediaTypeForImageAtt}
                    selectedMediaTypeForDoc={selectedMediaTypeForDocAtt}
                    setSelectedMediaTypeForImage={setSelectedMediaTypeForImageAtt}
                    setSelectedMediaTypeForDoc={setSelectedMediaTypeForDocAtt}
                    setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImageAtt}
                    setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDocAtt}
                    systemMediaFolderList={systemMediaFolderListForAtt}
                    selectedMediaFolderIDForImage={selectedMediaFolderIDForImageAtt}
                    selectedMediaFolderIDFordoc={selectedMediaFolderIDFordocAtt}
                    myMediaFolderList={myMediaFolderListForAtt}
                    downloadFileForImage={downloadFileForImageAtt}
                    downloadFileFordoc={downloadFileFordocAtt}
                    selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImageAtt}
                    setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImageAtt}
                    selectedMediaFileForImage={selectedMediaFileForImageAtt}
                    setSelectedMediaFileForImage={setSelectedMediaFileForImageAtt}
                    selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDocAtt}
                    setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDocAtt}
                    selectedMediaFileForDoc={selectedMediaFileForDocAtt}
                    setSelectedMediaFileForDoc={setSelectedMediaFileForDocAtt}
                    mediaList={mediaListAtt}
                    total_mediaFiles={total_mediaFilesAtt}
                    pageForMedia={pageForMediaAtt}
                    setPageForMedia={setPageForMediaAtt}
                    handleChangePageForMedia={handleChangePageForMediaForAtt}
                    rowsPerPageForMedia={rowsPerPageForMediaAtt}
                    handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMediaAtt}
                    media_Pagination_options={media_Pagination_options}
                    mediaDataFor={mediaDataForAtt}
                    handleImageFileValueCustAttForAtt={handleImageFileValueCustAttForAtt}
                    handleCloseMediaModelAddMedia={handleCloseMediaModelAddMediaAtt}
                    setSelectedFileMedia={setSelectedFileMediaAtt}
                    selectedFileMedia={selectedFileMediaAtt}
                    isEditMedia={isEditMediaAtt}
                    setIsEditMedia={setIsEditMediaAtt}
                    setSelectedMedia={setSelectedMediaAtt}
                    selectedMedia={selectedMediaAtt}
                    deleteMedia={deleteMediaAtt}
                    setOpenRenameModel={setOpenRenameModelAtt}
                    setIsRenameMedia={setIsRenameMediaAtt}
                  />

                  <AddMedia
                    openFileUploadModel={openMediaModelFor === "normal" ? openFileUploadModelMedia : openFileUploadModelMediaAtt}
                    isEdit={openMediaModelFor === "normal" ? isEditMedia : isEditMediaAtt}
                    handleCloseForMediaModel={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                    getRootProps={getRootProps}
                    selectedFile={openMediaModelFor === "normal" ? selectedFileMedia : selectedFileMediaAtt}
                    getInputProps={getInputProps}
                    renderPreview={openMediaModelFor === "normal" ? renderPreview : renderPreviewAtt}
                    valueForMediaTab={valueForMediaTab}
                    handleChangeForMedia={handleChangeForMedia}
                    mediaTitle={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                    setMediaTitle={openMediaModelFor === "normal" ? setMediaTitle : setMediaTitleAtt}
                    mediaAltText={openMediaModelFor === "normal" ? mediaAltText : mediaAltTextAtt}
                    setMediaAltText={openMediaModelFor === "normal" ? setMediaAltText : setMediaAltTextAtt}
                    mediaDescription={openMediaModelFor === "normal" ? mediaDescription : mediaDescriptionAtt}
                    setMediaDescription={openMediaModelFor === "normal" ? setMediaDescription : setMediaDescriptionAtt}
                    mediaDirectUrl={openMediaModelFor === "normal" ? mediaDirectUrl : mediaDirectUrlAtt}
                    setMediaDirectUrl={openMediaModelFor === "normal" ? setMediaDirectUrl : setMediaDirectUrlAtt}
                    loggedUser={loggedUser}
                    isSystemTypeMedia={openMediaModelFor === "normal" ? isSystemTypeMedia : isSystemTypeMediaAtt}
                    isParentFolderSystemType={openMediaModelFor === "normal" ? isParentFolderSystemType : isParentFolderSystemTypeAtt}
                    permissionsForMedia={permissionsForMedia}
                    setPermissionsForMedia={setPermissionsForMedia}
                    isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                    setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
                    columns_atttributes_headers={columns_atttributes_headers}
                    columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                    responseForMedia={openMediaModelFor === "normal" ? responseForMedia : responseForMediaAtt}
                    storeMedia={openMediaModelFor === "normal" ? storeMedia : storeMediaAtt}
                    showSystemMediaCheckbox={false}
                  />

                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openMediaModelFor === "normal" ? openRnameMediaModel : openRnameMediaModelAtt}
                    maxWidth="sm"
                    fullWidth={true}
                    className="media-dialog-2"
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Rename Media
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="media-preview">
                      <Box className=" main-app-icon-div-4 d-flex flex-wrap">
                        <Box className="media-info-div-2">
                          <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <Typography className="fs-14">Title</Typography>
                            <TextField
                              className="input-field mt-2"
                              fullWidth
                              id="outlined-basic short summary"
                              variant="outlined"
                              placeholder={`write media title`}
                              value={openMediaModelFor === "normal" ? mediaTitle : mediaTitleAtt}
                              onChange={(event) => {
                                if (openMediaModelFor === "normal") {
                                  setMediaTitle(event?.target?.value)
                                } else {
                                  setMediaTitleAtt(event?.target?.value)
                                }
                              }}
                            />

                          </Paper>
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={openMediaModelFor === "normal" ? handleCloseForMediaModel : handleCloseForMediaModelAtt}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={openMediaModelFor === "normal" ? renameMedia : renameMediaAtt}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default UserCreateContent;
