import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  Card,
  Stack,
  Image,
} from "react-bootstrap";
import {
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faArrowRight,
  faFolder,
  faCertificate,
  faPlus,
  faTimes,
  faClipboard,
  faUpload,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import { Await, json, useNavigate } from "react-router-dom";

import Header from "../../components/layout/header";
import CustomEditor from "../../components/common/ckEditor";
import { Form, Formik, ErrorMessage, FieldArray, Field } from "formik";
import Select from "react-select";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import { Toast } from "../../utils/toast";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ImageUpload from "../../components/common/imageUpload";
import CreatableSelect from "react-select/creatable";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postTypeOptions } from "../../helpers/constants/addNewsConstants/postContentConstants";
import { targetTypes } from "../../helpers/constants/addNewsConstants/associatedAttributesConstants";
import {
  DayWeekMonthYearOptions,
  DismissModelBehaviourOption,
  ModelBehaviourOptions,
  OneDayWeekMonthYearOptions,
  configForContentEditor,
  configForContentEditorForHTML,
  yesNoOptions,
} from "../../helpers/constants/common.js";
import {
  AcknowledgementTypeOptions,
  ExpiryActions,
  NotifyUsersOptions,
  ReminderNotificationActions,
  ShowTopOfFeedOptionActions,
} from "../../helpers/constants/addNewsConstants/moreAttributesConstants";
import FileUploadModal from "../../components/common/fileUploadModal.js";
import FileUpload from "../../components/common/fileUpload.js";
import { addNewNewsvalidationSchema } from "../../helpers/validations/addNewNews/index.js";
import { WarningAlert } from "../../components/common/alert/warningAlert.js";
import { SuccessAlert } from "../../components/common/alert/successAlert.js";
import { useParams } from "react-router-dom";
import DrawerMenu from "../../components/layout/drawer.js";
import { Toaster } from "react-hot-toast";
import { parseInt } from "lodash";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  errorTab: PropTypes.number.isRequired,
};

const DummyPostType = () => {
  let { id, type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersExceptAll, setAllUsersExceptAll] = useState([]);
  const [allUsersEdit, setAllUsersEdit] = useState([]);
  const [allUsersWithYou, setAllUsersWithYou] = useState([]);
  const [value, setValue] = useState(0);
  const [openFileUploadModel, setOpenFileUploadModel] = useState(false);
  const [openFileUploadModelForAtt, setOpenFileUploadModelForAtt] =
    useState(false);

  const [contentForPost, setContentForPost] = useState("");
  const [contentForPostForAtt, setContentForPostForAtt] = useState("");

  const [publicationOption, setPublicationOption] = useState("");
  const [getAllNewsData, setGetAllNewsData] = useState([]);
  const [errorTab, setErrorTab] = useState(-1);
  const [isErrorOnClassificationTab, SetIsErrorOnClassificationTab] =
    useState(false);
  const [isErrorOnIRTab, setIsErrorOnIRTab] = useState(false);
  const [isErrorOnEngTab, SetIsErrorOnEngTab] = useState(false);
  const [showError, setShowError] = useState(false);
  const [newsId, setNewsID] = useState(id ? parseInt(id) : "");
  const [isEdit, setIsEdit] = useState(newsId ? true : false);
  const [getNewsData, setGetNewsData] = useState();
  const [getAllTypes, setGetAllTypes] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState([]);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({});
  const [getAllAttributeSections, setGetAllAttributeSections] = useState([]);
  // const [attributesData, setAttributes] = useState();

  const [attData, setAttData] = useState();
  const [additionalTextFields, setAdditionalTextFields] = useState([]);

  const selectedTypeID = localStorage.getItem("type-selected-id");
  const selectedTypeName = localStorage.getItem("type-selected-name");

  const handleOpen = () => setOpenFileUploadModel(true);
  const handleOpenForAtt = () => setOpenFileUploadModelForAtt(true);

  const handleClose = () => setOpenFileUploadModel(false);
  const handleCloseForAtt = () => setOpenFileUploadModelForAtt(false);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const imageFileTypes = ["JPG", "PNG", "GIF"];

  const fileTypes = ["docx", "pdf"];

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    activeType,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  let newWarningMessages = [];

  const initialValues = {
    title: newsId ? getNewsData?.title : "",
    content: newsId ? getNewsData?.content : "",
    image: newsId ? getNewsData?.image : "",
    typePoststatus: newsId ? getNewsData?.typePoststatus : true,
    file: "",
    fileDescription: "",
    postType: getAllTypes?.find((option) => option?.value == selectedTypeID)
      ?.value,
    classification_shortSummary: newsId
      ? getNewsData?.classification_shortSummary
      : "",
    classification_folder: newsId ? getNewsData?.classification_folder : "news",
    classification_tags: newsId ? getNewsData?.classification_tags : [],
    IRarchiveDate: newsId ? getNewsData?.IRarchiveDate : "",
    IRarchiveActivity: newsId ? getNewsData?.IRarchiveActivity : "year",
    IRViewUsers: newsId
      ? getNewsData?.IRViewUsers
      : allUsers
        ?.filter((option) => option?.value === "all_users")
        .map((option) => option.value),
    IRNotViewUsers: newsId ? getNewsData?.IRNotViewUsers : [],
    IRNotObjectUsers: newsId ? getNewsData?.IRNotObjectUsers : [],
    IREditUsers: newsId
      ? getNewsData?.IREditUsers
      : allUsersEdit
        ?.filter((option) => option?.value == loggedUser?.id)
        .map((option) => option.value),
    IRRelatedItems: newsId ? getNewsData?.IRRelatedItems : [],
    IRReviewUsers: newsId ? getNewsData?.IRReviewUsers : "none",
    IRReviewByUsers: newsId ? getNewsData?.IRReviewByUsers : [],
    IRNewsLinkTitle: newsId ? getNewsData?.IRNewsLinkTitle : "",
    IRNewsLinkURL: newsId ? getNewsData?.IRNewsLinkURL : "",
    IRlinkFields: newsId ? getNewsData?.IRlinkFields : [],
    EngEnableComments: newsId ? getNewsData?.EngEnableComments : "yes",
    EngEnable_comment_moderation: newsId
      ? getNewsData?.EngEnable_comment_moderation
      : "no",
    EngEnableLikes: newsId ? getNewsData?.EngEnableLikes : "yes",
    EngEnableRating: newsId ? getNewsData?.EngEnableRating : "no",
    EngNotifyUsers: newsId ? getNewsData?.EngNotifyUsers : "no",
    EngAcknowledgementRequired: newsId
      ? getNewsData?.EngAcknowledgementRequired
      : "no",
    EngUsersForAcknowledge: newsId ? getNewsData?.EngUsersForAcknowledge : [],
    EngDueBy: newsId ? getNewsData?.EngDueBy : "",
    EngDueByExpiryAction: newsId ? getNewsData?.EngDueByExpiryAction : "",
    EngReminderNotification: newsId ? getNewsData?.EngReminderNotification : "",
    EngAcknowledgementType: newsId ? getNewsData?.EngAcknowledgementType : "",
    EngAcknowledgementQuestions:
      newsId && getNewsData?.EngAcknowledgementQuestions?.length > 0
        ? getNewsData?.EngAcknowledgementQuestions
        : [{ title: "" }],
    EngAcknowledgementText: newsId ? getNewsData?.EngAcknowledgementText : "",
    EngShowTopOfFeed: newsId ? getNewsData?.EngShowTopOfFeed : "none",
    EngHighlightAsModal: newsId ? getNewsData?.EngHighlightAsModal : "no",
    EngModalBehaviour: newsId ? getNewsData?.EngModalBehaviour : "",
    EngDismissModel: newsId ? getNewsData?.EngDismissModel : "",
    // publishOption: "",
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const handleFileChange = (file, setFieldValue) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (contentForPost === "image") {
          setIsEdit(false);
          setSelectedImageFile(file);
        } else if (contentForPost === "file") {
          setSelectedFile(file);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageFileValue = (setFieldValue) => {
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File ||
      selectedFile instanceof Blob ||
      selectedFile instanceof File
    ) {
      if (contentForPost === "image") {
        setFieldValue("image", URL.createObjectURL(selectedImageFile));

        setOpenFileUploadModel(false);
      } else {
        setFieldValue("file", selectedFile);

        setOpenFileUploadModel(false);
      }
    } else {
      console.error("Selected file is not a valid Blob or File object");
    }
  };

  const handleClick = (content, attdatas) => {
    setContentForPost(content);
    setAttData(attdatas);
    setOpenFileUploadModel(true);
  };

  const onCloseForAlerts = () => {
    setWarningMessage([]);
    setSuccessMessage("");
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("content", values?.content);
    if (
      selectedImageFile instanceof Blob ||
      selectedImageFile instanceof File
    ) {
      formData.append("image", selectedImageFile);
    }
    if (newsId) {
      formData.append("news_id", newsId);
    }
    formData.append("postType", values?.postType);
    formData.append(
      "classification_shortSummary",
      values?.classification_shortSummary
    );
    formData.append("classification_folder", values?.classification_folder);
    if (values?.classification_tags) {
      // Extract the value property from each item in classification_tags
      const tags = values.classification_tags.map((tag) => tag?.value);
      const finalTags = tags.join(",");
      // Append the tags to the FormData object
      formData.append("classification_tags", finalTags);
    }
    if (values?.IRarchiveDate) {
      const formattedDate = dayjs(values?.IRarchiveDate).format("YYYY-MM-DD");
      formData.append("IRarchiveDate", formattedDate);
    } else {
      formData.append("IRarchiveDate", "");
    }

    formData.append("IRarchiveActivity", values?.IRarchiveActivity);
    if (values?.IRViewUsers) {
      const viewUsers = values.IRViewUsers?.map((user) => user);
      const usersString = viewUsers.join(",");

      formData.append("IRViewUsers", usersString);
    }
    if (values?.IRNotViewUsers) {
      const viewUsers = values.IRNotViewUsers?.map((user) => user);
      const usersString = viewUsers.join(",");

      formData.append("IRNotViewUsers", usersString);
    }
    if (values?.IRNotObjectUsers) {
      const viewUsers = values.IRNotObjectUsers?.map((user) => user);
      const usersString = viewUsers.join(",");

      formData.append("IRNotObjectUsers", usersString);
    }
    if (values?.IREditUsers) {
      const viewUsers = values.IREditUsers?.map((user) => user);
      const usersString = viewUsers.join(",");

      formData.append("IREditUsers", usersString);
    }
    if (values?.IRRelatedItems?.length > 0) {
      const viewUsers = values.IRRelatedItems?.map((user) => user);

      const usersString = viewUsers.join(",");

      formData.append("IRRelatedItems", usersString);
    }

    formData.append("IRReviewUsers", values?.IRReviewUsers);
    if (values?.IRReviewByUsers?.length > 0) {
      const viewUsers = values.IRReviewByUsers?.map((user) => user);

      const usersString = viewUsers.join(",");

      formData.append("IRReviewByUsers", usersString);
    }
    formData.append("IRNewsLinkTitle", values?.IRNewsLinkTitle);
    formData.append("IRNewsLinkURL", values?.IRNewsLinkURL);
    if (values?.IRlinkFields?.length > 0) {
      const viewUsers = JSON.stringify(values.IRlinkFields);
      formData.append("IRlinkFields", viewUsers);
    }
    formData.append("EngEnableComments", values?.EngEnableComments);
    formData.append(
      "EngEnable_comment_moderation",
      values?.EngEnable_comment_moderation
    );
    formData.append("EngEnableLikes", values?.EngEnableLikes);
    formData.append("EngEnableRating", values?.EngEnableRating);
    formData.append("EngNotifyUsers", values?.EngNotifyUsers);
    formData.append(
      "EngAcknowledgementRequired",
      values?.EngAcknowledgementRequired
    );
    if (
      values?.EngUsersForAcknowledge &&
      values?.EngAcknowledgementRequired === "yes"
    ) {
      const viewUsers = values.EngUsersForAcknowledge?.map((user) => user);
      // Join the array of user values into a comma-separated string
      const usersString = viewUsers.join(",");
      // Append the comma-separated string to formData
      formData.append("EngUsersForAcknowledge", usersString);
      const formattedDateDueBy = dayjs(values?.EngDueBy).format("YYYY-MM-DD");
      formData.append("EngDueBy", formattedDateDueBy);
      formData.append("EngDueByExpiryAction", values?.EngDueByExpiryAction);
      formData.append(
        "EngReminderNotification",
        values?.EngReminderNotification
      );
      formData.append("EngAcknowledgementType", values?.EngAcknowledgementType);
      if (
        values?.EngAcknowledgementQuestions &&
        values?.EngAcknowledgementType === "questions"
      ) {
        const viewUsers = JSON.stringify(values.EngAcknowledgementQuestions);
        formData.append("EngAcknowledgementQuestions", viewUsers);
      }
      if (
        values?.EngAcknowledgementText &&
        values?.EngAcknowledgementType === "checkbox"
      ) {
        formData.append(
          "EngAcknowledgementText",
          values?.EngAcknowledgementText
        );
      }
    }
    formData.append("EngShowTopOfFeed", values?.EngShowTopOfFeed);
    formData.append("EngHighlightAsModal", values?.EngHighlightAsModal);
    if (values?.EngHighlightAsModal === "yes") {
      formData.append("EngModalBehaviour", values?.EngModalBehaviour);
      formData.append("EngDismissModel", values?.EngDismissModel);
    }

    formData.append(
      "publishOption",
      publicationOption === "draft" ? "saveAsDraft" : "saveAsPublish"
    );
    if (newsId) {
      try {
        setLoading(true);
        const response = await api.post("/type/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response?.success == true) {
          setLoading(false);
          setPublicationOption("");
          setIsErrorOnIRTab(false);
          SetIsErrorOnEngTab(false);
          SetIsErrorOnClassificationTab(false);
          setShowError(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          setGetNewsData();
          setSuccessMessage(response?.message);
          localStorage.setItem("last-redirect-page", `/type/${type}`);
          navigate(`/type/${type}`);
          setPublicationOption("");
        } else {
          setLoading(false);
          setPublicationOption("");
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);
        setPublicationOption("");
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.message);
        Toast.error(err?.response?.data?.message);
        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
      }
    } else {
      try {
        setLoading(true);
        const response = await api.post("/type/store", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response?.success == true) {
          setLoading(false);
          setPublicationOption("");
          setIsErrorOnIRTab(false);
          SetIsErrorOnEngTab(false);
          SetIsErrorOnClassificationTab(false);
          setShowError(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          setSuccessMessage(response?.message);
          localStorage.setItem("last-redirect-page", `/type/${type}`);
          navigate(`/type/${type}`);
          setPublicationOption("");
        } else {
          setLoading(false);
          setPublicationOption("");
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);
        setPublicationOption("");

        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
      }
    }
  };

  const fetchAllTypes = async (type) => {
    try {
      setLoading(true);
      const response = await api.get(`admin/type/master/list/${type}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      let types = response?.data?.map((type) => ({
        value: type?.id,
        label: type?.name,
      }));
      setGetAllTypes(types);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get("/users-list", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let users = response?.response?.map((user) => ({
          value: user?.id,
          label: user?.name,
        }));
        let usersExceptAll = users;
        setAllUsersExceptAll(usersExceptAll);
        // Add "All Users" option
        let allusers = [
          {
            value: "all_users",
            label: "All Users",
          },
          ...users,
        ];

        setAllUsers(allusers);
        const editUsers = [
          {
            value: loggedUser?.id,
            label: `${loggedUser?.name} (you)`,
          },
          ...users,
        ];
        setAllUsersEdit(editUsers);
        const allUserWithYou = [
          {
            value: "all_users",
            label: "All Users",
          },
          {
            value: loggedUser?.id,
            label: `${loggedUser?.name} (you)`,
          },
          ...users,
        ];
        setAllUsersWithYou(allUserWithYou);
        setLoading(false);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
    }
  };

  const getAllNews = async () => {
    try {
      setLoading(true);
      const response = await api.get("/type/list/news", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.status === 200) {
        let users = response?.data?.map((user) => ({
          value: user?.id,
          label: user?.title,
        }));

        setGetAllNewsData(users);
        setLoading(false);
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 403) {
        setLoading(false);
      }
    }
  };

  const fetchAttributeSection = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/attribute-section`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllAttributeSections(response?.response);
      setLoading(false);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute ${isErrorOnClassificationTab == true && `error-on-class`
          }`
          : `non-selected-tab-attribute ${isErrorOnClassificationTab == true && `error-on-class`
          }`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute ${isErrorOnIRTab == true && `error-on-ir`}`
          : `non-selected-tab-attribute ${isErrorOnIRTab == true && `error-on-ir`
          }`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute ${isErrorOnEngTab == true && `error-on-eng`
          }`
          : `non-selected-tab-attribute ${isErrorOnEngTab == true && `error-on-eng`
          }`,
    };
  }

  const fetchNewsDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`type/details/edit/${parseInt(id)}}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        let acknowledgementQuestionsArray = [];

        if (response?.response?.EngAcknowledgementQuestions) {
          try {
            const jsonString = response.response.EngAcknowledgementQuestions;
            acknowledgementQuestionsArray = JSON.parse(jsonString);
          } catch (error) {
            console.error("Error parsing EngAcknowledgementQuestions:", error);
          }
        }
        const news = {
          title: response?.response?.title,
          content: response?.response?.content,
          postType: response?.response?.postType,
          classification_folder: response?.response?.classification_folder,
          classification_tags: response?.response?.classification_tags?.map(
            (tags) => ({
              value: tags?.value,
              label: tags?.label,
            })
          ),
          classification_shortSummary:
            response?.response?.classification_shortSummary,
          IRarchiveDate:
            response?.response?.IRarchiveDate != null
              ? dayjs(response?.response?.IRarchiveDate)
              : "",
          IRarchiveActivity: response?.response?.IRarchiveActivity,
          IRViewUsers: response?.response?.IRViewUsers,
          IRNotViewUsers:
            response?.response?.IRNotViewUsers?.length > 0
              ? response?.response?.IRNotViewUsers
              : [],
          IRNotObjectUsers:
            response?.response?.IRNotObjectUsers?.length > 0
              ? response?.response?.IRNotObjectUsers
              : [],
          IREditUsers:
            response?.response?.IREditUsers?.length > 0
              ? response?.response?.IREditUsers
              : [],
          IRRelatedItems:
            response?.response?.IRRelatedItems?.length > 0
              ? response?.response?.IRRelatedItems
              : [],
          IRReviewUsers: response?.response?.IRReviewUsers,
          IRReviewByUsers:
            response?.response?.IRReviewByUsers?.length > 0
              ? response?.response?.IRReviewByUsers
              : [],
          IRNewsLinkTitle:
            response?.response?.IRNewsLinkTitle != undefined
              ? response?.response?.IRNewsLinkTitle
              : "",
          IRNewsLinkURL:
            response?.response?.IRNewsLinkURL != undefined
              ? response?.response?.IRNewsLinkURL
              : "",
          IRlinkFields:
            response?.response?.IRlinkFields?.length > 0
              ? response?.response?.IRlinkFields
              : [],
          EngEnableComments: response?.response?.EngEnableComments,
          EngEnable_comment_moderation:
            response?.response?.EngEnable_comment_moderation,
          EngEnableLikes: response?.response?.EngEnableLikes,
          EngEnableRating: response?.response?.EngEnableRating,
          EngNotifyUsers: response?.response?.EngNotifyUsers,
          EngAcknowledgementRequired:
            response?.response?.EngAcknowledgementRequired,
          EngUsersForAcknowledge:
            response?.response?.EngUsersForAcknowledge?.length > 0
              ? response?.response?.EngUsersForAcknowledge
              : [],
          EngDueBy:
            response?.response?.EngAcknowledgementRequired === "yes" &&
              response?.response?.EngDueBy != null
              ? dayjs(response?.response?.EngDueBy)
              : "",
          EngDueByExpiryAction:
            response?.response?.EngAcknowledgementRequired === "yes"
              ? response?.response?.EngDueByExpiryAction
              : "",
          EngReminderNotification:
            response?.response?.EngAcknowledgementRequired === "yes"
              ? response?.response?.EngReminderNotification
              : "",
          EngAcknowledgementType:
            response?.response?.EngAcknowledgementRequired === "yes" &&
              response?.response?.EngAcknowledgementType !== "none"
              ? response?.response?.EngAcknowledgementType
              : "",

          EngAcknowledgementQuestions:
            response?.response?.EngAcknowledgementRequired === "yes" &&
              response?.response?.EngAcknowledgementType === "questions" &&
              response?.response?.EngAcknowledgementType != null
              ? acknowledgementQuestionsArray?.map((question) => ({
                title: question?.title,
              }))
              : [],

          EngAcknowledgementText:
            response?.response?.EngAcknowledgementRequired === "yes" &&
              response?.response?.EngAcknowledgementType === "checkbox"
              ? response?.response?.EngAcknowledgementText
              : "",
          EngShowTopOfFeed: response?.response?.EngShowTopOfFeed,
          EngHighlightAsModal: response?.response?.EngHighlightAsModal,
          EngModalBehaviour:
            response?.response?.EngHighlightAsModal === "yes"
              ? response?.response?.EngModalBehaviour
              : "",
          EngDismissModel:
            response?.response?.EngHighlightAsModal === "yes"
              ? response?.response?.EngDismissModel
              : "",
          image: response?.response?.image,
          published_date: response?.response?.published_date,
        };
        setGetNewsData(news);
        setSelectedImageFile(news?.image);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/type/${type}`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/type/${type}`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/type/${type}`);

        navigate("/");
      }
    }
  };

  useEffect(() => {
    setWarningMessage(newWarningMessages);
  }, [newWarningMessages]);

  useEffect(() => {
    const apiCall = async () => {
      if (parseInt(id)) {
        await fetchNewsDetails();
        await fetchAllTypes("enable");
        await getAllUsers();
        await getAllNews();
        await fetchAttributeSection();
      } else {
        await fetchAllTypes("enable");
        await getAllUsers();
        await getAllNews();
        await fetchAttributeSection();
      }
    };
    apiCall();
  }, [parseInt(id)]);

  return (
    <>
      {/* START :: MAIN CONTENT WRAPPER */}
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage && (
            <>
              {warningMessage?.map((message, index) => (
                <>
                  <WarningAlert
                    message={message}
                  // onClose={() => handleCloseWarningMessage(index)}
                  />
                </>
              ))}
            </>
          )}
          {/* START :: PAGE TITLE WRAPPER */}
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewNewsvalidationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
            }) => {
              let classificationErrors = {};
              let irErrors = {};
              let engErrors = {};
              let messages = [];

              if (Object.keys(errors).length > 0) {
                for (let key in errors) {
                  if (key.startsWith("classification")) {
                    classificationErrors[key] = errors[key];
                  } else if (key.startsWith("IR")) {
                    irErrors[key] = errors[key];
                  } else if (key.startsWith("Eng")) {
                    engErrors[key] = errors[key];
                  }
                }
              }

              const errorTypes = [
                {
                  errors: classificationErrors,
                  errorMessage:
                    "Please enter required field for Classification attributes",
                  setErrorFlag: SetIsErrorOnClassificationTab,
                },
                {
                  errors: irErrors,
                  errorMessage:
                    "Please enter required field for Integrity & Relevance attributes",
                  setErrorFlag: setIsErrorOnIRTab,
                },
                {
                  errors: engErrors,
                  errorMessage:
                    "Please enter required field for Engagement attributes",
                  setErrorFlag: SetIsErrorOnEngTab,
                },
              ];

              errorTypes.forEach(({ errors, errorMessage, setErrorFlag }) => {
                if (Object.keys(errors).length > 0) {
                  if (showError == true) {
                    messages.push(errorMessage);
                    setErrorFlag(true);
                  }
                } else {
                  setErrorFlag(false);
                }
              });

              newWarningMessages = messages;
              return (
                <Form>
                  <Row>
                    <Col lg="12">
                      <h1 className="page-title">
                        {newsId ? "Edit News" : "Add New"}
                      </h1>
                    </Col>

                    <Col lg="9" md="7">
                      <Stack direction="vertical" className="gap-30">
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Page Content
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text">
                                  Page Type<span className="required">*</span>
                                </Typography>
                                <Select
                                  closeMenuOnSelect={true}
                                  options={getAllTypes}
                                  menuPlacement="auto"
                                  name="postType"
                                  value={getAllTypes.find(
                                    (option) => option.value === values.postType
                                  )}
                                  placeholder="Select page type..."
                                  onChange={(selectedOption) => {
                                    setFieldValue(
                                      "postType",
                                      selectedOption ? selectedOption.value : ""
                                    );
                                  }}
                                  className="muilt-select-field mt-2"
                                  classNamePrefix="select"
                                  isDisabled
                                  styles={{
                                    option: (provided, state) => {
                                      return {
                                        ...provided,
                                        backgroundColor: state.isSelected
                                          ? "#660c605c"
                                          : provided.backgroundColor,
                                        color: state.isSelected
                                          ? "var(--dark-color)"
                                          : provided.color,
                                      };
                                    },
                                  }}
                                />
                                {errors?.postType && touched.postType && (
                                  <p
                                    style={{ color: "#d32f2f" }}
                                    className="error-msg"
                                  >
                                    {errors?.postType}
                                  </p>
                                )}
                              </Col>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text">
                                  Page Title<span className="required">*</span>
                                </Typography>
                                <TextField
                                  className="input-field mt-2"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="title"
                                  placeholder="Enter title here..."
                                  value={values?.title}
                                  onChange={(event) => {
                                    setFieldValue("title", event.target.value);
                                  }}
                                  helperText={touched.title && errors.title}
                                  error={touched.title && Boolean(errors.title)}
                                />
                              </Col>
                              <Col lg="12">
                                <Typography className="fw-400 dark-text mb-2">
                                  Content<span className="required">*</span>
                                </Typography>

                                <CustomEditor
                                  setFieldValue={setFieldValue}
                                  value={values?.content}
                                  editorConfiguration={configForContentEditor}
                                  setFieldValueFor="content"
                                />
                                {errors?.content && touched.content && (
                                  <p
                                    style={{ color: "#d32f2f" }}
                                    className="error-msg"
                                  >
                                    {errors?.content}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>

                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Attributes
                          </Card.Header>
                          <Card.Body className="attribute-card-body">
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                className="box-attribute"
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  aria-label="basic tabs example"
                                  // variant={isTablet && "scrollable"}
                                  variant="scrollable"
                                  className={
                                    isErrorOnClassificationTab === true ||
                                      isErrorOnEngTab === true ||
                                      isErrorOnIRTab === true
                                      ? "tabs"
                                      : ""
                                  }
                                  TabIndicatorProps={{
                                    style: {
                                      backgroundColor:
                                        (isErrorOnClassificationTab === true &&
                                          value == 0) ||
                                          (isErrorOnIRTab === true &&
                                            value == 1) ||
                                          (isErrorOnEngTab === true && value == 2)
                                          ? "red"
                                          : "#1976d2",
                                    },
                                  }}
                                >
                                  <Tab
                                    icon={<FontAwesomeIcon icon={faFolder} />}
                                    label="Classification"
                                    iconPosition="start"
                                    className={
                                      isErrorOnClassificationTab
                                        ? "error-tab"
                                        : "test"
                                    }
                                    {...a11yProps1(0)}
                                  />

                                  <Tab
                                    label="Integrity & Relevence"
                                    icon={
                                      <FontAwesomeIcon icon={faCertificate} />
                                    }
                                    iconPosition="start"
                                    {...a11yProps2(1)}
                                  />
                                  <Tab
                                    label="Engagement"
                                    {...a11yProps3(2)}
                                    icon={<FontAwesomeIcon icon={faCheck} />}
                                    iconPosition="start"
                                  />
                                </Tabs>
                              </Box>

                              <CustomTabPanel
                                value={value}
                                index={0}
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Folder<span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={postTypeOptions}
                                      menuPlacement="auto"
                                      name="classification_folder"
                                      value={postTypeOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.classification_folder
                                      )}
                                      placeholder="Select folder..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "classification_folder",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      isDisabled={
                                        newsId &&
                                        getNewsData?.published_date !== null
                                      }
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.classification_folder &&
                                      touched.classification_folder && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.classification_folder}
                                        </p>
                                      )}
                                  </Col>

                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Tags<span className="required">*</span>
                                    </Typography>
                                    <CreatableSelect
                                      closeMenuOnSelect={false}
                                      isMulti
                                      className="muilt-select-field  mt-2 boder-radius-8"
                                      menuPlacement="auto"
                                      placeholder="Select or enter tags..."
                                      name="classification_tags"
                                      value={values?.classification_tags}
                                      onChange={(selectedOptions) => {
                                        const selectedValues =
                                          selectedOptions.map(
                                            (option) => option
                                          );

                                        setFieldValue(
                                          "classification_tags",
                                          selectedValues
                                        );
                                      }}
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.classification_tags &&
                                      touched.classification_tags && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.classification_tags}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Short Summary
                                      <span className="required">*</span>
                                    </Typography>

                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic short summary"
                                      variant="outlined"
                                      multiline
                                      maxRows={2}
                                      name="classification_shortSummary"
                                      placeholder="Enter post short summary here..."
                                      value={
                                        values?.classification_shortSummary
                                      }
                                      onChange={(event) => {
                                        setFieldValue(
                                          "classification_shortSummary",
                                          event.target.value
                                        );
                                      }}
                                      helperText={
                                        touched.classification_shortSummary &&
                                        errors.classification_shortSummary
                                      }
                                      error={
                                        touched.classification_shortSummary &&
                                        Boolean(
                                          errors.classification_shortSummary
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              </CustomTabPanel>
                              <CustomTabPanel
                                value={value}
                                index={1}
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Archive Date
                                    </Typography>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DemoContainer
                                        components={[
                                          "DatePicker",
                                          "DatePicker",
                                        ]}
                                        className="p-0 date"
                                      >
                                        <DatePicker
                                          name="IRarchiveDate"
                                          value={
                                            values?.IRarchiveDate
                                              ? values?.IRarchiveDate
                                              : null
                                          }
                                          className="w-100 pt-0"
                                          onChange={(newValue) => {
                                            setFieldValue(
                                              "IRarchiveDate",
                                              newValue
                                            );
                                          }}
                                          views={["year", "month", "day"]}
                                          format="DD/MM/YYYY"
                                          slotProps={{
                                            field: {
                                              clearable: true,
                                            },
                                          }}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                    {errors?.IRarchiveDate &&
                                      touched.IRarchiveDate && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRarchiveDate}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Archive Activity
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={OneDayWeekMonthYearOptions}
                                      menuPlacement="auto"
                                      name="IRarchiveActivity"
                                      value={OneDayWeekMonthYearOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.IRarchiveActivity
                                      )}
                                      placeholder="Select archive activity..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "IRarchiveActivity",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRarchiveActivity &&
                                      touched.IRarchiveActivity && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRarchiveActivity}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Who Can View
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={
                                        values?.IRNotViewUsers?.length > 0
                                          ? allUsers?.filter(
                                            (value) =>
                                              !values.IRNotViewUsers.includes(
                                                value.value
                                              )
                                          )
                                          : allUsersWithYou
                                      }
                                      isMulti={
                                        values?.IRViewUsers != "all_users"
                                      }
                                      menuPlacement="auto"
                                      name="IRViewUsers"
                                      value={allUsersWithYou?.filter((option) =>
                                        values?.IRViewUsers?.includes(
                                          option?.value
                                        )
                                      )}
                                      placeholder="Select Users..."
                                      onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                          // Check if selectedOption is an array
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );

                                          if (users?.includes("all_users")) {
                                            setFieldValue("IRViewUsers", [
                                              "all_users",
                                            ]);
                                          } else {
                                            setFieldValue("IRViewUsers", [
                                              loggedUser?.id,
                                              ...users,
                                            ]);
                                          }
                                          // Filter users from IRNotObjectUsers that are in IRViewUsers
                                          const filteredIRNotObjectUsers =
                                            values.IRNotObjectUsers.filter(
                                              (user) =>
                                                users.includes(user) ||
                                                user === "all_users"
                                            );
                                          const filteredEditUsers =
                                            values.IREditUsers.filter(
                                              (user) =>
                                                users.includes(user) ||
                                                user === "all_users"
                                            );
                                          const filteredReviewByUsers =
                                            values.IRReviewByUsers.filter(
                                              (user) =>
                                                users.includes(user) ||
                                                user === "all_users"
                                            );
                                          setFieldValue(
                                            "IRNotObjectUsers",
                                            filteredIRNotObjectUsers
                                          );
                                          setFieldValue(
                                            "IREditUsers",
                                            filteredEditUsers
                                          );
                                          setFieldValue(
                                            "IRReviewByUsers",
                                            filteredReviewByUsers
                                          );
                                        } else {
                                          // Handle single option selection
                                          if (
                                            selectedOption?.value ===
                                            "all_users"
                                          ) {
                                            // If "all_users" is selected, set IRViewUsers to ["all_users"]
                                            setFieldValue("IRViewUsers", [
                                              selectedOption.value,
                                            ]);
                                          } else {
                                            // If a single user is selected, set IRViewUsers to [loggedUser's ID, selected user's ID]
                                            setFieldValue(
                                              "IRViewUsers",
                                              selectedOption
                                                ? [
                                                  loggedUser?.id,
                                                  selectedOption.value,
                                                ]
                                                : []
                                            );
                                          }
                                          // Remove the selected user from IRNotObjectUsers only if it's not present in IRViewUsers
                                          if (
                                            values.IRViewUsers.includes(
                                              selectedOption.value
                                            )
                                          ) {
                                            const filteredIRNotObjectUsers =
                                              values.IRNotObjectUsers.filter(
                                                (user) =>
                                                  user == selectedOption?.value
                                              );
                                            const filteredEditUsers =
                                              values.IREditUsers.filter(
                                                (user) =>
                                                  user == selectedOption?.value
                                              );
                                            const filteredReviewByUsers =
                                              values.IRReviewByUsers.filter(
                                                (user) =>
                                                  user == selectedOption?.value
                                              );
                                            setFieldValue(
                                              "IRNotObjectUsers",
                                              filteredIRNotObjectUsers
                                            );
                                            setFieldValue(
                                              "IREditUsers",
                                              filteredEditUsers
                                            );
                                            setFieldValue(
                                              "IRReviewByUsers",
                                              filteredReviewByUsers
                                            );
                                          }
                                        }
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRViewUsers &&
                                      touched.IRViewUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRViewUsers}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Who Can not View
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={allUsersExceptAll?.filter(
                                        (value) =>
                                          !values.IRViewUsers?.includes(
                                            value?.value
                                          )
                                      )}
                                      isMulti
                                      menuPlacement="auto"
                                      name="IRNotViewUsers"
                                      value={allUsers?.filter((option) =>
                                        values?.IRNotViewUsers?.includes(
                                          option?.value
                                        )
                                      )}
                                      placeholder="Select Users..."
                                      onChange={(selectedOption) => {
                                        const users = selectedOption.map(
                                          (option) => option.value
                                        );
                                        setFieldValue("IRNotViewUsers", users);

                                        const filteredIRNotObjectUsers =
                                          values.IRNotObjectUsers.filter(
                                            (user) => !users.includes(user)
                                          );
                                        setFieldValue(
                                          "IRNotObjectUsers",
                                          filteredIRNotObjectUsers
                                        );
                                        setFieldValue(
                                          "IREditUsers",
                                          filteredIRNotObjectUsers
                                        );
                                        setFieldValue(
                                          "IRReviewByUsers",
                                          filteredIRNotObjectUsers
                                        );
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRNotViewUsers &&
                                      touched.IRNotViewUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRNotViewUsers}
                                        </p>
                                      )}
                                  </Col>

                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Who Can View Details, but not object
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={
                                        !values?.IRViewUsers?.includes(
                                          "all_users"
                                        )
                                          ? allUsers?.filter((value) =>
                                            values?.IRViewUsers?.includes(
                                              value?.value
                                            )
                                          )
                                          : allUsers?.filter(
                                            (value) =>
                                              !values.IRNotViewUsers?.includes(
                                                value?.value
                                              )
                                          )
                                      }
                                      isMulti={
                                        values?.IRNotObjectUsers != "all_users"
                                      }
                                      menuPlacement="auto"
                                      name="IRNotObjectUsers"
                                      value={allUsers?.filter((option) =>
                                        values?.IRNotObjectUsers?.includes(
                                          option?.value
                                        )
                                      )}
                                      placeholder="Select Users..."
                                      onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                          // Check if selectedOption is an array
                                          const users = selectedOption.map(
                                            (option) => option?.value
                                          );
                                          if (users?.includes("all_users")) {
                                            setFieldValue("IRNotObjectUsers", [
                                              "all_users",
                                            ]);
                                          } else {
                                            setFieldValue("IRNotObjectUsers", [
                                              ...users,
                                            ]);
                                          }
                                        } else {
                                          // Handle single option selection
                                          setFieldValue(
                                            "IRNotObjectUsers",
                                            selectedOption
                                              ? [selectedOption.value]
                                              : []
                                          ); // Convert to array if selectedOption is not null
                                        }
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRNotObjectUsers &&
                                      touched.IRNotObjectUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRNotObjectUsers}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Who Can Edit
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={
                                        !values?.IRViewUsers?.includes(
                                          "all_users"
                                        )
                                          ? allUsersEdit?.filter((value) =>
                                            values?.IRViewUsers?.includes(
                                              value?.value
                                            )
                                          )
                                          : allUsersEdit?.filter(
                                            (value) =>
                                              !values.IRNotViewUsers?.includes(
                                                value?.value
                                              )
                                          )
                                      }
                                      isMulti={
                                        values?.IREditUsers != "all_users"
                                      }
                                      menuPlacement="auto"
                                      name="IREditUsers"
                                      value={allUsersEdit.filter((option) =>
                                        values?.IREditUsers?.includes(
                                          option?.value
                                        )
                                      )}
                                      placeholder="Select Users..."
                                      onChange={(selectedOption) => {
                                        if (Array.isArray(selectedOption)) {
                                          // Check if selectedOption is an array
                                          const users = selectedOption.map(
                                            (option) => option.value
                                          );
                                          setFieldValue("IREditUsers", [
                                            loggedUser?.id,
                                            ...users,
                                          ]); // Include loggedUser?.id as a single value
                                        } else {
                                          // Handle single option selection
                                          setFieldValue(
                                            "IREditUsers",
                                            selectedOption
                                              ? [
                                                loggedUser?.id,
                                                selectedOption.value,
                                              ]
                                              : []
                                          ); // Include loggedUser?.id as a single value
                                        }
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IREditUsers &&
                                      touched.IREditUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IREditUsers}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Related Items
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={false}
                                      options={getAllNewsData}
                                      isMulti
                                      menuPlacement="auto"
                                      name="IRRelatedItems"
                                      value={getAllNewsData?.filter((option) =>
                                        values?.IRRelatedItems?.includes(
                                          option?.value
                                        )
                                      )}
                                      placeholder="Select related items..."
                                      onChange={(selectedOption) => {
                                        const users = selectedOption.map(
                                          (option) => option.value
                                        );
                                        setFieldValue("IRRelatedItems", users);
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRRelatedItems &&
                                      touched.IRRelatedItems && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRRelatedItems}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text mb-2">
                                      Review
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={DayWeekMonthYearOptions}
                                      menuPlacement="auto"
                                      name="IRReviewUsers"
                                      value={DayWeekMonthYearOptions.find(
                                        (option) =>
                                          option.value === values.IRReviewUsers
                                      )}
                                      placeholder="Select Users..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "IRReviewUsers",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.IRReviewUsers &&
                                      touched.IRReviewUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.IRReviewUsers}
                                        </p>
                                      )}
                                  </Col>
                                  {values?.IRReviewUsers != "none" && (
                                    <Col lg="12">
                                      <Typography className="fw-400 dark-text mb-2">
                                        Review By
                                        <span className="required">*</span>
                                      </Typography>
                                      <Select
                                        closeMenuOnSelect={false}
                                        options={
                                          !values?.IRViewUsers?.includes(
                                            "all_users"
                                          )
                                            ? allUsers?.filter((value) =>
                                              values?.IRViewUsers?.includes(
                                                value?.value
                                              )
                                            )
                                            : allUsersExceptAll?.filter(
                                              (value) =>
                                                !values.IRNotViewUsers?.includes(
                                                  value?.value
                                                )
                                            )
                                        }
                                        isMulti={
                                          values?.IRReviewByUsers != "all_users"
                                        }
                                        menuPlacement="auto"
                                        name="IRReviewByUsers"
                                        value={allUsersExceptAll.filter(
                                          (option) =>
                                            values?.IRReviewByUsers?.includes(
                                              option?.value
                                            )
                                        )}
                                        placeholder="Select Users..."
                                        onChange={(selectedOption) => {
                                          if (Array.isArray(selectedOption)) {
                                            // Check if selectedOption is an array
                                            const users = selectedOption.map(
                                              (option) => option.value
                                            );
                                            setFieldValue(
                                              "IRReviewByUsers",
                                              users
                                            );
                                          } else {
                                            // Handle single option selection
                                            setFieldValue(
                                              "IRReviewByUsers",
                                              selectedOption
                                                ? [selectedOption.value]
                                                : []
                                            ); // Convert to array if selectedOption is not null
                                          }
                                        }}
                                        className="muilt-select-field"
                                        classNamePrefix="select"
                                        styles={{
                                          option: (provided, state) => {
                                            return {
                                              ...provided,
                                              backgroundColor: state.isSelected
                                                ? "#660c605c"
                                                : provided.backgroundColor,
                                              color: state.isSelected
                                                ? "var(--dark-color)"
                                                : provided.color,
                                            };
                                          },
                                        }}
                                      />
                                      {errors?.IRReviewByUsers &&
                                        touched.IRReviewByUsers && (
                                          <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                          >
                                            {errors?.IRReviewByUsers}
                                          </p>
                                        )}
                                    </Col>
                                  )}

                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      News Link Title
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="IRNewsLinkTitle"
                                      placeholder="Enter news link title here..."
                                      value={values?.IRNewsLinkTitle}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "IRNewsLinkTitle",
                                          event.target.value
                                        );
                                      }}
                                      helperText={
                                        touched.IRNewsLinkTitle &&
                                        errors.IRNewsLinkTitle
                                      }
                                      error={
                                        touched.IRNewsLinkTitle &&
                                        Boolean(errors.IRNewsLinkTitle)
                                      }
                                    />
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      News Link URL
                                    </Typography>
                                    <TextField
                                      className="input-field mt-2"
                                      fullWidth
                                      id="outlined-basic username"
                                      variant="outlined"
                                      size="small"
                                      name="IRNewsLinkURL"
                                      placeholder="Enter news link url here..."
                                      value={values?.IRNewsLinkURL}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "IRNewsLinkURL",
                                          event.target.value
                                        );
                                      }}
                                      helperText={
                                        touched.IRNewsLinkURL &&
                                        errors.IRNewsLinkURL
                                      }
                                      error={
                                        touched.IRNewsLinkURL &&
                                        Boolean(errors.IRNewsLinkURL)
                                      }
                                    />
                                  </Col>
                                  <FieldArray
                                    name="IRlinkFields"
                                    render={({ push, remove }) => (
                                      <>
                                        <Col
                                          lg="12"
                                          className="extra-links-div"
                                        >
                                          <Typography className="fw-400 dark-text">
                                            Extra Links
                                          </Typography>
                                          <Button
                                            className="btn primary-btn"
                                            onClick={() =>
                                              push({ title: "", url: "" })
                                            }
                                          >
                                            <FontAwesomeIcon icon={faPlus} />
                                            Add Links
                                          </Button>
                                        </Col>
                                        {values.IRlinkFields.map(
                                          (link, index) => (
                                            <React.Fragment key={index}>
                                              <Col
                                                lg="12"
                                                className="extra-links-div"
                                              >
                                                <Typography className="fw-400 dark-text">
                                                  Extra Link Data {index + 1}
                                                </Typography>
                                                <Button
                                                  className="btn btn-danger"
                                                  onClick={() => remove(index)}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTimes}
                                                  />
                                                </Button>
                                              </Col>
                                              <Col
                                                lg="12"
                                                className="extra-link-div-title"
                                              >
                                                <Typography className="fw-400 dark-text mt-0">
                                                  Link Title {index + 1}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </Typography>
                                                <Field
                                                  name={`IRlinkFields[${index}].title`}
                                                  render={({ field, form }) => (
                                                    <div className="input-group">
                                                      <TextField
                                                        className="input-field mt-2"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        placeholder="Enter title here..."
                                                        value={link.title}
                                                        {...field}
                                                      />
                                                    </div>
                                                  )}
                                                />
                                                <ErrorMessage
                                                  name={`IRlinkFields[${index}].title`}
                                                  component="p"
                                                  className="error-msg"
                                                />
                                              </Col>
                                              <Col lg="12">
                                                <Typography className="fw-400 dark-text">
                                                  Link URL {index + 1}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </Typography>
                                                <Field
                                                  name={`IRlinkFields[${index}].url`}
                                                  render={({ field, form }) => (
                                                    <TextField
                                                      className="input-field mt-2"
                                                      fullWidth
                                                      variant="outlined"
                                                      size="small"
                                                      placeholder="Enter URL here..."
                                                      {...field}
                                                    />
                                                  )}
                                                />

                                                <ErrorMessage
                                                  name={`IRlinkFields[${index}].url`}
                                                  component="p"
                                                  className="error-msg"
                                                />
                                              </Col>
                                            </React.Fragment>
                                          )
                                        )}
                                      </>
                                    )}
                                  />
                                </Row>
                              </CustomTabPanel>
                              <CustomTabPanel
                                value={value}
                                index={2}
                                errorTab={errorTab}
                              >
                                <Row>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Enable Comments
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngEnableComments"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngEnableComments
                                      )}
                                      placeholder="Select enable comments option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngEnableComments",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngEnableComments &&
                                      touched.EngEnableComments && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngEnableComments}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Comment Moderation{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngEnable_comment_moderation"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngEnable_comment_moderation
                                      )}
                                      placeholder="Select comment moderation option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngEnable_comment_moderation",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngEnable_comment_moderation &&
                                      touched.EngEnable_comment_moderation && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngEnable_comment_moderation}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Enable Likes{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngEnableLikes"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value === values.EngEnableLikes
                                      )}
                                      placeholder="Select enable likes option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngEnableLikes",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngEnableLikes &&
                                      touched.EngEnableLikes && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngEnableLikes}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Enable Rating{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngEnableRating"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngEnableRating
                                      )}
                                      placeholder="Select enable rating option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngEnableRating",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngEnableRating &&
                                      touched.EngEnableRating && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngEnableRating}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Notify Users{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngNotifyUsers"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value === values.EngNotifyUsers
                                      )}
                                      placeholder="Select notify user option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngNotifyUsers",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngNotifyUsers &&
                                      touched.EngNotifyUsers && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngNotifyUsers}
                                        </p>
                                      )}
                                  </Col>

                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Acknowledgement Required{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngAcknowledgementRequired"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngAcknowledgementRequired
                                      )}
                                      placeholder="Select acknowledgement required option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngAcknowledgementRequired",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                        const updatedNews = {
                                          ...getNewsData,
                                          EngAcknowledgementRequired:
                                            selectedOption?.value,
                                        };
                                        setGetNewsData(updatedNews);
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngAcknowledgementRequired &&
                                      touched.EngAcknowledgementRequired && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngAcknowledgementRequired}
                                        </p>
                                      )}
                                  </Col>
                                  {values?.EngAcknowledgementRequired ==
                                    "yes" && (
                                      <>
                                        <Col lg="12">
                                          <Typography className="fw-400 dark-text">
                                            Users Required to Acknowledge{" "}
                                            <span className="required">*</span>
                                          </Typography>
                                          <Select
                                            closeMenuOnSelect={false}
                                            options={allUsersWithYou}
                                            menuPlacement="auto"
                                            isMulti={
                                              values?.EngUsersForAcknowledge !=
                                              "all_users"
                                            }
                                            name="EngUsersForAcknowledge"
                                            value={allUsersWithYou.filter(
                                              (option) =>
                                                values.EngUsersForAcknowledge.includes(
                                                  option.value
                                                )
                                            )}
                                            placeholder="Select users..."
                                            onChange={(selectedOption) => {
                                              if (Array.isArray(selectedOption)) {
                                                // Check if selectedOption is an array
                                                const users = selectedOption.map(
                                                  (option) => option.value
                                                );
                                                if (
                                                  users?.includes("all_users")
                                                ) {
                                                  setFieldValue(
                                                    "EngUsersForAcknowledge",
                                                    ["all_users"]
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    "EngUsersForAcknowledge",
                                                    [loggedUser?.id, ...users]
                                                  );
                                                }
                                              } else {
                                                // Handle single option selection
                                                if (
                                                  selectedOption?.value ===
                                                  "all_users"
                                                ) {
                                                  // If "all_users" is selected, set IRViewUsers to ["all_users"]
                                                  setFieldValue(
                                                    "EngUsersForAcknowledge",
                                                    [selectedOption.value]
                                                  );
                                                } else {
                                                  // If a single user is selected, set IRViewUsers to [loggedUser's ID, selected user's ID]
                                                  setFieldValue(
                                                    "EngUsersForAcknowledge",
                                                    selectedOption
                                                      ? [
                                                        loggedUser?.id,
                                                        selectedOption.value,
                                                      ]
                                                      : []
                                                  );
                                                }
                                              }
                                            }}
                                            className="muilt-select-field mt-2"
                                            classNamePrefix="select"
                                            styles={{
                                              option: (provided, state) => {
                                                return {
                                                  ...provided,
                                                  backgroundColor:
                                                    state.isSelected
                                                      ? "#660c605c"
                                                      : provided.backgroundColor,
                                                  color: state.isSelected
                                                    ? "var(--dark-color)"
                                                    : provided.color,
                                                };
                                              },
                                            }}
                                          />
                                          {errors?.EngUsersForAcknowledge &&
                                            touched.EngUsersForAcknowledge && (
                                              <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                              >
                                                {errors?.EngUsersForAcknowledge}
                                              </p>
                                            )}
                                        </Col>
                                        <Col lg="12">
                                          <Typography className="fw-400 dark-text">
                                            Due by{" "}
                                            <span className="required">*</span>
                                          </Typography>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <DemoContainer
                                              components={[
                                                "DatePicker",
                                                "DatePicker",
                                              ]}
                                              className="p-0"
                                            >
                                              <DatePicker
                                                name="EngDueBy"
                                                value={values?.EngDueBy}
                                                className="w-100 pt-0"
                                                onChange={(newValue) => {
                                                  setFieldValue(
                                                    "EngDueBy",
                                                    newValue
                                                  );
                                                }}
                                                views={["year", "month", "day"]}
                                                slotProps={{
                                                  field: {
                                                    clearable: true,
                                                  },
                                                }}
                                                format="DD/MM/YYYY"
                                              />
                                            </DemoContainer>
                                          </LocalizationProvider>
                                          {errors?.EngDueBy &&
                                            touched.EngDueBy && (
                                              <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                              >
                                                {errors?.EngDueBy}
                                              </p>
                                            )}
                                        </Col>
                                        <Col lg="12">
                                          <Typography className="fw-400 dark-text">
                                            Due by Expiry Action{" "}
                                            <span className="required">*</span>
                                          </Typography>
                                          <Select
                                            closeMenuOnSelect={true}
                                            options={ExpiryActions}
                                            menuPlacement="auto"
                                            name="EngDueByExpiryAction"
                                            value={ExpiryActions.find(
                                              (option) =>
                                                option.value ===
                                                values.EngDueByExpiryAction
                                            )}
                                            placeholder="Select due by expiry action option..."
                                            onChange={(selectedOption) => {
                                              setFieldValue(
                                                "EngDueByExpiryAction",
                                                selectedOption
                                                  ? selectedOption.value
                                                  : ""
                                              );
                                            }}
                                            className="muilt-select-field mt-2"
                                            classNamePrefix="select"
                                            styles={{
                                              option: (provided, state) => {
                                                return {
                                                  ...provided,
                                                  backgroundColor:
                                                    state.isSelected
                                                      ? "#660c605c"
                                                      : provided.backgroundColor,
                                                  color: state.isSelected
                                                    ? "var(--dark-color)"
                                                    : provided.color,
                                                };
                                              },
                                            }}
                                          />
                                          {errors?.EngDueByExpiryAction &&
                                            touched.EngDueByExpiryAction && (
                                              <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                              >
                                                {errors?.EngDueByExpiryAction}
                                              </p>
                                            )}
                                        </Col>
                                        <Col lg="12">
                                          <Typography className="fw-400 dark-text">
                                            Reminder notifications{" "}
                                            <span className="required">*</span>
                                          </Typography>
                                          <Select
                                            closeMenuOnSelect={true}
                                            options={ReminderNotificationActions}
                                            menuPlacement="auto"
                                            name="EngReminderNotification"
                                            value={ReminderNotificationActions.find(
                                              (option) =>
                                                option.value ===
                                                values.EngReminderNotification
                                            )}
                                            placeholder="Select reminder notification option..."
                                            onChange={(selectedOption) => {
                                              setFieldValue(
                                                "EngReminderNotification",
                                                selectedOption
                                                  ? selectedOption.value
                                                  : ""
                                              );
                                            }}
                                            className="muilt-select-field mt-2"
                                            classNamePrefix="select"
                                            styles={{
                                              option: (provided, state) => {
                                                return {
                                                  ...provided,
                                                  backgroundColor:
                                                    state.isSelected
                                                      ? "#660c605c"
                                                      : provided.backgroundColor,
                                                  color: state.isSelected
                                                    ? "var(--dark-color)"
                                                    : provided.color,
                                                };
                                              },
                                            }}
                                          />
                                          {errors?.EngReminderNotification &&
                                            touched.EngReminderNotification && (
                                              <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                              >
                                                {errors?.EngReminderNotification}
                                              </p>
                                            )}
                                        </Col>
                                        <Col lg="12">
                                          <Typography className="fw-400 dark-text">
                                            Acknowledgement Type{" "}
                                            <span className="required">*</span>
                                          </Typography>
                                          <Select
                                            closeMenuOnSelect={true}
                                            options={AcknowledgementTypeOptions}
                                            menuPlacement="auto"
                                            name="EngAcknowledgementType"
                                            value={AcknowledgementTypeOptions.find(
                                              (option) =>
                                                option.value ===
                                                values.EngAcknowledgementType
                                            )}
                                            placeholder="Select acknowledgement type option..."
                                            onChange={(selectedOption) => {
                                              setFieldValue(
                                                "EngAcknowledgementType",
                                                selectedOption
                                                  ? selectedOption.value
                                                  : []
                                              );
                                            }}
                                            className="muilt-select-field mt-2"
                                            classNamePrefix="select"
                                            styles={{
                                              option: (provided, state) => {
                                                return {
                                                  ...provided,
                                                  backgroundColor:
                                                    state.isSelected
                                                      ? "#660c605c"
                                                      : provided.backgroundColor,
                                                  color: state.isSelected
                                                    ? "var(--dark-color)"
                                                    : provided.color,
                                                };
                                              },
                                            }}
                                          />
                                          {errors?.EngAcknowledgementType &&
                                            touched.EngAcknowledgementType && (
                                              <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                              >
                                                {errors?.EngAcknowledgementType}
                                              </p>
                                            )}
                                        </Col>
                                        <>
                                          {values?.EngAcknowledgementType ===
                                            "checkbox" ? (
                                            <Col lg="12">
                                              <Typography className="fw-400 dark-text">
                                                Acknowledgement Text{" "}
                                                <span className="required">
                                                  *
                                                </span>
                                              </Typography>
                                              <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="EngAcknowledgementText"
                                                placeholder="Enter acknowledgement text here..."
                                                value={
                                                  values?.EngAcknowledgementText
                                                }
                                                onChange={(event) => {
                                                  setFieldValue(
                                                    "EngAcknowledgementText",
                                                    event.target.value
                                                  );
                                                  if (
                                                    event.target.value ===
                                                    "questions"
                                                  ) {
                                                    setFieldValue(
                                                      "EngAcknowledgementQuestions",
                                                      [{ title: "" }]
                                                    );
                                                  }
                                                }}
                                                helperText={
                                                  touched.EngAcknowledgementText &&
                                                  errors.EngAcknowledgementText
                                                }
                                                error={
                                                  touched.EngAcknowledgementText &&
                                                  Boolean(
                                                    errors.EngAcknowledgementText
                                                  )
                                                }
                                              />
                                            </Col>
                                          ) : values?.EngAcknowledgementType ===
                                            "questions" ? (
                                            <>
                                              <FieldArray
                                                name="EngAcknowledgementQuestions"
                                                render={({ push, remove }) => (
                                                  <>
                                                    <Col
                                                      lg="12"
                                                      className="extra-links-div"
                                                    >
                                                      <Typography className="fw-400 dark-text">
                                                        Questions
                                                      </Typography>
                                                      {values
                                                        ?.EngAcknowledgementQuestions
                                                        ?.length < 3 && (
                                                          <Button
                                                            className="btn primary-btn"
                                                            onClick={() =>
                                                              push({ title: "" })
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faPlus}
                                                            />
                                                            Add Question
                                                          </Button>
                                                        )}
                                                    </Col>
                                                    {values?.EngAcknowledgementQuestions?.map(
                                                      (link, index) => (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          <Col
                                                            lg="12"
                                                            className="extra-links-div"
                                                          >
                                                            <Typography className="fw-400 dark-text">
                                                              Question {index + 1}
                                                            </Typography>
                                                            <Button
                                                              className="btn btn-danger"
                                                              onClick={() => {
                                                                if (index > 0) {
                                                                  remove(index);
                                                                }
                                                              }}
                                                            >
                                                              <FontAwesomeIcon
                                                                icon={faTimes}
                                                              />
                                                            </Button>
                                                          </Col>
                                                          <Col
                                                            lg="12"
                                                            className="extra-link-div-title"
                                                          >
                                                            <Typography className="fw-400 dark-text">
                                                              Question title for
                                                              question {index + 1}
                                                              <span className="required">
                                                                *
                                                              </span>
                                                            </Typography>
                                                            <Field
                                                              name={`EngAcknowledgementQuestions[${index}].title`}
                                                              render={({
                                                                field,
                                                                form,
                                                              }) => (
                                                                <div className="input-group">
                                                                  <TextField
                                                                    className="input-field mt-2"
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    size="small"
                                                                    placeholder="Enter question here..."
                                                                    value={
                                                                      link?.title
                                                                    }
                                                                    {...field}
                                                                  />
                                                                </div>
                                                              )}
                                                            />
                                                            <ErrorMessage
                                                              name={`EngAcknowledgementQuestions[${index}].title`}
                                                              component="p"
                                                              className="error-msg"
                                                            />
                                                          </Col>
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      </>
                                    )}

                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Show top of feed{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={ShowTopOfFeedOptionActions}
                                      menuPlacement="auto"
                                      name="EngShowTopOfFeed"
                                      value={ShowTopOfFeedOptionActions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngShowTopOfFeed
                                      )}
                                      placeholder="Select show top of feed option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngShowTopOfFeed",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngShowTopOfFeed &&
                                      touched.EngShowTopOfFeed && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngShowTopOfFeed}
                                        </p>
                                      )}
                                  </Col>
                                  <Col lg="12">
                                    <Typography className="fw-400 dark-text">
                                      Highlight as modal{" "}
                                      <span className="required">*</span>
                                    </Typography>
                                    <Select
                                      closeMenuOnSelect={true}
                                      options={yesNoOptions}
                                      menuPlacement="auto"
                                      name="EngHighlightAsModal"
                                      value={yesNoOptions.find(
                                        (option) =>
                                          option.value ===
                                          values.EngHighlightAsModal
                                      )}
                                      placeholder="Select highlight as model option..."
                                      onChange={(selectedOption) => {
                                        setFieldValue(
                                          "EngHighlightAsModal",
                                          selectedOption
                                            ? selectedOption.value
                                            : ""
                                        );
                                      }}
                                      className="muilt-select-field mt-2"
                                      classNamePrefix="select"
                                      styles={{
                                        option: (provided, state) => {
                                          return {
                                            ...provided,
                                            backgroundColor: state.isSelected
                                              ? "#660c605c"
                                              : provided.backgroundColor,
                                            color: state.isSelected
                                              ? "var(--dark-color)"
                                              : provided.color,
                                          };
                                        },
                                      }}
                                    />
                                    {errors?.EngHighlightAsModal &&
                                      touched.EngHighlightAsModal && (
                                        <p
                                          style={{ color: "#d32f2f" }}
                                          className="error-msg"
                                        >
                                          {errors?.EngHighlightAsModal}
                                        </p>
                                      )}
                                  </Col>
                                  {values?.EngHighlightAsModal === "yes" && (
                                    <>
                                      <Col lg="12">
                                        <Typography className="fw-400 dark-text">
                                          Modal behaviour{" "}
                                          <span className="required">*</span>
                                        </Typography>
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={ModelBehaviourOptions}
                                          menuPlacement="auto"
                                          name="EngModalBehaviour"
                                          value={ModelBehaviourOptions.find(
                                            (option) =>
                                              option.value ===
                                              values.EngModalBehaviour
                                          )}
                                          placeholder="Select modal behaviour option..."
                                          onChange={(selectedOption) => {
                                            setFieldValue(
                                              "EngModalBehaviour",
                                              selectedOption
                                                ? selectedOption.value
                                                : ""
                                            );
                                          }}
                                          className="muilt-select-field mt-2"
                                          classNamePrefix="select"
                                          styles={{
                                            option: (provided, state) => {
                                              return {
                                                ...provided,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "#660c605c"
                                                    : provided.backgroundColor,
                                                color: state.isSelected
                                                  ? "var(--dark-color)"
                                                  : provided.color,
                                              };
                                            },
                                          }}
                                        />
                                        {errors?.EngModalBehaviour &&
                                          touched.EngModalBehaviour && (
                                            <p
                                              style={{ color: "#d32f2f" }}
                                              className="error-msg"
                                            >
                                              {errors?.EngModalBehaviour}
                                            </p>
                                          )}
                                      </Col>
                                      <Col lg="12">
                                        <Typography className="fw-400 dark-text">
                                          Dismiss model behaviour after{" "}
                                          <span className="required">*</span>
                                        </Typography>
                                        <Select
                                          closeMenuOnSelect={true}
                                          options={DismissModelBehaviourOption}
                                          menuPlacement="auto"
                                          name="EngDismissModel"
                                          value={DismissModelBehaviourOption.find(
                                            (option) =>
                                              option.value ===
                                              values.EngDismissModel
                                          )}
                                          placeholder="Select dismiss model behaviour after option..."
                                          onChange={(selectedOption) => {
                                            setFieldValue(
                                              "EngDismissModel",
                                              selectedOption
                                                ? selectedOption.value
                                                : ""
                                            );
                                          }}
                                          className="muilt-select-field mt-2"
                                          classNamePrefix="select"
                                          styles={{
                                            option: (provided, state) => {
                                              return {
                                                ...provided,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "#660c605c"
                                                    : provided.backgroundColor,
                                                color: state.isSelected
                                                  ? "var(--dark-color)"
                                                  : provided.color,
                                              };
                                            },
                                          }}
                                        />
                                        {errors?.EngDismissModel &&
                                          touched.EngDismissModel && (
                                            <p
                                              style={{ color: "#d32f2f" }}
                                              className="error-msg"
                                            >
                                              {errors?.EngDismissModel}
                                            </p>
                                          )}
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </CustomTabPanel>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Stack>
                    </Col>

                    <Col lg="3" md="5">
                      <Stack
                        direction="vertical"
                        className="gap-30 change-direction"
                      >
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Publication Options
                          </Card.Header>
                          <Card.Body className="p-15">
                            <Stack gap="10">

                              {getNewsData?.published_date == null && (
                                <Button
                                  className="btn btn-secondary w-100 text-white"
                                  type="submit"
                                  onClick={() => {
                                    setPublicationOption("draft");
                                    setShowError(true);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faClipboard} />
                                  Save As Draft
                                </Button>
                              )}

                              <Button
                                className="btn primary-btn w-100"
                                type="submit"
                                onClick={() => {
                                  setShowError(true);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="d-md-block d-sm-none d-none"
                                />
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="d-md-none d-sm-block"
                                />
                                Publish Page
                              </Button>
                              <div></div>
                            </Stack>
                          </Card.Body>
                        </Card>
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Post Image <span className="required">*</span>
                          </Card.Header>
                          <Card.Body className="p-15">
                            <ImageUpload
                              value={values?.image}
                              handleClick={() => handleClick("image")}
                              handleFileChange={handleFileChange}
                              setFieldValue={setFieldValue}
                            />
                            {errors?.image && touched.image && (
                              <p
                                style={{ color: "#d32f2f" }}
                                className="error-msg"
                              >
                                {errors?.image}
                              </p>
                            )}
                          </Card.Body>
                        </Card>
                        {type != "news" && (
                          <Card className="bg-white card-wrapper">
                            <Card.Header className="bg-white fw-700 primary-text">
                              Post File
                            </Card.Header>
                            <Card.Body className="p-15">
                              <FileUpload
                                value={values?.file}
                                handleClick={() => handleClick("file")}
                                handleFileChange={handleFileChange}
                                setFieldValue={setFieldValue}
                                values={values}
                                touched={touched}
                                errors={errors}
                              />
                              {errors?.file && touched.file && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.file}
                                </p>
                              )}
                            </Card.Body>
                          </Card>
                        )}
                      </Stack>
                    </Col>
                  </Row>
                  <FileUploadModal
                    open={openFileUploadModel}
                    setOpen={setOpenFileUploadModel}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    title={contentForPost}
                    setFieldValue={setFieldValue}
                    fileTypes={
                      contentForPost === "image" ? imageFileTypes : fileTypes
                    }
                    handleFileChange={handleFileChange}
                    file={
                      contentForPost === "image"
                        ? selectedImageFile
                        : selectedFile
                    }
                    setFileValue={() => handleImageFileValue(setFieldValue)}
                    isImage={contentForPost === "image" ? true : false}
                    isImageEditable={isEdit}
                  />
                </Form>
              );
            }}
          </Formik>

          {/* START :: PAGE TITLE WRAPPER */}
        </Container>

        <Loader isLoading={loading} />
        <Toaster />
      </div>

      {/* END :: MAIN CONTENT WRAPPER */}
    </>
  );
};

export default DummyPostType;
