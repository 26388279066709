import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import {
    TextField,
    Typography,
    Tabs,
    Tab,
    Box,
    Breadcrumbs,
    Link,
    Divider,
    Stack,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    IconButton,
    styled,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faXmark,
    faFloppyDisk,
    faDownload,
    faUser,
    faPen,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import Select from "react-select";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import { Toast } from "../../utils/toast";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { WarningAlert } from "../../components/common/alert/warningAlert.js";
import { SuccessAlert } from "../../components/common/alert/successAlert.js";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "bootstrap-daterangepicker/daterangepicker.css";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting.js";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MuiColorInput } from "mui-color-input";
import "../../styles/links.scss";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { exportXlsx } from "../../helpers/Exports/FileExport.js";
import { links } from "../../redux/slices/link/link.js";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiPaper-root": {
        // minHeight: "40vh",
        // maxHeight: "40vh"
    }
}));

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box padding-left-right ">{children}</Box>}
        </div>
    );
}


const LinksList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [openAddLinksModel, setOpenLinksAddModel] = useState(false)
    const [openAddLabelModel, setOpenLabelAddModel] = useState(false)

    const [activeTab, setActiveTab] = useState(0);

    const [getAllIcons, setGetAllIcons] = useState([]);

    const [menuItemTypeColor, setMenuItemTypeColor] = useState("#FFFFFF")
    const [menuItemTypeBGColor, setMenuItemTypeBGColor] = useState("#660C60")
    const [menuItemTypeIcon, setMenuItemTypeIcon] = useState("");

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    const [getAllGroupsAndUsers, setGetAllGroupsAndUsers] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [isAdvancePermission, setIsAdvancePermission] = useState(false);
    const [fetchAllUsers, setFetchAllUsers] = useState([])
    const [addLinkErrors, setAddLinkErrors] = useState({})

    const [linkName, setLinkName] = useState("")
    const [linkType, setLinkType] = useState("internal")
    const [linkLabels, setLinkLabels] = useState([])
    const [linkOpenTab, setLinkOpenTab] = useState(true)
    const [linkUrl, setLinkUrl] = useState("")
    const [linkLabelOptions, setLinkLabelOptions] = useState([])
    const [linkIcon, setLinkIcon] = useState("")
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    //Labels form states
    const [labelName, setLabelName] = useState("");
    const [labelIconId, setLabelIconId] = useState("");
    const [labelIconValue, setLabelIconValue] = useState("")
    const [addLabelErrors, setAddLabelErrors] = useState({})



    //Listing labels states
    const [listingLabels, setListingLabels] = useState([]);
    const { selectedLabelId, } = useSelector((state) => state?.link);

    //Listing links states
    const [listingLinks, setListingLinks] = useState([]);;
    const [editLinkId, setEditLinkId] = useState();

    const userToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");

    const [tabs, setTabs] = useState([
        { key: '0', label: 'Link Data' },
        { key: '1', label: 'Design Options' },
        { key: '2', label: 'Permissions' },
    ]);

    const columns_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const columns_advace_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },

        {
            key: 5,
            id: "edit",
            disablePadding: true,
            label: "Edit",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 6,
            id: "delete",
            disablePadding: true,
            label: "Delete",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 7,
            id: "redacted",
            disablePadding: true,
            label: "Redacted",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        activeType,
        drawerVariant,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleCloseAddLink = () => {
        setEditLinkId();
        setActiveTab(0);
        setAddLinkErrors({});
        setLinkName("")
        setLinkType("internal")
        setLinkLabels([])
        setLinkOpenTab(true)
        setLinkUrl("")
        setOpenLinksAddModel(false)
        setMenuItemTypeIcon("")
        setLinkIcon("")
        setMenuItemTypeColor("#959595")
        setMenuItemTypeBGColor("#660C60")
        const allUserOption = fetchAllUsers.find(
            (user) => user.value === "all_users"
        );
        // Preselect "all_user"
        if (allUserOption) {
            setPermissions([allUserOption]);
        }
        setOpenLinksAddModel(false);
    }

    const handleCloseAddLabel = () => {
        setOpenLabelAddModel(false)
        setAddLabelErrors({});
        setLabelName("");
        setLabelIconId("");
        setLabelIconValue("");
    }

    const handleLinkTypeChange = (event) => {
        setLinkType(event?.target?.value)
    }

    const handleChangeForLinkOpenTab = () => {
        setLinkOpenTab(!linkOpenTab)
    }

    const handleChangeForIconColor = (newValue) => {
        setMenuItemTypeColor(newValue)

    };

    const handleChangeForIconBGColor = (newValue) => {
        setMenuItemTypeBGColor(newValue)
    };

    const handleToggle = (value, key) => {
        setPermissions((prevPermissions) =>
            prevPermissions?.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );

        setGetAllGroupsAndUsers((prevPermissions) =>
            prevPermissions.map((permission) => {
                if (permission?.value === value) {
                    if (key === 'deny') {
                        // If deny is toggled, set all other permissions to false
                        return {
                            ...permission,
                            deny: !permission.deny,
                            view: false,
                            edit: false,
                            delete: false,
                            redacted: false,
                        };
                    } else {
                        // If any other permission is toggled, set deny to false
                        return {
                            ...permission,
                            [key]: !permission[key],
                            deny: false,
                        };
                    }
                }
                return permission;
            })
        );
    };

    const handleCloseWarningMessage = () => {
        setIsWarningMessage(false);

        setWarningMessage("");
    };

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setIsSuccessMessage("");
    };

    const validateFieldsForAddMenuLink = () => {
        const errors = {};
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;

        if (!linkName.trim()) {
            errors.title = "Link name is required.";
        }
        if (linkName.length > 255) {
            errors.title = "Link name is not greater than 255 character"
        }
        if (linkLabels?.length < 1) {
            errors.labels = "Select or add atleast one label"
        }
        if (linkUrl === "") {
            errors.url = "Please enter link URL.";
        } else if (!urlPattern.test(linkUrl)) {
            errors.url = "Please enter a valid URL (e.g., http://example.com or https://example.com).";
        }
        if (permissions?.length < 1) {
            errors.permissions = "Select atleast one user/group."
        }
        if (menuItemTypeIcon === "") {
            errors.icon = "Link icon is required."
        }
        if (!menuItemTypeColor.trim()) {
            errors.iconcolor = "Link icon color is required."
        }
        if (!menuItemTypeBGColor.trim()) {
            errors.iconBgColor = "Link icon background color is required."
        }
        return errors;
    };

    const onSubmit = async () => {
        const validationErrors = validateFieldsForAddMenuLink();
        if (Object.keys(validationErrors).length > 0) {
            setAddLinkErrors(validationErrors);
        } else {
            const formData = new FormData();
            let permissionData;
            if (isAdvancePermission === true) {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view === true ? 1 : 0,
                        can_deny: data?.deny === true ? 1 : 0,
                        can_edit: data?.edit === true ? 1 : 0,
                        can_delete: data?.delete === true ? 1 : 0,
                        can_redacted: data?.redacted === true ? 1 : 0,
                        can_add: data?.add === true ? 1 : 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view === true ? 1 : 0,
                        can_deny: data?.deny === true ? 1 : 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_redacted: 0,
                        can_add: 0,
                        label: data?.label,
                    };
                });
            }

            formData.append("title", linkName)
            formData.append("type", linkType)
            formData.append("label", JSON?.stringify(linkLabels))
            formData.append("open_new_tab", linkOpenTab ? 1 : 0);
            formData.append("link", linkUrl);
            formData.append("icon", menuItemTypeIcon);
            formData.append("colour", menuItemTypeColor);
            formData.append("background", menuItemTypeBGColor);
            formData.append("permission", JSON?.stringify(permissionData))
            formData.append("advance_permission", isAdvancePermission === true ? 1 : 0)

            if (editLinkId) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `/admin/link/update-link/${editLinkId}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        fetchElementList();
                        fetchLabelsLink();
                        handleCloseAddLink();
                        Toast.success(response?.message);

                    } else if (response?.success === false) {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error while creating new links::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(
                            err?.response?.data?.errors
                        ).forEach((value) => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.");
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        "/admin/link/store-link",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        fetchElementList();
                        fetchLabelsLink();
                        handleCloseAddLink();
                        Toast.success(response?.message);

                    } else if (response?.success === false) {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    setLoading(false);
                    if (err?.response?.status === 401) {
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    else if (err?.response?.status === 422) {
                        const errorData = Object.values(
                            err?.response?.data?.errors
                        ).forEach((value) => Toast.error(value));
                    }
                    else if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.");
                    } else {
                        console.error("error while creating new links::", err);
                    }
                }
            }
        }
    }

    const validateLabelForm = () => {
        const errors = {};

        if (!labelName.trim()) {
            errors.title = "Label name is required.";
        }
        if (labelIconId === "") {
            errors.icon = "Label icon is required."
        }
        return errors;
    };

    const onSubmitLabel = async () => {
        const validationErrors = validateLabelForm();
        if (Object.keys(validationErrors).length > 0) {
            setAddLabelErrors(validationErrors);
        } else {
            const formData = new FormData();

            formData.append("title", labelName)
            formData.append("icon", labelIconId);

            try {
                setLoading(true);
                const response = await api.post(
                    "/admin/link/store-label",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (response?.success === true) {
                    setLoading(false);
                    fetchElementList();
                    fetchLabelsLink();
                    handleCloseAddLabel();
                    Toast.success(response?.message);

                } else if (response?.success === false) {
                    setLoading(false);
                    Toast.error(response?.message);
                }
            } catch (err) {
                console.log("error while creating new label::", err);
                setLoading(false);

                if (err?.response?.status === 401) {
                    setLoading(false);
                    logoutHelper(dispatch, navigate, userToken);
                }
                if (err?.response?.status === 422) {
                    setLoading(false);
                    const errorData = Object.values(
                        err?.response?.data?.errors
                    ).forEach((value) => Toast.error(value));
                }
                if (err?.response?.status === 429) {
                    Toast.error("Too many attemps wait for 2 min.");
                }
            }


        }
    }

    const fetchElementList = async () => {
        try {
            setLoading(true);
            const response = await api.get(`admin/link/label-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const users = response?.response?.users;
                const groups = response?.response?.groups;
                const labels = response?.response?.labels;
                const icons = response?.response?.icons;

                setLinkLabelOptions(labels);
                setGetAllIcons(icons);

                setListingLabels([{ value: 0, label: 'All Links', icon: { icon_value: faUser } }, ...labels]);

                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        redacted: false,
                        type: data?.type,
                    };
                });

                setFetchAllUsers(finalUsers)

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];

                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user.value === "all_users"
                );
                setGetAllGroupsAndUsers(allGroupsAndUsersData);
                // Preselect "all_user"
                if (allUserOption) {
                    setPermissions([allUserOption]);
                }
                setLoading(false);
            } else if (response?.success === false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    };

    const fetchLabelsLink = async () => {
        try {
            setLoading(true);
            const response = await api.get(`admin/link/link-list/${selectedLabelId}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const data = response?.response?.data
                setListingLinks(data);
                setLoading(false);
            } else if (response?.success === false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch links list api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchLinkDetail = async (id) => {
        try {
            setLoading(true);
            const response = await api.get(`admin/link/edit-link/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const data = response?.response
                createEditData(data);
                setLoading(false);
            } else if (response?.success === false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch links list api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const createEditData = (data) => {
        setLinkName(data?.title);
        setLinkType(data?.type);
        setLinkLabels(data?.label);
        setLinkOpenTab(data?.open_new_tab ? true : false);
        setLinkUrl(data?.link);
        setMenuItemTypeIcon(data?.icon?.id);
        setLinkIcon(data?.icon?.icon_value)
        setMenuItemTypeColor(data?.colour);
        setMenuItemTypeBGColor(data?.background);
        const linkPermissions = data?.permission?.map((permission) => {
            return {
                label: permission?.label,
                value: permission?.user_group_id,
                view: permission?.can_view === 1 ? true : false,
                deny: permission?.can_deny === 1 ? true : false,
                edit: permission?.can_edit === 1 ? true : false,
                delete: permission?.can_delete === 1 ? true : false,
                redacted: permission?.can_add === 1 ? true : false,
                type: permission?.type
            }
        })
        setPermissions(linkPermissions);
        setIsAdvancePermission(data?.advance_permission ? true : false);
    }

    const handleLabelClick = async (id) => {
        // setSelectedLabelId(id);
        dispatch(links.setSelectedLabelId(id));

    }

    const handleEditClick = async (id) => {
        setEditLinkId(id);
        setOpenLinksAddModel(true);
        await fetchLinkDetail(id);

    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [openAddPopup, setOpenAddPopup] = useState(false);

    const handleAddClick = (event) => {
        setOpenAddPopup(true)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenAddPopup(false)
    };

    const [exportAnchorEl, setExportAnchorEl] = useState(null);
    const [openExportPopup, setOpenExportPopup] = useState(false);

    const handleExportClick = (event) => {
        setOpenExportPopup(true)
        setExportAnchorEl(event.currentTarget);
    };

    const handleExportClose = () => {
        setExportAnchorEl(null);
        setOpenExportPopup(false)
    };

    const handleCsvExport = async (type) => {
        try {
            setLoading(true);
            let result;
            if (type === 'link') {
                result = await exportXlsx(`admin/link/link-export/${selectedLabelId}`, 'links_listing');
            } else if (type === 'label') {
                result = await exportXlsx(`admin/link/label-export`, 'labels_listing');
            }

            if (!result) {
                Toast.error("Failed to download file");
            }

        } catch (error) {
            console.error("Error while Exporting links or labels::", error);
            Toast.error("Failed to download file");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchLabelsLink();
    }, [selectedLabelId])

    useEffect(() => {
        fetchElementList()
    }, [])
    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open === true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    {isSuccessMessage === true && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}

                    {isWarningMessage === true && (
                        <WarningAlert
                            message={warningMessage}
                            onClose={handleCloseWarningMessage}
                        />
                    )}
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        Admin
                                    </Link>
                                    <Typography key="3" component="h3" color="text.primary">
                                        Links
                                    </Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <div className="d-flex flex-wrap gap-3">
                            <Button className="btn export-btn" variant="outlined"
                                onClick={handleExportClick}
                            >
                                Export
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            {/* Popover for export */}
                            <Popover
                                id={openExportPopup ? 'simple-popover' : undefined}
                                open={openExportPopup}
                                anchorEl={exportAnchorEl}
                                onClose={handleExportClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box>
                                    <List component="nav" className="p-0" aria-label="main mailbox folders">
                                        <ListItemButton
                                            onClick={() => {
                                                handleExportClose()
                                                handleCsvExport('link');
                                            }}
                                        >
                                            <ListItemText primary="Export Links" />
                                        </ListItemButton>
                                        <Divider className="divider" />
                                        <ListItemButton
                                            onClick={() => {
                                                handleExportClose();
                                                handleCsvExport('label');
                                            }}
                                        >
                                            <ListItemText primary="Export Labels" />
                                        </ListItemButton>
                                    </List>
                                </Box>
                            </Popover>

                            <Button
                                className="btn primary-btn"
                                onClick={handleAddClick}
                            >
                                Add
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <Popover
                                id={openAddPopup ? 'simple-popover' : undefined}
                                open={openAddPopup}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box>
                                    <List component="nav" className="p-0" aria-label="main mailbox folders">
                                        <ListItemButton
                                            onClick={() => {
                                                handleClose()
                                                setOpenLinksAddModel(true)
                                            }}
                                        >
                                            <ListItemText primary="Add Link" />
                                        </ListItemButton>
                                        <Divider className="divider" />
                                        <ListItemButton
                                            onClick={() => {
                                                handleClose()
                                                setOpenLabelAddModel(true)
                                            }}
                                        >
                                            <ListItemText primary="Add Label" />
                                        </ListItemButton>
                                    </List>
                                </Box>
                            </Popover>
                        </div>
                    </div>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box>
                        <Grid container spacing={2}>
                            {/* Labels listings */}
                            <Grid item xs={12} md={3} className=" mt-3 py-1 link-label-list-grid">
                                <List className="list-item-menu gap-0">
                                    {listingLabels.map((label, index) => (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            sx={{ display: "block" }}
                                            className={`${selectedLabelId === label?.value && "link-selected-type-border-right"}`}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    px: 2.5,
                                                }}
                                                onClick={() => {
                                                    handleLabelClick(label?.value);
                                                }}
                                            >
                                                {label?.icon?.icon_value && (
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: 2,
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={label?.icon?.icon_value}
                                                            className={`${selectedLabelId === label?.value ? "link-label-icon-selected" : "link-label-icon"}`}
                                                        />
                                                    </ListItemIcon>
                                                )}
                                                <ListItemText
                                                    primary={label?.label}
                                                    className={`${selectedLabelId === label?.value ? "link-label-name-selected" : "link-label-name"}`}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                            {/* links listings */}
                            <Grid item xs={12} md={9} className=" mt-4 py-1 link-list-grid">
                                <Grid container spacing={2}>
                                    {listingLinks?.map((link, index) => (
                                        <Grid key={index} item xs={12} sm={6} md={4}>
                                            <Box className="py-3 link-icon-box" sx={{
                                                backgroundColor: link?.background,
                                                color: link?.colour,
                                                cursor: 'pointer',
                                                position: 'relative',
                                                overflow: 'hidden',
                                                '&:hover .hover-overlay': {
                                                    opacity: 1,
                                                },
                                                '&:hover .icon-content': {
                                                    opacity: 0.5,
                                                },
                                            }}>
                                                <Box className="icon-content">
                                                    <Box>
                                                        <FontAwesomeIcon icon={link?.icon?.icon_value} />
                                                    </Box>
                                                    <Box className="link-label-text">
                                                        <Typography>{link?.label}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box
                                                    className="hover-overlay"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        opacity: 0,
                                                        transition: 'opacity 0.3s ease',
                                                    }}
                                                >
                                                    <FontAwesomeIcon onClick={() => { handleEditClick(link?.id) }} icon={faPen} />
                                                </Box>
                                            </Box>
                                        </Grid>

                                    ))}
                                </Grid>
                                {listingLinks?.length === 0 && (
                                    <>
                                        <div className="no-data justify-content-center mt-5">
                                            <div>
                                                <Image
                                                    src={NoDataFoundImg}
                                                    className="text-center mx-auto no-data-img mt-4"
                                                />
                                            </div>
                                            <Typography
                                                component="h5"
                                                variant="h5"
                                            >
                                                No data found
                                            </Typography>
                                        </div>
                                    </>
                                )}
                            </Grid>
                        </Grid>

                    </Box>

                    {/* Link add edit modal */}
                    <BootstrapDialog
                        onClose={handleCloseAddLink}
                        aria-labelledby="customized-dialog-title"
                        open={openAddLinksModel}
                        fullWidth
                        maxWidth={"sm"}
                    >
                        <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                            className="highlight-menu-model-title-div"
                        >
                            <Typography sx={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: 400 }} className="text-center menu-modal-title">
                                {
                                    editLinkId ? 'Edit Link' : 'Add Link'
                                }
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseAddLink}
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                className="alert-close-btn menu-close-btn"
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers className="menu-modal-dialog-content">
                            <Box>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleTabChange}
                                    className="menu-modal-tabs"
                                    variant="scrollable"
                                    scrollButtons="auto"

                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#660C60",
                                        },
                                    }}
                                >
                                    {tabs.map((tab, index) => (
                                        <Tab
                                            label={
                                                <Box gap={2} className="menu-modal-tab-label">
                                                    <span>
                                                        {`${tab?.label}`}
                                                    </span>
                                                </Box>
                                            }
                                            key={index}
                                        />
                                    ))}
                                </Tabs>
                                <Divider className="divider" />
                                <CustomTabPanel key={0} value={activeTab} index={0}>
                                    <Row>
                                        <Col lg="12" className="">
                                            <Typography className="fw-400 dark-text">
                                                Name
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>
                                            <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="title"
                                                placeholder="Enter title here..."
                                                value={linkName}
                                                onChange={(
                                                    event
                                                ) => {
                                                    setLinkName(event?.target?.value)
                                                    if (event.target.value.trim() && event.target.value.length <= 255) {
                                                        setAddLinkErrors((prevErrors) => ({ ...prevErrors, title: "" }));
                                                    }
                                                }}
                                                helperText={
                                                    addLinkErrors.title
                                                }
                                                error={
                                                    Boolean(
                                                        addLinkErrors.title
                                                    )
                                                }
                                            />
                                        </Col>

                                        <Col lg="12" className="col-menu-item">
                                            <Typography className="fw-400 dark-text">
                                                Link Type
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>

                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="categoryType"
                                                className="mt-1"
                                                value={linkType}
                                                onChange={handleLinkTypeChange}
                                            >
                                                <FormControlLabel
                                                    key={"internal"}
                                                    value={"internal"}
                                                    control={
                                                        <Radio
                                                            size="small"
                                                            checked={
                                                                linkType === "internal"
                                                            }
                                                            value={"internal"}
                                                        />
                                                    }
                                                    label="Internal"
                                                    className="font-inter"
                                                />
                                                <FormControlLabel
                                                    key={"external"}
                                                    value={"external"}
                                                    control={
                                                        <Radio
                                                            size="small"
                                                            checked={
                                                                linkType === "external"
                                                            }
                                                            value={"external"}
                                                        />
                                                    }
                                                    label={"External"}
                                                    className="font-inter"
                                                />
                                            </RadioGroup>

                                        </Col>

                                        <Col lg="12" className="col-menu-item">
                                            <Typography className="fw-400 dark-text">
                                                Labels
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>
                                            <CreatableSelect
                                                closeMenuOnSelect={
                                                    false
                                                }
                                                isMulti
                                                options={
                                                    linkLabelOptions
                                                }
                                                className="muilt-select-field  mt-2 boder-radius-8"
                                                menuPlacement="auto"
                                                maxMenuHeight={150}
                                                placeholder={`Select or enter label...`}
                                                name="labels"
                                                value={linkLabels}
                                                onChange={(
                                                    selectedOptions
                                                ) => {
                                                    const selectedValues =
                                                        selectedOptions.map(
                                                            (option) => option
                                                        );

                                                    setLinkLabels(selectedValues)
                                                    if (linkLabels?.length < 1) {
                                                        setAddLinkErrors((prevErrors) => ({ ...prevErrors, labels: "" }));

                                                    }
                                                }}
                                                isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                                    const trimmedInputValue = inputValue.trim().toLowerCase();
                                                    if (trimmedInputValue === '') {
                                                        return false;
                                                    }
                                                    const optionExists = selectOptions.some(
                                                        (option) => option.label.trim().toLowerCase() === trimmedInputValue
                                                    );
                                                    return !optionExists;
                                                }}
                                                styles={{
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => {
                                                        return {
                                                            ...provided,
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                            color:
                                                                state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                        };
                                                    },
                                                }}
                                            />
                                            {addLinkErrors?.labels && (
                                                <p
                                                    style={{ color: "#d32f2f" }}
                                                    className="error-msg"
                                                >
                                                    {addLinkErrors?.labels}
                                                </p>
                                            )}
                                        </Col>

                                        <Col lg="12" className="col-menu-item">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            linkOpenTab === true
                                                        }
                                                        name="showOnUserPage"
                                                        onChange={handleChangeForLinkOpenTab}
                                                    />
                                                }
                                                label="Open link on new tab"
                                                className="font-inter"
                                            />
                                        </Col>

                                        <Col lg="12" className="col-menu-item">
                                            <Typography className="fw-400 dark-text">
                                                Link URL
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>
                                            <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="title"
                                                placeholder="Enter title here..."
                                                value={linkUrl}
                                                onChange={(
                                                    event
                                                ) => {
                                                    setLinkUrl(event?.target?.value)
                                                    if (event.target.value.trim()) {
                                                        setAddLinkErrors((prevErrors) => ({ ...prevErrors, url: "" }));
                                                    }
                                                }}
                                                helperText={
                                                    addLinkErrors.url
                                                }
                                                error={
                                                    Boolean(
                                                        addLinkErrors.url
                                                    )
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </CustomTabPanel>
                                <CustomTabPanel key={1} value={activeTab} index={1}>
                                    <Typography component="p" className="fs-14">
                                        Icon
                                        <span className="required">
                                            *
                                        </span>
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        className="d-flex flex-wrap gap-1 mt-2"
                                    >
                                        {getAllIcons?.map((icon, index) => (
                                            <Box
                                                className={`icon-div d-flex justify-content-center align-items-center icons-div ${menuItemTypeIcon === icon?.id &&
                                                    "selected-icon"
                                                    }`}
                                                key={index}
                                                onClick={() => {
                                                    setMenuItemTypeIcon(icon?.id);
                                                    setLinkIcon(icon?.icon_value)
                                                    setAddLinkErrors((prevErrors) => ({ ...prevErrors, icon: "" }));
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={icon?.icon_value}
                                                    className="icon"
                                                />

                                            </Box>
                                        ))}
                                    </Stack>
                                    {addLinkErrors?.icon && (
                                        <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                        >
                                            {addLinkErrors?.icon}
                                        </p>
                                    )}
                                    <Box className="mt-3">
                                        <Stack className="customization-icon-color">
                                            <Typography
                                                className="fw-400 dark-text font-inter "
                                                whiteSpace="nowrap"
                                            >
                                                Color
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>

                                            <MuiColorInput
                                                format="hex"
                                                value={menuItemTypeColor}
                                                onChange={handleChangeForIconColor}
                                                className="icon-color-picker"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="iconColor"
                                            />
                                        </Stack>
                                        {addLinkErrors?.iconcolor && (
                                            <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                            >
                                                {addLinkErrors?.iconcolor}
                                            </p>
                                        )}
                                    </Box>
                                    <Box className="mt-3">
                                        <Stack className="customization-icon-color">
                                            <Typography
                                                className="fw-400 dark-text font-inter "
                                                whiteSpace="nowrap"
                                            >
                                                Background
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>

                                            <MuiColorInput
                                                format="hex"
                                                value={menuItemTypeBGColor}
                                                onChange={handleChangeForIconBGColor}
                                                className="icon-color-picker2"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="iconBGColor"
                                            />
                                        </Stack>
                                        {addLinkErrors?.iconBgColor && (
                                            <p
                                                style={{ color: "#d32f2f" }}
                                                className="error-msg"
                                            >
                                                {addLinkErrors?.iconBgColor}
                                            </p>
                                        )}
                                    </Box>
                                    <Box className="mt-3">
                                        <Stack className="customization-icon-color">
                                            <Typography
                                                className="fw-400 dark-text font-inter "
                                                whiteSpace="nowrap"
                                            >
                                                Button Preview
                                            </Typography>
                                        </Stack>
                                    </Box>
                                    <Box className="mt-3">
                                        <Box className="icon-preview-box" sx={{ backgroundColor: menuItemTypeBGColor, color: menuItemTypeColor }}>
                                            <Box>
                                                {linkIcon && (<FontAwesomeIcon icon={linkIcon} />)}
                                            </Box>
                                            <Box>
                                                <Typography>{linkName}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CustomTabPanel>
                                <CustomTabPanel key={2} value={activeTab} index={2}>
                                    <Col lg="12" className="">
                                        <Typography className="fw-400 fs-14 dark-text">
                                            Select Users
                                            <span className="required">
                                                *
                                            </span>
                                        </Typography>
                                        <Select
                                            closeMenuOnSelect={false}
                                            options={getAllGroupsAndUsers}
                                            menuPlacement="bottom"
                                            name="users"
                                            value={permissions}
                                            placeholder="Select users..."
                                            isMulti
                                            menuPosition="fixed"
                                            onChange={(selectedOption) => {
                                                const users = selectedOption.map(
                                                    (option) => option
                                                );
                                                setPermissions(users);
                                                if (permissions?.length < 1) {
                                                    setAddLinkErrors((prevErrors) => ({ ...prevErrors, permissions: "" }));
                                                }
                                            }}
                                            className={`muilt-select-field mt-2 `}
                                            classNamePrefix="select"
                                            styles={{
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected
                                                        ? "#660c605c"
                                                        : provided.backgroundColor,
                                                    color: state.isSelected
                                                        ? "var(--dark-color)"
                                                        : provided.color,
                                                }),
                                                multiValueLabel: (provided, state) => {
                                                    return {
                                                        ...provided,
                                                        color:
                                                            state.data?.type === "group"
                                                                ? "rgb(0, 135, 90)"
                                                                : "rgb(0, 82, 204)",
                                                    };
                                                },
                                            }}
                                        />
                                    </Col>
                                    {addLinkErrors?.permissions && (
                                        <p
                                            style={{ color: "#d32f2f" }}
                                            className="error-msg"
                                        >
                                            {addLinkErrors?.permissions}
                                        </p>
                                    )}
                                    <Col lg="12" className="repeatable-col">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAdvancePermission === true}
                                                    name="allowEdit"
                                                    onChange={() =>
                                                        setIsAdvancePermission(
                                                            !isAdvancePermission
                                                        )
                                                    }
                                                />
                                            }
                                            label="Show Advanced Permissions"
                                            className="font-inter fs-14"
                                        />
                                    </Col>
                                    <Col lg="12" className="repeatable-col">
                                        <TableContainer className="z-0">
                                            <Table
                                                aria-labelledby="tableTitle"
                                                stickyHeader
                                            >
                                                <TableHeaderForSorting
                                                    columns={
                                                        isAdvancePermission === false
                                                            ? columns_atttributes_headers
                                                            : columns_advace_atttributes_headers
                                                    }
                                                />
                                                <TableBody>
                                                    {permissions?.length > 0 && (
                                                        <>
                                                            {permissions?.map(
                                                                (permission, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <TableRow
                                                                            hover
                                                                            role="checkbox"
                                                                            tabIndex={-1}
                                                                            key={permission.value}
                                                                            className="cursor-pointer tbl-row-attribute-row"
                                                                        >
                                                                            <TableCell
                                                                                align="left"
                                                                                className={` tbl-border-left tbl-cell-attribute-2`}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                }}
                                                                            >
                                                                                <Typography className="fs-14">
                                                                                    {permission?.label}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="right"
                                                                                className={`tbl-cell-attribute-2`}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                }}
                                                                            >
                                                                                <AntSwitch
                                                                                    name="view"
                                                                                    checked={
                                                                                        permission.view
                                                                                    }
                                                                                    onChange={() =>
                                                                                        handleToggle(
                                                                                            permission.value,
                                                                                            "view"
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        "aria-label":
                                                                                            "ant design",
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell
                                                                                align="right"
                                                                                className={`tbl-cell-attribute-2`}
                                                                                style={{
                                                                                    width: "100%",
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                }}
                                                                            >
                                                                                <AntSwitch
                                                                                    name="deny"
                                                                                    checked={
                                                                                        permission.deny
                                                                                    }
                                                                                    onChange={() =>
                                                                                        handleToggle(
                                                                                            permission.value,
                                                                                            "deny"
                                                                                        )
                                                                                    }
                                                                                    inputProps={{
                                                                                        "aria-label":
                                                                                            "ant design",
                                                                                    }}
                                                                                />
                                                                            </TableCell>

                                                                            {isAdvancePermission && (
                                                                                <>
                                                                                    <TableCell
                                                                                        align="right"
                                                                                        className={`tbl-cell-attribute-2`}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                        }}
                                                                                    >
                                                                                        <AntSwitch
                                                                                            name="edit"
                                                                                            checked={
                                                                                                permission.edit
                                                                                            }
                                                                                            onChange={() =>
                                                                                                handleToggle(
                                                                                                    permission.value,
                                                                                                    "edit"
                                                                                                )
                                                                                            }
                                                                                            inputProps={{
                                                                                                "aria-label":
                                                                                                    "ant design",
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="right"
                                                                                        className={`tbl-cell-attribute-2`}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                        }}
                                                                                    >
                                                                                        <AntSwitch
                                                                                            name="delete"
                                                                                            checked={
                                                                                                permission.delete
                                                                                            }
                                                                                            onChange={() =>
                                                                                                handleToggle(
                                                                                                    permission.value,
                                                                                                    "delete"
                                                                                                )
                                                                                            }
                                                                                            inputProps={{
                                                                                                "aria-label":
                                                                                                    "ant design",
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell
                                                                                        align="right"
                                                                                        className={`tbl-border-right tbl-cell-attribute-2`}
                                                                                        style={{
                                                                                            width: "100%",
                                                                                            top: 0,
                                                                                            left: 0,
                                                                                        }}
                                                                                    >
                                                                                        <AntSwitch
                                                                                            name="redacted"
                                                                                            checked={
                                                                                                permission.redacted
                                                                                            }
                                                                                            onChange={() =>
                                                                                                handleToggle(
                                                                                                    permission.value,
                                                                                                    "redacted"
                                                                                                )
                                                                                            }
                                                                                            inputProps={{
                                                                                                "aria-label":
                                                                                                    "ant design",
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                </>
                                                                            )}
                                                                        </TableRow>
                                                                        <TableRow className="extra-row"></TableRow>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Col>
                                </CustomTabPanel>
                            </Box>
                        </DialogContent>
                        <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                            <Button
                                className="btn delete-btn-model"
                                onClick={handleCloseAddLink}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                                Cancel
                            </Button>
                            <Button
                                className="btn primary-btn"
                                onClick={() => {
                                    onSubmit()
                                }}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                Save Changes
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>

                    {/* Label add edit modal */}
                    <BootstrapDialog
                        onClose={handleCloseAddLabel}
                        aria-labelledby="customized-dialog-title"
                        open={openAddLabelModel}
                        fullWidth
                        maxWidth={"sm"}
                    >
                        <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                            className="highlight-menu-model-title-div"
                        >
                            <Typography sx={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: 400 }} className="text-center menu-modal-title">
                                Add Label
                            </Typography>
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseAddLabel}
                                sx={{
                                    color: (theme) => theme.palette.grey[500],
                                }}
                                className="alert-close-btn menu-close-btn"
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent dividers className="menu-modal-dialog-content">
                            <Box>
                                <Divider className="divider" />
                                <Box className="m-3">
                                    <Row>
                                        <Col lg="12" className="">
                                            <Typography className="fw-400 dark-text">
                                                Name
                                                <span className="required">
                                                    *
                                                </span>
                                            </Typography>
                                            <TextField
                                                className="input-field mt-2"
                                                fullWidth
                                                id="outlined-basic username"
                                                variant="outlined"
                                                size="small"
                                                name="label-title"
                                                placeholder="Enter label title"
                                                value={labelName}
                                                onChange={(
                                                    event
                                                ) => {
                                                    setLabelName(event?.target?.value)
                                                    if (event.target.value.trim()) {
                                                        setAddLabelErrors((prevErrors) => ({ ...prevErrors, title: "" }));
                                                    }
                                                }}
                                                helperText={
                                                    addLabelErrors.title
                                                }
                                                error={
                                                    Boolean(
                                                        addLabelErrors.title
                                                    )
                                                }
                                            />
                                        </Col>

                                        <Col lg="12" className="col-menu-item">
                                            <Box className="mt-3">
                                                <Typography component="p" className="fs-14">
                                                    Icon
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    className="d-flex flex-wrap gap-1 mt-2"
                                                >
                                                    {getAllIcons?.map((icon, index) => (
                                                        <Box
                                                            className={`icon-div d-flex justify-content-center align-items-center icons-div ${labelIconId === icon?.id &&
                                                                "selected-icon"
                                                                }`}
                                                            key={index}
                                                            onClick={() => {
                                                                setLabelIconId(icon?.id);
                                                                setLabelIconValue(icon?.icon_value)
                                                                setAddLabelErrors((prevErrors) => ({ ...prevErrors, icon: "" }));
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={icon?.icon_value}
                                                                className="icon"
                                                            />

                                                        </Box>
                                                    ))}
                                                </Stack>
                                                {addLabelErrors?.icon && (
                                                    <p
                                                        style={{ color: "#d32f2f" }}
                                                        className="error-msg"
                                                    >
                                                        {addLabelErrors?.icon}
                                                    </p>
                                                )}
                                            </Box>

                                        </Col>
                                    </Row>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                            <Button
                                className="btn delete-btn-model"
                                onClick={handleCloseAddLabel}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                                Cancel
                            </Button>
                            <Button
                                className="btn primary-btn"
                                onClick={() => {
                                    onSubmitLabel()
                                }}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                Save Changes
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                    <Loader isLoading={loading} />

                </Container>
            </div >
        </>
    )
}

export default LinksList