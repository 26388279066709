import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import {
  faHouse,
  faUsers,
  faFolder,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import Collapse from "@mui/material/Collapse";


export const UserMenu = (props) => {
  let {
    selectedItem,
    open,
    openOperation,
    handleClickForOperationsMenu,
    dispatch,
    navigate,
    location,
    adminAccessible,
    getAllTypesForUsers,
    loggedUser,
    userMenuLists,
    setExpandedMenuForUser,
    expandedMenuForUser
  } = props;

  const handleToggle = (id) => {
    setExpandedMenuForUser((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const SubMenu = ({ menu, open, handleToggle, expandedMenuForUser, navigate, location, adminAccessible, loggedUser }) => {
    const handleItemClick = (item) => {
      if (item.sub_menu.length > 0) {
        handleToggle(item.id);
      } else {
        const userKey = loggedUser?.user_type === "admin" && adminAccessible ? "admin-user" : "user";
        localStorage.setItem(`type-selected-name-${userKey}`, item?.title);
        localStorage.setItem(`type-selected-id-${userKey}`, item?.id);
        localStorage.setItem(`type-selected-slug-${userKey}`, item?.slug);
        localStorage.setItem(`last-redirect-page-${userKey}`, item?.link);
        if (item?.link?.startsWith("http")) {
          // External link, open in new tab
          window.open(item?.link, "_blank");
        } else {
          // Internal link, use navigate
          navigate(item?.link);
        }
      }
    };

    return (
      <div key={menu?.id}>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          className={location.pathname === menu?.link || expandedMenuForUser[menu?.id] ? `selected-menu-item w-48px ${open === false && "open-selected-menu"}` : `non-selected-menu-item`}
        >
          <ListItemButton
            onClick={() => handleItemClick(menu)}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", justifyContent: "center" }}>
              <FontAwesomeIcon icon={menu?.icon?.icon_value} className="menu-icon" />
            </ListItemIcon>
            <ListItemText primary={menu?.title} sx={{ opacity: open ? 1 : 0 }} className="menu-name" />
            {open && menu?.sub_menu?.length > 0 && (
              <FontAwesomeIcon icon={expandedMenuForUser[menu?.id] ? faChevronUp : faChevronDown} sx={{ opacity: open ? 1 : 0 }} className="menu-icon" />
            )}
          </ListItemButton>
        </ListItem>
        {menu?.sub_menu?.length > 0 && (
          <Collapse in={expandedMenuForUser[menu?.id]} timeout="auto" unmountOnExit className={`${expandedMenuForUser[menu?.id] && "p-1"}`}>
            <List component="div" disablePadding>
              {menu?.sub_menu.map((submenu) => (
                <SubMenu
                  key={submenu?.id}
                  menu={submenu}
                  open={open}
                  handleToggle={handleToggle}
                  expandedMenuForUser={expandedMenuForUser}
                  navigate={navigate}
                  location={location}
                  adminAccessible={adminAccessible}
                  loggedUser={loggedUser}
                />
              ))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <List className="list-item-menu">
      {/* <ListItem
        key={"user-home"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/dashboard"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")

            dispatch(drawerMenuAction.clickDrawerItem("user-home"));
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/dashboard`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/dashboard`)
            }
            localStorage.removeItem("selected-chat-room-id")
            navigate("/dashboard");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faHouse} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Home"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"media"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/media"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            localStorage.removeItem("selected-folder-item")
            dispatch(drawerMenuAction.handleNameCollapsable(false));
            dispatch(drawerMenuAction.handleTypesCollapsable(false));
            dispatch(drawerMenuAction.handleActiveType(false));
            dispatch(drawerMenuAction.clickDrawerItem("home"));
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/media`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/media`)
            }
            localStorage.removeItem("selected-chat-room-id")
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            navigate("/media");
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faFolder} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Media"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem>
      {getAllTypesForUsers?.map((type, index) => (
        <ListItem
          key={type?.slug}
          disablePadding
          sx={{ display: "block" }}
          className={
            location.pathname === `/user-type/${type?.slug}` && type?.slug === location.pathname.split("/")[2]
              ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
              } `
              : "non-selected-menu-item w-48px"
          }
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                localStorage.setItem(
                  "type-selected-name-user",
                  type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  type?.slug
                );
                localStorage.setItem("last-redirect-page-admin-user", `/user-type/${type?.slug}`)
                navigate(`/user-type/${type?.slug}`);
              }
              else {
                localStorage.setItem(
                  "type-selected-name-user",
                  type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  type?.slug
                );
                localStorage.setItem("last-redirect-page-user", `/user-type/${type?.slug}`)
                navigate(`/user-type/${type?.slug}`);
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}
            >
              <FontAwesomeIcon icon={type?.icon} className="menu-icon" />
            </ListItemIcon>
            <ListItemText
              primary={type?.name}
              sx={{ opacity: open ? 1 : 0 }}
              className="menu-name" />
          </ListItemButton>
        </ListItem>
      ))}
      <ListItem
        key="people"
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === `/user-type/people`
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            if (loggedUser?.user_type === "admin" && adminAccessible == true) {
              localStorage.setItem(
                "type-selected-name-user",
                "People"
              );
              localStorage.setItem(
                "type-selected-id-user",
                "people"
              );
              localStorage.setItem("last-redirect-page-admin-user", `/user-type/people`)
            }

            else {
              localStorage.setItem(
                "type-selected-name-user",
                "People"
              );
              localStorage.setItem(
                "type-selected-id-user",
                "people"
              );
              localStorage.setItem(
                "type-selected-slug-user",
                "people"
              );
              localStorage.setItem("last-redirect-page-user", `/user-type/people`)
            }
            navigate(`/user-type/people`);
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faUsers} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="People"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name" />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"user-chat"}
        disablePadding
        sx={{ display: "block" }}
        className={
          location.pathname === "/user-conversation"
            ? `selected-menu-item w-48px ${open == false && "open-selected-menu"
            } `
            : "non-selected-menu-item w-48px"
        }
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          onClick={() => {
            dispatch(drawerMenuAction.clickDrawerItem("user-chat"))
            localStorage.removeItem("globalSearchTab")
            localStorage.removeItem("globalSearchText")
            if (adminAccessible == true) {
              localStorage.setItem("last-redirect-page-admin-user", `/user-conversation`)
            } else {
              localStorage.setItem("last-redirect-page-user", `/user-conversation`)
            }
            navigate("/user-conversation");
          }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 2 : "auto",
              justifyContent: "center",
            }}
          >
            <FontAwesomeIcon icon={faMessage} className="menu-icon" />
          </ListItemIcon>
          <ListItemText
            primary="Conversations"
            sx={{ opacity: open ? 1 : 0 }}
            className="menu-name"
          />
        </ListItemButton>
      </ListItem> */}
      {/* {userMenuLists.map((menu) => (
        <div key={menu.id}>
          <ListItem
            disablePadding
            sx={{ display: 'block' }}
            className={location.pathname === menu.link || expandedMenuForUser[menu.id] ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
              } ` : `non-selected-menu-item`}
          >
            <ListItemButton
              onClick={() => {
                if (menu.sub_menu.length > 0) {
                  handleToggle(menu.id);
                } else {
                  if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                    localStorage.setItem(
                      "type-selected-name-user",
                      menu?.title
                    );
                    localStorage.setItem(
                      "type-selected-id-user",
                      menu?.id
                    );
                    localStorage.setItem(
                      "type-selected-slug-user",
                      menu?.slug
                    );
                    localStorage.setItem("last-redirect-page-admin-user", `${menu?.link}`)
                    navigate(menu?.link);
                  }
                  else {
                    localStorage.setItem(
                      "type-selected-name-user",
                      menu?.title
                    );
                    localStorage.setItem(
                      "type-selected-id-user",
                      menu?.id
                    );
                    localStorage.setItem(
                      "type-selected-slug-user",
                      menu?.slug
                    );
                    localStorage.setItem("last-redirect-page-user", `${menu?.link}}`)
                    navigate(menu?.link);
                  }
                  // navigate(menu.link);
                }
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon sx={{
                minWidth: 0,
                mr: open ? 2 : "auto",
                justifyContent: "center",
              }}>
                <FontAwesomeIcon icon={menu.icon.icon_value} className="menu-icon" />
              </ListItemIcon>
              <ListItemText primary={menu.title} sx={{ opacity: open ? 1 : 0 }} className="menu-name" />
              {open === true && (
                <>
                  {menu.sub_menu.length > 0 && (
                    <FontAwesomeIcon icon={expandedMenuForUser[menu.id] ? faChevronUp : faChevronDown} sx={{ opacity: open ? 1 : 0 }} className="menu-icon" />
                  )}
                </>
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={expandedMenuForUser[menu.id]} timeout="auto" unmountOnExit className={`${expandedMenuForUser[menu.id] && "p-1"}`}>
            <List component="div" disablePadding>
              {menu.sub_menu.map((submenu) => (

                <ListItem
                  disablePadding
                  sx={{ display: 'block' }}
                  className={location.pathname === submenu.link ? `selected-menu-item w-48px ${open === false && "open-selected-menu"
                    }` : `non-selected-menu-item w-48px`}
                >
                  <ListItemButton
                    onClick={() => {
                      if (submenu.sub_menu.length > 0) {
                        handleToggle(submenu.id);
                      } else {
                        if (loggedUser?.user_type == "admin" && adminAccessible == true) {

                          localStorage.setItem(
                            "type-selected-name-user",
                            submenu?.title
                          );
                          localStorage.setItem(
                            "type-selected-id-user",
                            submenu?.id
                          );
                          localStorage.setItem(
                            "type-selected-slug-user",
                            submenu?.slug
                          );
                          localStorage.setItem("last-redirect-page-admin-user", `${submenu?.link}`)
                          navigate(submenu?.link);
                        }
                        else {
                          localStorage.setItem(
                            "type-selected-name-user",
                            submenu?.title
                          );
                          localStorage.setItem(
                            "type-selected-id-user",
                            submenu?.id
                          );
                          localStorage.setItem(
                            "type-selected-slug-user",
                            submenu?.slug
                          );
                          localStorage.setItem("last-redirect-page-user", `${submenu?.link}}`)
                          navigate(submenu?.link);
                        }
                        // navigate(submenu.link);
                      }
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        ml: 4,
                        justifyContent: "center",
                      }}
                      className={`${open === false && "sub-menu-icon-false"}`}
                    >
                      <FontAwesomeIcon icon={submenu?.icon?.icon_value} className="menu-icon" />
                    </ListItemIcon>
                    <ListItemText primary={submenu?.title} sx={{ opacity: open ? 1 : 0 }} className="menu-name" />
                    {submenu?.sub_menu?.length > 0 && (
                      <FontAwesomeIcon icon={expandedMenuForUser[submenu?.id] ? faChevronUp : faChevronDown} />
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))} */}
      {userMenuLists.map((menu) => (
        <SubMenu
          key={menu.id}
          menu={menu}
          open={open}
          handleToggle={handleToggle}
          expandedMenuForUser={expandedMenuForUser}
          navigate={navigate}
          location={location}
          adminAccessible={adminAccessible}
          loggedUser={loggedUser}
        />
      ))}
    </List>
  );
};
